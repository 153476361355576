import {
  MedicalIcon,
  Typography,
  Wrapper,
  checkDateDay,
  parseDate,
  MessageIcon,
  Tag
} from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { FeatureIcon } from "../../../atoms";

export const NotifyMessageHeader = ({ message, icon, time, badge }) => (
  <Wrapper className="notify-message__header">
    <Wrapper flex>
      <Wrapper className="notify-message__svg">
        {icon === "ClinicalCaseFollowingSubscription" && <MedicalIcon />}
        {icon === "FeatureUpdatesSubscription" && <FeatureIcon />}
        {icon === "MessagesSubscription" && <MessageIcon />}
      </Wrapper>

      <Wrapper className="notify-message__title" flex>
        {badge && <Tag text="20" type="number" />}
        <Typography size={14}>{message}</Typography>
      </Wrapper>
    </Wrapper>

    {checkDateDay(time) ? (
      <Typography className="notify-message__time" size={14}>
        {checkDateDay(time)}
      </Typography>
    ) : (
      <Typography className="notify-message__time" size={14}>
        {parseDate(time, "DD MMM YYYY")}
      </Typography>
    )}
  </Wrapper>
);

NotifyMessageHeader.defaultProps = {
  badge: false
};

NotifyMessageHeader.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  badge: PropTypes.bool
};

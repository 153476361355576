import _ from "lodash";
import { useSelector } from "react-redux";
import { Divider, NoteOutcomes } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { showOutcome } from "../../../../../utils/helper";
import { HEPC } from "../../../../../constants/defaults";

export const Outcomes = ({
  filterOutcomeTypes,
  form,
  processes,
  outcomeOptions,
  notesHandler
}) => {
  const caseData = useSelector((state) => state.caseTypeReducer);
  
  const getProcessRefId = (processTypeName) => {
    const item = _.find(processes, { processType: processTypeName });
    return item?.processReference;
  };

  const getOutcomeOptions = (type) => {
    switch (type) {
      case "NoteType-Testing-ResultsConsultation-Done":
        return outcomeOptions.test;
      case "NoteType-Testing-RecordHepBOutcome-Done":
        return [...outcomeOptions.hepBOutcome, ...outcomeOptions.testReactive];
      case "NoteType-Testing-RecordHepCOutcome-Done":
        return [...outcomeOptions.hepCOutcome, ...outcomeOptions.testReactive];
      case "NoteType-Testing-RecordHivOutcome-Done":
        return [...outcomeOptions.hivOutcome, ...outcomeOptions.testReactive];
      case "NoteType-Testing-RecordSypOutcome-Done":
        return [...outcomeOptions.sypOutcome, ...outcomeOptions.testReactive];
      case "NoteType-Safeguarding-Callback-Done":
      case "NoteType-Safeguarding-ConsultationUnderage-Done":
        return outcomeOptions.safeGuarding;
      case "NoteType-Care-LogOutcome-Done":
        return outcomeOptions.care;
      case "NoteType-Care-LogHepCOutcome-Done":
        return outcomeOptions.careHepc;
      case "NoteType-Care-GeneralCallback-Done":
      case "NoteType-Care-GeneralCallback-NotRequired":
        return outcomeOptions.careGeneral;
      case "NoteType-Care-CompleteChlamydiaPreTreatmentTriage-Done":
        return outcomeOptions.careChlamydiaPre;
      case "NoteType-Care-CompleteChlamydiaPostTreatmentTriage-Done":
        return outcomeOptions.careChlamydiaPost;

      default:
        return [];
    }
  };

  const isMultiOutcomes = (processName, type) => {
    if (
      type === "NoteType-Care-LogOutcome-Done" &&
      processName === "ProcessType-Care"
    ) {
      return false;
    }

    if (
      type === "NoteType-Care-GeneralCallback-Done" &&
      processName === "ProcessType-Care"
    ) {
      return false;
    }
    if (
      processName === "ProcessType-Safeguarding" ||
      type === "NoteType-Care-CompleteChlamydiaPreTreatmentTriage-Done" ||
      type === "NoteType-Care-CompleteChlamydiaPostTreatmentTriage-Done" ||
      processName === "ProcessType-Care"
    ) {
      return true;
    }

    return false;
  };

  const {serviceProviderSettings, data, portalType} = caseData;
  const defaultSelectedMode = (!!serviceProviderSettings?.careTransferToList && !portalType) || portalType === HEPC;

  const getOutcomes = (outcomes) =>
    _.map(outcomes, ({ processTypeName, id: outcomeTypeId, type }, index) => {
     const processReference = getProcessRefId(processTypeName);
      if (showOutcome(type)) {
        return (
          <>
            <NoteOutcomes
              careTransferredToList = {serviceProviderSettings?.careTransferToList || data?.name || ''}
              isFreeTextField = {serviceProviderSettings?.isFreeTextField}
              defaultSelectedMode={defaultSelectedMode}
              key={`note-outcome-${index + 1}`}
              outcomesSelected={form?.notes?.outcomes || []}
              processReference={processReference}
              outcomeTypeId={outcomeTypeId}
              outcomeOptions={getOutcomeOptions(type)}
              isMultiOutcomes={isMultiOutcomes(processTypeName, type)}
              outcomesHandler={(value) => notesHandler("outcomes", value)}
            />
            <Divider mt={16} mb={30} absolute />
          </>
        );
      }
      return null;
    });

  return <>{getOutcomes(filterOutcomeTypes)}</>;
};
Outcomes.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  processes: PropTypes.instanceOf(Array).isRequired,
  notesHandler: PropTypes.func.isRequired,
  filterOutcomeTypes: PropTypes.instanceOf(Array).isRequired,
  outcomeOptions: PropTypes.instanceOf(Object).isRequired
};

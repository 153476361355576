import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import {
  Page,
  RecordSummary,
  Samples,
  Wrapper,
  TestBookingsV2,
  translate
} from "preventx-storybook";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { AuthenticateTemplate } from "../AuthenticateTemplate";
import { getLabReport } from "../../store/labreportv2/actionCreator";

export const LabReportsV2 = () => {
  const { t } = translate();
  const isSccResult = process.env.REACT_APP_LABREPORT_PROVIDER === "SCC";
  const { screenReferenceId, serviceuserReferenceId } = useParams();
  const dispatch = useDispatch();
  const labReportData = useSelector((state) => state.labReportReducer);
  const labData = { ...labReportData.data, screenReference: screenReferenceId };

  useEffect(() => {
    dispatch(
      getLabReport({
        screenReferenceId
      })
    );
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <Page title={`${t("Lab-Record")} ${serviceuserReferenceId}`}>
          <Wrapper className="cup-labreport">
            <Wrapper className="cup-labreport__report">
              <RecordSummary
                isLoading={labReportData.loading}
                isError={labReportData.error.isError}
                errorCode={labReportData.error.code}
                errorMessage={labReportData.error.message}
                summary={labData || {}}
                isSccResult={!isSccResult}
              />
              <Samples
                isLoading={labReportData.loading}
                isError={labReportData.error?.isError}
                errorCode={labReportData?.error?.code}
                errorMessage={labReportData?.error?.message}
                summary={labReportData?.data?.screens || []}
                isSccResult={!isSccResult}
              />
              <TestBookingsV2
                isLoading={labReportData.loading}
                isError={labReportData.error.isError}
                errorCode={labReportData.error.code}
                errorMessage={labReportData.error.message}
                summary={labReportData?.data?.bookings}
              />
            </Wrapper>
          </Wrapper>
        </Page>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const initialState = {
  caseReference: {},
  loading: -1,
  error: apiErrorObj,
  flag: false
};

// action type
const CREATE_CASE = "CREATE_CASE";
const CREATE_CASE_SUCCESS = "CREATE_CASE_SUCCESS";
const CREATE_CASE_ERROR = "CREATE_CASE_ERROR";
const CREATE_CASE_RESET = "CREATE_CASE_RESET";

// action creator
export const postCreateCase = (data) => ({ type: CREATE_CASE, data });
export const resetCreateCase = () => ({ type: CREATE_CASE_RESET });

// saga action
function* createCaseCall(action) {
  try {
    const response = yield apiCall({
      method: "post",
      url: "cases",
      data: action.data
    });
    yield put({
      type: CREATE_CASE_SUCCESS,
      payload: { ...action.data, response }
    });
  } catch (e) {
    yield put(
      apiException({
        type: CREATE_CASE_ERROR,
        code: e.code || e,
        message: e.message || "CreateCase-Fail"
      })
    );
  }
}

export function* createCasePostAction() {
  yield takeLatest([CREATE_CASE], createCaseCall);
}

// reducer
export const createCaseReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CREATE_CASE: {
      return {
        ...state,
        loading: 1,
        flag: false,
        error: apiErrorObj
      };
    }
    case CREATE_CASE_SUCCESS: {
      return {
        ...state,
        caseReference: action.payload.response.caseReference,
        loading: 0,
        flag: true
      };
    }
    case CREATE_CASE_ERROR: {
      return {
        ...state,
        caseReference: action.payload || "",
        loading: false,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    case CREATE_CASE_RESET: {
      return {
        ...state,
        loading: -1,
        flag: false,
        error: apiErrorObj
      };
    }
    default:
      return state;
  }
};

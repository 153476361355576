import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_MORE,
  MARKALLASREAD_NOTIFICATION,
  MARKASREAD_NOTIFICATION,
  RESET_NOTIFICATION,
  SET_NOTIFICATIONS_SUCCESS
} from "./actionTypes";

export const getNotifications = (status) => ({
  type: GET_NOTIFICATIONS,
  status,
  resetPage: true
});

export const getNotificationsMore = (status) => ({
  type: GET_NOTIFICATIONS_MORE,
  status,
  moreLoading: true
});

export const setNotifications = (data, overDuePage, status) => ({
  type: SET_NOTIFICATIONS_SUCCESS,
  data,
  overDuePage,
  status
});

export const markAsReadNotification = (notificationId) => ({
  type: MARKASREAD_NOTIFICATION,
  notificationId
});

export const markAllAsReadNotification = () => ({
  type: MARKALLASREAD_NOTIFICATION
});

export const deleteNotification = (notificationId) => ({
  type: DELETE_NOTIFICATION,
  notificationId
});

export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
})
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  Select,
  Wrapper,
  DropDown,
  PlusIcon,
  translate,
  Typography,
  EllipsisIcon
} from "preventx-storybook";

// Constants
import { CloseAccountStatus } from "../../../constants/closeaccountstatus";

// Store Management
import {
  postCreateCase,
  resetCreateCase
} from "../../../store/createCase/logic";
import { getcaseHistory } from "../../../store/casehistory/logic";
import { getaccountStatus } from "../../../store/accountstatus/logic";
import { putserviceUserReference } from "../../../store/serviceuserreference/update/logic";

// Services
import { apiSuccess } from "../../../services/api";
import { goToOldApp } from "../../../utils/helper";

// Components
import { OrderIcon } from "../../atoms";

export const StausBar = () => {
  const { t } = translate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { serviceuserReferenceId } = useParams();

  const [displayDirectOrderLink, setDisplayDirectOrderLink] = useState(false);
  const serviceUserState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );
  const createCaseState = useSelector((state) => state.createCaseReducer);
  const accountStatusState = useSelector((state) => state.accountStatusReducer);
  const [selected, setSelected] = useState(
    serviceUserState?.data?.AccountStatus
  );
  const serviceUserUpdateState = useSelector(
    (state) => state.serviceUserReferenceUpdateReducer
  );

  const {
    data: { caseHistories }
  } = useSelector((state) => state.caseHistoryReducer);

  const { data } = useSelector((state) => state.serviceProviderSettingsReducer);

  const displayDirectOrder = (serviceProviders) => {
    try {
      const showDirectOrder = serviceProviders?.some((provider) => {
        const providerData = data.find((entry) =>
          Object.hasOwn(entry, provider)
        );

        const suppressOperations = providerData?.[provider].find(
          (item) => item.Key === "SuppressOperations"
        );
        const suppressOperationsValue = JSON.parse(suppressOperations?.Value);
        return suppressOperationsValue.includes("DirectOrder");
      });
      setDisplayDirectOrderLink(!showDirectOrder);
    } catch (error) {
      console.error("Error parsing JSON settings:", error);
    }
  };

  const getUniqueServiceProviders = () => {
    const uniqueServiceProviderReferences = [
      ...new Set(
        caseHistories?.map(
          (caseHistory) => caseHistory.serviceProviderReference
        )
      )
    ];
    if (uniqueServiceProviderReferences.length) {
      displayDirectOrder(uniqueServiceProviderReferences);
    }
  };
  useEffect(() => {
    getUniqueServiceProviders();
  }, [caseHistories]);

  useEffect(() => {
    dispatch(getaccountStatus());
  }, [dispatch]);

  useEffect(() => {
    if (createCaseState?.flag) {
      dispatch(
        apiSuccess(
          `${t("Case")} ${createCaseState?.caseReference} ${t(
            "Created-Success"
          )}`
        )
      );
      dispatch(resetCreateCase());
      history.push(`/case-list/${createCaseState?.caseReference}`);
    }
  }, [createCaseState?.flag]);

  useEffect(() => {
    if (!createCaseState?.loading) {
      dispatch(
        getcaseHistory({
          ServiceUserReference: serviceuserReferenceId
        })
      );
    }
  }, [createCaseState, history]);

  const serviceProvideState = useSelector(
    (state) => state.serviceProvidersReducer
  );

  const createCaseFn = () => {
    dispatch(
      postCreateCase({
        serviceUserReference: serviceuserReferenceId,
        serviceProviderReference: serviceProvideState.data[1].value
      })
    );
  };

  const updateSelected = (selectedVal) => {
    setSelected(selectedVal);
    dispatch(
      putserviceUserReference({
        url: `serviceusers/${serviceUserState?.data?.ServiceUserReference}`,
        body: {
          AccountStatus: selectedVal,
          ServiceUserReference: serviceuserReferenceId
        },
        message: "Account-Status-Update-Message"
      })
    );
  };

  const redirectToKitOrder = () => {
    const legacyUserId = serviceUserState.data.LegacyId;
    const legacyUserUid = serviceUserState.data.LegacyUID.substring(24, 36);
    const serviceProviderId = serviceProvideState.data[1].value.substring(
      2,
      serviceProvideState.data[1].value.length - 1
    );
    const url = `/account/shuk/manage/testing/orderkit/${legacyUserId},${legacyUserUid},${serviceProviderId}`;
    goToOldApp(url);
  };

  return (
    <Wrapper className="statusbar">
      <Wrapper className="statusbar__header" flex>
        <Wrapper className="statusbar__namesection">
          <Typography variant="h1" size={24} weight="medium">
            {`${serviceUserState.data.FirstName} ${serviceUserState.data.LastName}`}
          </Typography>
        </Wrapper>
        <Wrapper className="statusbar__select">
          <Select
            handler={(selectedVal) => {
              updateSelected(selectedVal);
            }}
            disabled={
              accountStatusState.loading || serviceUserUpdateState?.loading
            }
            options={accountStatusState?.data}
            placeHolder={t("Change-Account-Status")}
            selected={selected}
          />
        </Wrapper>
      </Wrapper>
      {CloseAccountStatus.indexOf(serviceUserState?.data?.AccountStatus) ===
        -1 && (
        <Wrapper>
          <DropDown
            className="statusbar-dropdown"
            menu={[
              serviceUserState.data.LegacyUID &&
                displayDirectOrderLink && {
                  title: "Direct-Order",
                  icon: <OrderIcon />,
                  handler: () => redirectToKitOrder()
                },
              {
                title: "Create-Case",
                icon: <PlusIcon />,
                handler: () => createCaseFn()
              }
            ]}
            title="User-Actions"
            icon={<EllipsisIcon />}
            position="start"
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};

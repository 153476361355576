import {
  activityLogReducer,
  additionalMetricsReducer,
  backDropReducer,
  careReducer,
  caseFollowReducer,
  caseHeaderReducer,
  caseHistoryReducer,
  caseListReducer,
  createTaskReducer,
  errorStatusReducer,
  filterListReducer,
  laboratoryAccreditateReducer,
  notesReducer,
  partnerTracingReducer,
  recordSummaryReducer,
  resultSummaryReducer,
  safeGuardingReducer,
  sampleReceivedReducer,
  searchCaseReducer,
  searchCountReducer,
  searchUserReducer,
  serviceUserReferenceReducer,
  statusNotificationReducer,
  taskTypeReducer,
  testBookingReducer,
  testingReducer,
  todayListReducer,
  toggleSettingsReducer,
  triageReportReducer,
  userDetailsReducer,
  userNotificationReducer,
  userSubscriptionsReducer,
  serviceProvidersReducer,
  navbarReducer,
  serviceUserReferenceUpdateReducer,
  createCaseReducer,
  accountStatusReducer,
  partnerTracingListReducer,
  messageReducer,
  eventLogReducer,
  taskLogReducer,
  triageHeaderReducer,
  forceRefreshReducer,
  caseTypeReducer,
  searchScreenReferenceReducer,
  labReportReducer,
  serviceProviderSettingsReducer
} from "./index";

const rootReducers = {
  testBookingReducer,
  backDropReducer,
  careReducer,
  caseFollowReducer,
  caseHeaderReducer,
  caseListReducer,
  errorStatusReducer,
  filterListReducer,
  notesReducer,
  partnerTracingReducer,
  safeGuardingReducer,
  searchCaseReducer,
  searchCountReducer,
  searchUserReducer,
  statusNotificationReducer,
  testingReducer,
  todayListReducer,
  toggleSettingsReducer,
  userDetailsReducer,
  userNotificationReducer,
  userSubscriptionsReducer,
  taskTypeReducer,
  createTaskReducer,
  sampleReceivedReducer,
  additionalMetricsReducer,
  recordSummaryReducer,
  activityLogReducer,
  resultSummaryReducer,
  laboratoryAccreditateReducer,
  caseHistoryReducer,
  serviceUserReferenceReducer,
  triageReportReducer,
  serviceProvidersReducer,
  navbarReducer,
  serviceUserReferenceUpdateReducer,
  createCaseReducer,
  accountStatusReducer,
  partnerTracingListReducer,
  messageReducer,
  eventLogReducer,
  taskLogReducer,
  triageHeaderReducer,
  forceRefreshReducer,
  caseTypeReducer,
  searchScreenReferenceReducer,
  labReportReducer,
  serviceProviderSettingsReducer
};
export default rootReducers;

export const processOutcomesOptions = {
  test: [
    {
      text: "Outcome-Testing-UserConsultedAboutResults",
      value: "Outcome-Testing-UserConsultedAboutResults",
      children: [
        {
          text: "Outcome-Testing-UserConsultedAboutResults-FollowUpDeclined",
          value: "Outcome-Testing-UserConsultedAboutResults-FollowUpDeclined"
        },
        {
          text: "Outcome-Testing-UserConsultedAboutResults-ProgressingToCare",
          value: "Outcome-Testing-UserConsultedAboutResults-ProgressingToCare"
        },
        {
          text: "Outcome-Testing-UserConsultedAboutResults-NoActionRequired",
          value: "Outcome-Testing-UserConsultedAboutResults-NoActionRequired"
        },
        {
          text: "Outcome-Testing-UserConsultedAboutResults-FurtherTestingRequired",
          value:
            "Outcome-Testing-UserConsultedAboutResults-FurtherTestingRequired"
        }
      ]
    },
    {
      text: "Outcome-Testing-UserNotConsultedAboutResults",
      value: "Outcome-Testing-UserNotConsultedAboutResults",
      children: [
        {
          text: "Outcome-Testing-UserNotConsultedAboutResults-UnableToContact",
          value: "Outcome-Testing-UserNotConsultedAboutResults-UnableToContact"
        }
      ]
    }
  ],
  testReactive: [
    {
      text: "Outcome-Testing-NonReactive-NoDiagnosis",
      value: "Outcome-Testing-NonReactive-NoDiagnosis",
      children: [
        {
          text: "Outcome-Testing-NonReactive-NoDiagnosis-ClinicianConfirmed",
          value: "Outcome-Testing-NonReactive-NoDiagnosis-ClinicianConfirmed"
        },
        {
          text: "Outcome-Testing-NonReactive-NoDiagnosis-PatientConfirmed",
          value: "Outcome-Testing-NonReactive-NoDiagnosis-PatientConfirmed"
        }
      ]
    },
    {
      text: "Outcome-Testing",
      value: "Outcome-Testing",
      children: [
        {
          text: "Outcome-Testing-NotYetKnown",
          value: "Outcome-Testing-NotYetKnown"
        }
      ]
    },
    {
      text: "Outcome-Testing-Other",
      value: "Outcome-Testing-Other",
      children: [
        {
          text: "Outcome-Testing-Other-ExistingOrKnownReactive",
          value: "Outcome-Testing-Other-ExistingOrKnownReactive"
        },
        {
          text: "Outcome-Testing-Other-HistoryOfFalseReactive",
          value: "Outcome-Testing-Other-HistoryOfFalseReactive"
        }
      ]
    }
  ],
  hivOutcome: [
    {
      text: "Outcome-Testing-Reactive-HIVDiagnosis",
      value: "Outcome-Testing-Reactive-HIVDiagnosis",
      children: [
        {
          text: "Outcome-Testing-Reactive-HIVDiagnosis-ClinicianConfirmed",
          value: "Outcome-Testing-Reactive-HIVDiagnosis-ClinicianConfirmed"
        },
        {
          text: "Outcome-Testing-Reactive-HIVDiagnosis-PatientConfirmed",
          value: "Outcome-Testing-Reactive-HIVDiagnosis-PatientConfirmed"
        }
      ]
    }
  ],
  hepBOutcome: [
    {
      text: "Outcome-Testing-Reactive-HepBDiagnosis",
      value: "Outcome-Testing-Reactive-HepBDiagnosis",
      children: [
        {
          text: "Outcome-Testing-Reactive-HepBDiagnosis-ClinicianConfirmed",
          value: "Outcome-Testing-Reactive-HepBDiagnosis-ClinicianConfirmed"
        },
        {
          text: "Outcome-Testing-Reactive-HepBDiagnosis-PatientConfirmed",
          value: "Outcome-Testing-Reactive-HepBDiagnosis-PatientConfirmed"
        }
      ]
    }
  ],
  hepCOutcome: [
    {
      text: "Outcome-Testing-Reactive-HepCDiagnosis",
      value: "Outcome-Testing-Reactive-HepCDiagnosis",
      children: [
        {
          text: "Outcome-Testing-Reactive-HepCDiagnosis-ClinicianConfirmed",
          value: "Outcome-Testing-Reactive-HepCDiagnosis-ClinicianConfirmed"
        },
        {
          text: "Outcome-Testing-Reactive-HepCDiagnosis-PatientConfirmed",
          value: "Outcome-Testing-Reactive-HepCDiagnosis-PatientConfirmed"
        }
      ]
    }
  ],
  sypOutcome: [
    {
      text: "Outcome-Testing-Reactive-SypDiagnosis",
      value: "Outcome-Testing-Reactive-SypDiagnosis",
      children: [
        {
          text: "Outcome-Testing-Reactive-SypDiagnosis-ClinicianConfirmed",
          value: "Outcome-Testing-Reactive-SypDiagnosis-ClinicianConfirmed"
        },
        {
          text: "Outcome-Testing-Reactive-SypDiagnosis-PatientConfirmed",
          value: "Outcome-Testing-Reactive-SypDiagnosis-PatientConfirmed"
        }
      ]
    }
  ],

  safeGuarding: [
    {
      text: "Outcome-Safeguarding-Referred",
      value: "Outcome-Safeguarding-Referred",
      children: [
        {
          text: "Outcome-Safeguarding-Referred-SocialServicesReferral",
          value: "Outcome-Safeguarding-Referred-SocialServicesReferral"
        },
        {
          text: "Outcome-Safeguarding-Referred-SARCReferralSignposting",
          value: "Outcome-Safeguarding-Referred-SARCReferralSignposting"
        },
        {
          text: "Outcome-Safeguarding-Referred-DiscussedAtLocalBoard",
          value: "Outcome-Safeguarding-Referred-DiscussedAtLocalBoard"
        },
        {
          text: "Outcome-Safeguarding-Referred-PoliceReferralSignposting",
          value: "Outcome-Safeguarding-Referred-PoliceReferralSignposting"
        },
        {
          text: "Outcome-Safeguarding-Referred-SharedWithHealthProfessional",
          value: "Outcome-Safeguarding-Referred-SharedWithHealthProfessional"
        },
        {
          text: "Outcome-Safeguarding-Referred-ReferredClinicHospital",
          value: "Outcome-Safeguarding-Referred-ReferredClinicHospital"
        }
      ]
    },
    {
      text: "Outcome-Safeguarding-NoContactMade",
      value: "Outcome-Safeguarding-NoContactMade",
      children: [
        {
          text: "Outcome-Safeguarding-NoContactMade-UnableToContact",
          value: "Outcome-Safeguarding-NoContactMade-UnableToContact"
        }
      ]
    },
    {
      text: "Outcome-Safeguarding-NoFurtherActionRequired",
      value: "Outcome-Safeguarding-NoFurtherActionRequired",
      children: [
        {
          text: "Outcome-Safeguarding-NoFurtherActionRequired-IncorrectTriageResponses",
          value:
            "Outcome-Safeguarding-NoFurtherActionRequired-IncorrectTriageResponses"
        },
        {
          text: "Outcome-Safeguarding-NoFurtherActionRequired-NoSafeguardingConcerns",
          value:
            "Outcome-Safeguarding-NoFurtherActionRequired-NoSafeguardingConcerns"
        },
        {
          text: "Outcome-Safeguarding-NoFurtherActionRequired-FurtherAssistanceRefused",
          value:
            "Outcome-Safeguarding-NoFurtherActionRequired-FurtherAssistanceRefused"
        }
      ]
    }
  ],
  care: [
    {
      text: "Outcome-Care-CareTransferred",
      value: "Outcome-Care-CareTransferred",
      children: [
        {
          text: "Outcome-Care-CareTransferred-CareTransferredToParticipatingClinic",
          value:
            "Outcome-Care-CareTransferred-CareTransferredToParticipatingClinic"
        },
        {
          text: "Outcome-Care-CareTransferred-CareTransferredToClinic-Verified",
          value: "Outcome-Care-CareTransferred-CareTransferredToClinic-Verified"
        },
        {
          text: "Outcome-Care-CareTransferred-CareTransferredToClinic-NotVerified",
          value:
            "Outcome-Care-CareTransferred-CareTransferredToClinic-NotVerified"
        },
        {
          text: "Outcome-Care-CareTransferred-CareTransferredToServiceProvider",
          value: "Outcome-Care-CareTransferred-CareTransferredToServiceProvider"
        }
      ]
    },

    {
      text: "Outcome-Care-InfectionManaged",
      value: "Outcome-Care-InfectionManaged",
      children: [
        {
          text: "Outcome-Care-InfectionManaged-ManagedInGUMOrHIVClinic",
          value: "Outcome-Care-InfectionManaged-ManagedInGUMOrHIVClinic"
        },
        {
          text: "Outcome-Care-InfectionManaged-ManagedViaPostalTreatment",
          value: "Outcome-Care-InfectionManaged-ManagedViaPostalTreatment"
        },
        {
          text: "Outcome-Care-InfectionManaged-ManagedViaOtherPathway",
          value: "Outcome-Care-InfectionManaged-ManagedViaOtherPathway"
        }
      ]
    },
    {
      text: "Outcome-Care-NoFurtherActionRequired",
      value: "Outcome-Care-NoFurtherActionRequired",
      children: [
        {
          text: "Outcome-Care-NoFurtherActionRequired-FollowUpDeclined",
          value: "Outcome-Care-NoFurtherActionRequired-FollowUpDeclined"
        },
        {
          text: "Outcome-Care-NoFurtherActionRequired-UnableToContact",
          value: "Outcome-Care-NoFurtherActionRequired-UnableToContact"
        }
      ]
    }
  ],
  careHepc :[
    {
      text: "Outcome-Care-CareTransferred",
      value: "Outcome-Care-CareTransferred",
      children: [
        {
          text: "Outcome-Care-CareTransferred-CareTransferredToODN",
          value: 
          "Outcome-Care-CareTransferred-CareTransferredToODN"
        }
      ]
    },
  ],


  careGeneral: [
    {
      text: "Outcome-Care-GeneralCallback",
      value: "Outcome-Care-GeneralCallback",
      children: [
        {
          text: "Outcome-Care-GeneralCallback-Referred",
          value: "Outcome-Care-GeneralCallback-Referred"
        },
        {
          text: "Outcome-Care-GeneralCallback-NoContactMade",
          value: "Outcome-Care-GeneralCallback-NoContactMade"
        },
        {
          text: "Outcome-Care-GeneralCallback-NoFurtherActionRequired",
          value: "Outcome-Care-GeneralCallback-NoFurtherActionRequired"
        }
      ]
    }
  ],
  careChlamydiaPost: [
    {
      text: "Outcome-Care-CompleteChlamydiaPostTreatmentTriage",
      value: "Outcome-Care-CompleteChlamydiaPostTreatmentTriage",
      children: [
        {
          text: "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskAboutContraception",
          value:
            "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskAboutContraception"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskAboutSicknessOrDiarrhoea",
          value:
            "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskAboutSicknessOrDiarrhoea"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskIfAbstained",
          value:
            "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskIfAbstained"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskAboutRepeatTest",
          value:
            "Outcome-Care-CompleteChlamydiaPostTreatmentTriage-AskAboutRepeatTest"
        }
      ]
    }
  ],
  careChlamydiaPre: [
    {
      text: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage",
      value: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage",
      children: [
        {
          text: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskIfPregnantOrBreastfeeding",
          value:
            "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskIfPregnantOrBreastfeeding"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskIfSymptomatic",
          value:
            "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskIfSymptomatic"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskIfAbstaining",
          value:
            "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskIfAbstaining"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskAboutAllergiesOrMedicines",
          value:
            "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskAboutAllergiesOrMedicines"
        },
        {
          text: "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskAboutRepeatTest",
          value:
            "Outcome-Care-CompleteChlamydiaPreTreatmentTriage-AskAboutRepeatTest"
        }
      ]
    }
  ]
};

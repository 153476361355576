import {
  GET_SERVICE_USERS_BY_REFERENCES,
  GET_SERVICE_USER_REFERENCES,
  GET_SERVICE_USER_REFERENCES_MORE,
  RESET_USER_LIST,
  SERVICE_USER_REFERENCES_SUCCESS,
  SET_PLATFORM_USER_NAME,
  UPDATE_SCROLL_POSITION,
  UPDATE_USERS_LIST_FILTERS,
  UPDATE_USER_LIST
} from "./actionTypes";

export const getServiceUserReferences = (payload) => ({
  type: GET_SERVICE_USER_REFERENCES,
  payload: { ...payload, resetPage: true }
});

export const resetUserList = () => ({
  type: RESET_USER_LIST
});

export const setServiceUserReferences = (serviceUserReferences) => ({
  type: SERVICE_USER_REFERENCES_SUCCESS,
  serviceUserReferences
});

export const getServiceUserReferencesMore = (payload) => ({
  type: GET_SERVICE_USER_REFERENCES_MORE,
  payload: { ...payload, moreLoading: true }
});

export const getServiceUsersByReferences = (serviceUserReferences) => ({
  type: GET_SERVICE_USERS_BY_REFERENCES,
  serviceUserReferences
});

export const updateUsersListFilter = () => ({
  type: UPDATE_USERS_LIST_FILTERS,
  payload: { filters: [] }
});

export const updateScroll = (payload) => ({
  type: UPDATE_SCROLL_POSITION,
  payload
});

export const updateUserList = (payload) => ({
  type: UPDATE_USER_LIST,
  payload
});

export const setPlatformUserName = (data) => ({
  type: SET_PLATFORM_USER_NAME,
  data
});

import React from "react";
import PropTypes from "prop-types";
import { Typography, Wrapper } from "preventx-storybook";

export const NotificationEndScroll = ({ children }) => (
  <Wrapper className="notification-end-scroll">
    <Typography className="notification-end-scroll__text">
      {children}
    </Typography>
  </Wrapper>
);
NotificationEndScroll.defaultProps = {
  children: "Those all your notifications from the last 30 days",
};
NotificationEndScroll.propTypes = { children: PropTypes.string };

import produce from "immer";
import {
  apiErrorObj,
  DEFAULT_PAGE_SIZE,
  validState
} from "../../constants/defaults";
import { processOutcomesOptions } from "../../constants/outcome";
import * as actionType from "./actionTypes";

const initialState = {
  form: {
    notes: {
      noteTypeId: null,
      noteCategoryIds: [],
      outcomes: [],
      content: ""
    },
    isLoading: false,
    triggerEdit: false,
    triggerUserNotes: false,
    logCareOutcome: false,
    validations: {
      noteTypeIdError: validState,
      dateError: validState,
      noteCategoriesError: validState,
      contentError: validState
    },
    isSaving: false,
    isDeletingOutcome: false
  },

  flagSpinner: {
    isLoading: false,
    noteId: null
  },

  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,

  filtersSelected: [],

  response: {
    noteTypeOptions: [],
    noteCategoryOptions: [],
    outcomeTypes: [],
    filteredOutcomeTypes: [],
    processes: [],
    notes: {
      recentNoteId: null,
      loading: true,
      notesList: {},
      notesEditHistory: {},
      flaggedUserNotes: {}
    },
    byCaseId: {
      testOutcomes: [
        {
          processType: "Initial results",
          taskReference: "TS104497",
          summary: {
            positive: ["HCV", "MG", "CT", "TV"],
            negative: ["NG", "HBV", "SYP"],
            equivocal: [],
            void: ["HIV"]
          }
        },
        {
          processType: "Initial results",
          taskReference: "TS104498",
          summary: {
            positive: ["HCV", "MG", "CT", "TV"],
            negative: ["NG", "HBV", "SYP"],
            void: [],
            equivocal: ["HIV"]
          }
        }
      ]
    },
    error: {
      notesList: apiErrorObj,
      flaggedUserNotes: apiErrorObj,
      noteCategories: apiErrorObj,
      noteTypes: apiErrorObj,
      noteOutcomes: apiErrorObj,
      noteProcesses: apiErrorObj,
      noteTasks: apiErrorObj
    },
    noteTasks: [],
    outcomeOptions: { ...processOutcomesOptions }
  },
  validations: {}
};

export const notesReducer = (state = initialState, action = null) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionType.ADD_NOTES:
        if (action.key === "edit") {
          draft.form.notes = action.data;
        } else draft.form.notes[action.key] = action.data;
        break;

      case actionType.EDIT_NOTES:
        draft.form.triggerEdit = action.triggerEdit;
        draft.form.isLoading = action.isLoading;
        draft.form.logCareOutcome = false;
        break;

      case actionType.LOG_CARE_OUTCOME:
        draft.form.logCareOutcome = action.isLogCareOutcome;
        draft.form.notes.content = "";
        draft.form.notes.outcomes = [];

        break;

      case actionType.SET_NOTES_LIST:
        draft.response.notes.loading = false;
        draft.pageNumber = action.pageNumber;

        if (action.loadMore) {
          draft.response.notes.notesList.pagedResults = [
            ...state.response.notes.notesList.pagedResults,
            ...action.data.pagedResults
          ];
        } else {
          draft.response.notes.notesList = action.data;
        }

        break;

      case actionType.UNSHIFT_NOTE:
        draft.response.notes.notesList.pagedResults = action.data.pagedResults;
        break;

      case actionType.SET_RECENT_NOTE_ID:
        draft.response.notes.recentNoteId = action.caseNoteReference;
        break;

      case actionType.CLEAR_NOTES_LIST:
        draft.response.notes.recentNoteId = null;
        draft.response.notes.loading = true;
        draft.response.notes.notesList = {};

        break;

      case actionType.SET_NOTES_ERROR:
        draft.response.notes.loading = false;
        draft.response.error[action.key] = {
          isError: true,
          message: action.message,
          code: action.code
        };
        draft.response.notes.notesList = {};
        break;

      case actionType.SET_NOTE_CATEGORIES:
        draft.response.noteCategoryOptions = action.data;
        break;

      case actionType.UPDATE_FLAGGED_NOTES:
        draft.response.notes.notesList.pagedResults = action.data;
        draft.response.notes.notesList.totalCount = action.totalCount;
        draft.response.notes.notesList.totalFlagged = action.totalFlagged;
        break;

      case actionType.SET_FLAG_SPINNER:
        draft.flagSpinner.noteId = action.noteId;
        draft.flagSpinner.isLoading = action.isLoading;
        break;

      case actionType.SET_NOTE_TYPES:
        draft.response.noteTypeOptions = action.data;
        break;

      case actionType.SET_NOTES_EDIT_HISTORY:
        draft.response.notes.notesEditHistory[action.caseNoteReference] =
          action.data;
        break;

      case actionType.SET_OUTCOME_TYPES:
        draft.response.outcomeTypes = action.data;
        break;

      case actionType.SET_FILTER_OUTCOME_TYPES:
        draft.response.filteredOutcomeTypes = action.data;
        break;

      case actionType.SET_NOTE_TASKS:
        draft.response.noteTasks = action.data;
        break;

      case actionType.SET_PROCESSES:
        draft.response.processes = action.data;
        break;

      case actionType.SET_NOTES_LIST_FILTER:
        draft.filtersSelected = action.data;
        break;

      case actionType.VALIDATE_NOTES:
        draft.form.validations = { ...state.form.validations, ...action.data };
        break;

      case actionType.SET_NOTES_LIST_LOADING:
        draft.response.notes.loading = action.isLoading;
        break;

      case actionType.SET_FLAGGED_USER_NOTES:
        draft.response.notes.flaggedUserNotes = action.data;
        break;

      case actionType.CLEAR_NOTES_ERROR:
        draft.response.error[action.key] = apiErrorObj;
        break;

      case actionType.RESET_NOTES:
        draft.response.processes = [];
        draft.response.notes.notesEditHistory = {};
        draft.response.error = {
          notesList: apiErrorObj,
          noteCategories: apiErrorObj,
          noteTypes: apiErrorObj,
          noteOutcomes: apiErrorObj,
          noteProcesses: apiErrorObj,
          noteTasks: apiErrorObj
        };
        draft.filtersSelected = [];
        break;

      case actionType.RESET_NOTES_FORM:
        draft.form.notes.noteTypeId = null;
        draft.form.notes.noteCategoryIds = [];
        draft.form.notes.outcomes = [];
        draft.form.notes.content = "";
        draft.response.notes.notesEditHistory = {};
        draft.form.triggerEdit = false;
        draft.form.isLoading = false;
        draft.form.logCareOutcome = false;
        draft.response.filteredOutcomeTypes = [];

        draft.form.validations = {
          noteTypeIdError: validState,
          noteCategoriesError: validState,
          contentError: validState,
          dateError: validState
        };
        break;

      case actionType.SET_NOTE_SAVE_SPINNER:
        draft.form.isSaving = action.isSaving;
        break;

      case actionType.TRIGGER_USER_NOTES:
        draft.form.triggerUserNotes = action.isShow;
        break;

      case actionType.SET_NOTE_DELETE_OUTCOMES_SPINNER:
        draft.form.isDeletingOutcome = action.isSaving;
        break;
      default:
        break;
    }
  });

import { Tag, Typography, UserIcon, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteNotification,
  markAsReadNotification,
  resetNotification
} from "../../../../store/userNotifications/actionCreator";
import { NotificationDelete } from "../NotificationComponents/NotificationDelete";
import { NotificationReadStatus } from "../NotificationComponents/NotificationReadStatus";
import { NotificationViewCase } from "../NotificationComponents/NotificationViewCase";
import { NotifyMessageHeader } from "../NotificationComponents/NotifiyMessageHeader";

export const NotificationCases = ({
  title,
  tags,
  author,
  createdOn,
  subscriptionCode,
  id,
  markAsRead,
  caseType
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const renderViewCase = () => {
    const { tagLabel } = tags[0];
    history.push(`/case-list/${tagLabel}`);
    dispatch(resetNotification());
  };

  const markReadHandler = () => {
    dispatch(markAsReadNotification(id));
    dispatch(resetNotification());
  };

  const deleteHandler = () => {
    dispatch(deleteNotification(id));
  };

  return (
    <Wrapper className="notification-cases">
      <NotifyMessageHeader
        message={title}
        time={createdOn}
        icon={subscriptionCode}
      />
      <Wrapper className="notification-cases__caseId">
        {tags.map(({ tagLabel }, index) => (
          <Tag text={tagLabel} type="text" key={`tag-label-${index + 1}`} />
        ))}
        <Wrapper className="notification-cases__user">
          <UserIcon className="notification-cases__userIcon" />
          <Typography className="notification-cases__userName" size={14}>
            {author}
          </Typography>
        </Wrapper>
      </Wrapper>
      <Wrapper className="notification-cases__viewCase">
        <Wrapper className="notification-cases__viewCase__status">
          {markAsRead && (
            <>
              <NotificationReadStatus
                title="Mark as read"
                markReadHandler={markReadHandler}
              />
            </>
          )}
          <NotificationViewCase
            title={`View ${caseType}`}
            viewCase={renderViewCase}
          />
        </Wrapper>
        <Wrapper className="notification-cases__delete">
          <NotificationDelete deleteHandler={deleteHandler} />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

NotificationCases.defaultProps = {
  title: "",
  tags: [],
  author: "",
  createdOn: "",
  subscriptionCode: "",
  id: "",
  caseType: "",
  markAsRead: false
};

NotificationCases.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.instanceOf(Array),
  author: PropTypes.string,
  createdOn: PropTypes.string,
  subscriptionCode: PropTypes.string,
  id: PropTypes.string,
  caseType: PropTypes.string,
  markAsRead: PropTypes.bool
};

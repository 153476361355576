import { stringify } from "query-string";
import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const PARTNER_TRACING_LIST = "PARTNER_TRACING_LIST";
const PARTNER_TRACING_LIST_SUCCESS = "PARTNER_TRACING_LIST_SUCCESS";
const PARTNER_TRACING_LIST_ERROR = "PARTNER_TRACING_LIST_ERROR";
const PARTNER_TRACING_LIST_RESET = "PARTNER_TRACING_LIST_RESET";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: []
};

function* partnerlistGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "partnertracing",
      url: `serviceUsers/${
        action.payload.serviceUserReference
      }/partners?${stringify({
        screenreferences: action.payload.screenreferences
      })}`
    });

    yield put({
      type: PARTNER_TRACING_LIST_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: PARTNER_TRACING_LIST_ERROR,
        code: e.code || e,
        message: e.message || "PartnersList-Fail"
      })
    );
  }
}

export const getPartnerTracingList = (payload) => ({
  type: PARTNER_TRACING_LIST,
  payload
});

export const resetGetPartnerTracingList = (payload) => ({
  type: PARTNER_TRACING_LIST_RESET,
  payload
});

export function* partnerListGETAction() {
  yield takeLatest([PARTNER_TRACING_LIST], partnerlistGet);
}

export const partnerTracingListReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case PARTNER_TRACING_LIST: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case PARTNER_TRACING_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }
    case PARTNER_TRACING_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case PARTNER_TRACING_LIST_RESET: {
      return {
        ...state,
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    default:
      return state;
  }
};

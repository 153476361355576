import { stringify } from "query-string";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { apiCall, apiException } from "../../services/api";
import { getNotifications } from "./actionCreator";
import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_MORE,
  MARKALLASREAD_NOTIFICATION,
  MARKASREAD_NOTIFICATION,
  SET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS_SUCCESS_MORE
} from "./actionTypes";

let readPageNumber;
let unReadPageNumber;

function* getUserNotifications(action) {
  const {
    overDuePage: { unReadOverDue, readOverDue },
    pageSize
  } = yield select((state) => state.userNotificationReducer);

  if (action.status) {
    readPageNumber = action.resetPage ? 1 : readOverDue + 1;
  } else {
    unReadPageNumber = action.resetPage ? 1 : unReadOverDue + 1;
  }

  try {
    const response = yield apiCall({
      method: "get",
      apiType: "notifications",
      url: `Notifications?${stringify({
        Read: action.status
      })}&pageNumber=${
        action.status ? readPageNumber : unReadPageNumber
      }&pageSize=${pageSize}`
    });
    yield all([
      put({
        type: action.moreLoading
          ? SET_NOTIFICATIONS_SUCCESS_MORE
          : SET_NOTIFICATIONS_SUCCESS,
        data: response,
        overDuePage: {
          unReadOverDue: unReadPageNumber,
          readOverDue: readPageNumber
        },
        status: action.status
      })
    ]);
  } catch (e) {
    yield put(
      apiException({
        type: SET_NOTIFICATIONS_ERROR,
        code: e.code || e,
        message: e.message || "Notifications-Fail"
      })
    );
  }
}

function* markasReadNotification({ notificationId }) {
  try {
    yield apiCall({
      method: "PUT",
      apiType: "notifications",
      url: `Notifications/${notificationId}/read`
    });
    yield all([put(getNotifications(true)), put(getNotifications(false))]);
  } catch (e) {
    yield put(
      apiException({
        code: e.code || e,
        message: e.message || "Notifications-MarkRead-Fail"
      })
    );
  }
}

function* markAllasReadNotification() {
  try {
    yield apiCall({
      method: "PUT",
      apiType: "notifications",
      url: `Notifications/readall`
    });
    yield all([put(getNotifications(true)), put(getNotifications(false))]);
  } catch (e) {
    yield put(
      apiException({
        code: e.code || e,
        message: e.message || "Notifications-MarkRead-Fail"
      })
    );
  }
}

function* deleteUserNotification({ notificationId }) {
  try {
    yield apiCall({
      method: "DELETE",
      apiType: "notifications",
      url: `Notifications/${notificationId}`
    });

    yield all([put(getNotifications(true)), put(getNotifications(false))]);
  } catch (e) {
    yield put(
      apiException({
        code: e.code || e,
        message: e.message || "Notifications-Delete-Fail"
      })
    );
  }
}

export function* userNotificationGETAction() {
  yield takeEvery(GET_NOTIFICATIONS, getUserNotifications);
  yield takeLatest(GET_NOTIFICATIONS_MORE, getUserNotifications);
}

export function* userNotificationPUTAction() {
  yield takeLatest(MARKASREAD_NOTIFICATION, markasReadNotification);
  yield takeLatest(MARKALLASREAD_NOTIFICATION, markAllasReadNotification);
}

export function* userNotificationDELETEAction() {
  yield takeLatest(DELETE_NOTIFICATION, deleteUserNotification);
}

import {
  Button,
  CloseIcon,
  FilterIcon,
  Typography,
  Wrapper
} from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";

const actions = (action, actionLabel) => {
  if (action === "filter") {
    return (
      <>
        <Button variant="tertiary">{actionLabel || "Filter"}</Button>
        <FilterIcon />
      </>
    );
  }
  if (action === "close") {
    return (
      <>
        <Button variant="tertiary">{actionLabel || "Close"}</Button>
        <CloseIcon />
      </>
    );
  }
  return action;
};

const actionType = (action) => {
  if (action === "filter" || action === "close") {
    return true;
  }
  return false;
};

export const RightMenuPanel = ({
  title,
  action,
  actionFullWidth,
  children,
  handler,
  actionLabel,
  noAction,
  highlightBorder
}) => (
  <Wrapper
    fw
    fh
    borderColor={highlightBorder ? "alert-highlight-general" : "none"}
    b={highlightBorder ? 4 : 0}>
    <Wrapper
      className="right-panel scrollY scrollbar"
      tabIndex={0}
      fw
      fh
      b={highlightBorder ? 1 : 0}
      borderColor={highlightBorder ? "preventx-blue" : "none"}>
      <Wrapper
        className={`right-panel__header ${
          actionType(action) ? `right-panel__header__space-between` : ""
        } ${actionFullWidth ? `right-panel__header__block` : ""}`}>
        <Typography variant="h2" className="right-panel__header-title">
          {title}
        </Typography>
        <Wrapper
          handler={() => handler()}
          className={`right-panel__header-actions ${
            actionType(action) ? `right-panel__header-actions__fit-content` : ""
          }`}>
          {!noAction && actions(action, actionLabel)}
        </Wrapper>
      </Wrapper>
      <Wrapper fw fh>
        {children}
      </Wrapper>
    </Wrapper>
  </Wrapper>
);

RightMenuPanel.defaultProps = {
  handler: () => null,
  action: "close",
  actionLabel: null,
  noAction: false,
  actionFullWidth: false,
  highlightBorder: false
};

RightMenuPanel.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string,
  actionLabel: PropTypes.string,
  actionFullWidth: PropTypes.bool,
  children: PropTypes.element.isRequired,
  handler: PropTypes.func,
  noAction: PropTypes.bool,
  highlightBorder: PropTypes.bool
};

import { Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";
import { DeleteIcon } from "../../../atoms";

export const NotificationDelete = ({ className, deleteHandler }) => (
  <Wrapper
    className={`notification-delete ${className}`}
    handler={deleteHandler}>
    <DeleteIcon />
  </Wrapper>
);

NotificationDelete.defaultProps = {
  className: "",
};

NotificationDelete.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

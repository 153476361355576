import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../../constants/defaults";
import { apiCall } from "../../../services/api";

const TRIAGE_HEADER = "TRIAGE_HEADER";
const TRIAGE_HEADER_SUCCESS = "TRIAGE_HEADER_SUCCESS";
const TRIAGE_HEADER_ERROR = "TRIAGE_HEADER_ERROR";
const TRIAGE_HEADER_RESET = "TRIAGE_HEADER_RESET";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {}
};

function* triageHeaderGet(action) {
  try {
    const triageresponse = yield apiCall({
      method: "get",
      apiType: "questionnaires",
      url: `serviceUsers/${action.payload.serviceuserReferenceId}`
    });
    const triageDetail = triageresponse.filter(
      (item) =>
        item.questionnaireReference === action.payload.QuestionnaireReference
    );
    if (triageDetail && triageDetail.length) {
      yield put({
        type: TRIAGE_HEADER_SUCCESS,
        payload: triageDetail[0]
      });
    } else {
      yield put({
        type: TRIAGE_HEADER_ERROR
      });
    }
  } catch {
    yield put({
      type: TRIAGE_HEADER_ERROR
    });
  }
}

export const gettriageHeader = (payload) => ({
  type: TRIAGE_HEADER,
  payload
});

export const gettriageHeaderReset = (payload) => ({
  type: TRIAGE_HEADER_RESET,
  payload
});

export function* triageHeaderGETAction() {
  yield takeLatest([TRIAGE_HEADER], triageHeaderGet);
}

export const triageHeaderReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case TRIAGE_HEADER: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case TRIAGE_HEADER_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case TRIAGE_HEADER_RESET: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }

    case TRIAGE_HEADER_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_PAGE_SIZE } from "../constants/defaults";

export const paging = (pageNumber = 1, pageSize = DEFAULT_PAGE_SIZE) =>
  `PageNumber=${pageNumber}&PageSize=${pageSize}`;

export const apiException = (errorDetails) => ({
  type: null,
  ...errorDetails,
  notificationType: "FAILURE"
});

export const apiSuccess = (error, type = null) => ({
  type,
  message: error,
  notificationType: "SUCCESS"
});

// to be removed later when api exception is added on all calls
export const handleError = async (response) => {
  try {
    const res = await response.json();
    return {
      code: response.status,
      message: res.errorMessage || "Error-500"
    };
  } catch (error) {
    return {
      code: response || "unknown issue",
      message: response.statusText || "Error-500"
    };
  }
};

export const apiCall = async ({
  method = "GET",
  params = {},
  data = {},
  url = "",
  headers = {},
  auth = true,
  apiType = "clinicalcases",
  version = "v1"
}) => {
  const mainUrl = `${process.env.REACT_APP_API_URL}${apiType}/api/${version}/`;

  const queryString =
    params && Object.keys(params).length
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  const request = {
    method,
    data,
    headers: {
      "Content-Type": "application/json",
      ...headers,
      ...(auth
        ? {
            "ocp-apim-subscription-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
            "preventx-request-id": uuidv4()
          }
        : {})
    }
  };
  try {
    const res = await axios(`${mainUrl}${url}${queryString}`, request);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const GET_PORTALTYPE = "GET_PORTALTYPE";
export const GET_PORTALTYPE_SUCCESS = "GET_PORTALTYPE_SUCCESS";
export const GET_PORTALTYPE_ERROR = "GET_PORTALTYPE_ERROR";
export const CASE_VIEW_TYPE_HEPC = "CASE_VIEW_TYPE_HEPC";
export const SET_DEFAULT_DROPDOWN_VALUE = " SET_DEFAULT_DROPDOWN_VALUE";
export const CASE_HEPC_GETODNLIST_SUCCESS = "CASE_HEPC_GETODNLIST_SUCCESS";
export const CASE_HEPC_GETODNLIST_ERROR= "CASE_HEPC_GETODNLIST_ERROR";
export const GET_SERVICE_PROVIDER_SETTINGS_REQUEST = "GET_SERVICE_PROVIDER_SETTINGS_REQUEST";
export const GET_SERVICE_PROVIDER_SETTINGS_SUCCESS = "GET_SERVICE_PROVIDER_SETTINGS_SUCCESS";
export const GET_SERVICE_PROVIDER_SETTINGS_ERROR= "GET_SERVICE_PROVIDER_SETTINGS_ERROR";
export const RESET_SERVICE_PROVIDER_SETTINGS = "RESET_SERVICE_PROVIDER_SETTINGS";
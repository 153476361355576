import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { useParams } from "react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CaseHeader, Loader, Page, Wrapper } from "preventx-storybook";

// Constants
import { CloseAccountStatus } from "../../constants/closeaccountstatus";

// State Management
import {
  resetNotes,
  getNoteTasks,
  getNoteTypes,
  getProcesses,
  clearNotesList,
  getOutcomeTypes,
  getNoteCategories
} from "../../store/actionTypes";
import {
  getServiceProviderSettingsRequest,
  resetServiceProviderSettingsRequest
} from "../../store/caseType/actionCreator";
import {
  clearServiceUserDetails,
  getserviceUserReference
} from "../../store/serviceuserreference/logic";
import { getTaskType } from "../../store/tasktype/logic";
import { resetMessage } from "../../store/message/logic";
import { updatecaseFollow } from "../../store/caseFollow/logic";
import { resetactivityLog } from "../../store/activitylog/logic";
import { resetresultSummary } from "../../store/resultsummary/logic";
import { getServiceProviders } from "../../store/serviceprovider/logic";
import { getCaseView, resetCaseView } from "../../store/caseHeader/logic";

// Services
import { getSpecificSettings } from "../../utils/settings";

// Components
import {
  Care,
  Testing,
  Safeguarding,
  PartnerTracing
} from "../../components/organisms";
import { AuthenticateTemplate } from "../AuthenticateTemplate";
import { CaseClosed } from "../../components/organisms/CaseClosed/CaseClosed";
import RightMenus from "../../components/organisms/PageUtils/RightMenuPanel/RightMenus";

export const CaseView = () => {
  const { caseId } = useParams();
  const dispatch = useDispatch();

  const taskTypeState = useSelector((state) => state.taskTypeReducer);
  const caseFollowState = useSelector((state) => state.caseFollowReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const caseHeaderReducer = useSelector((state) => state.caseHeaderReducer);
  const serviceProviderState = useSelector((state) => state.caseHeaderReducer);
  const { data } = useSelector((state) => state.serviceProviderSettingsReducer);

  const serviceProvidersState = useSelector(
    (state) => state.serviceProvidersReducer
  );

  const serviceUserReferenceState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );

  useEffect(() => {
    dispatch(getNoteCategories());
    dispatch(getNoteTypes());
    dispatch(getOutcomeTypes());

    dispatch(getCaseView({ caseId }));
    dispatch(getServiceProviders());
    dispatch(getNoteTasks(caseId));
    dispatch(getProcesses(caseId));

    if (taskTypeState?.data && !Object.keys(taskTypeState?.data).length) {
      dispatch(getTaskType());
    }

    return () => {
      dispatch(clearServiceUserDetails());
      dispatch(clearNotesList());
      dispatch(resetNotes());
      dispatch(resetresultSummary());
      dispatch(resetCaseView());
      dispatch(resetactivityLog());
      dispatch(resetMessage());
      dispatch(resetServiceProviderSettingsRequest());
    };
  }, [caseId]);

  useEffect(() => {
    if (caseHeaderReducer.flag) {
      dispatch(
        getserviceUserReference({
          serviceUserReference: caseHeaderReducer?.data?.serviceUserReference,
          profile: "UserDetails"
        })
      );
      dispatch(
        getServiceProviderSettingsRequest(
          serviceProviderState.data.serviceProviderReference
        )
      );
    }
  }, [caseHeaderReducer.flag]);

  const followCase = () => {
    dispatch(
      updatecaseFollow({
        caseRefrence: caseHeaderReducer.data.caseReference,
        isFollow: caseHeaderReducer?.data?.isFollowingCase
      })
    );
  };

  const enabledProcesss = getSpecificSettings(
    data,
    caseHeaderState?.data?.serviceProviderReference,
    "EnabledProcessTypes"
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Page noScroll>
          {caseHeaderReducer.loading ||
          serviceUserReferenceState.loading ||
          serviceProvidersState.loading ? (
            <Loader
              loading={
                (caseHeaderReducer.loading ||
                  serviceUserReferenceState.loading ||
                  serviceProvidersState.loading) &&
                !caseHeaderReducer.error.isError
              }
              error={caseHeaderReducer.error.isError}
              errorCode={caseHeaderReducer?.error?.code}
              errorMessage={{
                404: "case-not-found"
              }}
              height="70vh"
            />
          ) : (
            <Wrapper flex position="relative" fh>
              <Wrapper
                tabIndex={0}
                className={`caseview ${
                  CloseAccountStatus.indexOf(
                    serviceUserReferenceState?.data?.AccountStatus
                  ) === -1 && "scrollY scrollbar"
                }`}>
                <CaseHeader
                  inputModel={{
                    ...caseHeaderReducer.data,
                    ...serviceUserReferenceState.data
                  }}
                  serviceproviderModel={serviceProvidersState?.data}
                  handlerFollow={followCase}
                  loading={caseFollowState.loading}
                  isClosed={
                    CloseAccountStatus.indexOf(
                      serviceUserReferenceState?.data?.AccountStatus
                    ) > -1
                  }
                  followCase={
                    process.env.REACT_APP_FOLLOW_CASE_BUTTON_ENABLE === "true"
                  }
                />
                <div
                  className={
                    CloseAccountStatus.indexOf(
                      serviceUserReferenceState?.data?.AccountStatus
                    ) === -1
                      ? "results"
                      : "results fullheight"
                  }>
                  {CloseAccountStatus.indexOf(
                    serviceUserReferenceState?.data?.AccountStatus
                  ) === -1 ? (
                    caseHeaderReducer?.data?.processes &&
                    caseHeaderReducer?.data?.processes?.length &&
                    caseHeaderReducer?.data?.processes
                      .filter((item) =>
                        enabledProcesss
                          ? enabledProcesss?.includes(item?.processType)
                          : true
                      )
                      .map((item) => {
                        if (
                          item.processType
                            .toLocaleLowerCase()
                            .includes("safeguarding")
                        ) {
                          return <Safeguarding key="safeguarding" />;
                        }

                        if (
                          item.processType
                            .toLocaleLowerCase()
                            .includes("testing")
                        ) {
                          return <Testing key="testing" />;
                        }
                        if (
                          item.processType.toLocaleLowerCase().includes("care")
                        ) {
                          return <Care key="care" />;
                        }
                        if (
                          item.processType
                            .toLocaleLowerCase()
                            .includes("partnertracing")
                        ) {
                          return <PartnerTracing key="partnertracing" />;
                        }
                        return null;
                      })
                  ) : (
                    <CaseClosed />
                  )}
                </div>
              </Wrapper>
              <RightMenus
                defaultMenu="case-notes"
                isDisabled={
                  CloseAccountStatus.indexOf(
                    serviceUserReferenceState?.data?.AccountStatus
                  ) > -1
                }
              />
            </Wrapper>
          )}
        </Page>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

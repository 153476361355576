import { stringify } from "query-string";
import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const SEARCH_COUNT = "SEARCH_COUNT";
const SEARCH_COUNT_SUCCESS = "SEARCH_COUNT_SUCCESS";
const SEARCH_COUNT_ERROR = "SEARCH_COUNT_ERROR";
const SEARCH_COUNT_RESET = "SEARCH_COUNT_RESET";
const CLEAR_SEARCH_COUNT_NUMBERS = "CLEAR_SEARCH_COUNT_NUMBERS";
const SEARCH_UPDATE_FILTERS = "SEARCH_UPDATE_FILTERS";
const UPDATE_SEARCH_COUNT = "UPDATE_SEARCH_COUNT";

const initialState = {
  loading: false,
  error: apiErrorObj,
  data: {
    updatedCount: {}
  },
  isSearch: false
};

export const updateSearchCount = (payload) => ({
  type: UPDATE_SEARCH_COUNT,
  payload
});
export const clearSearchCountNumbers = () => ({
  type: CLEAR_SEARCH_COUNT_NUMBERS
});

function* searchCountGet(action) {
  const param = { ...action.payload };
  if (param?.ContactNumber?.value && param?.ContactNumber?.number) {
    param.ContactNumber =
      param?.ContactNumber?.value + param?.ContactNumber?.number;
  }
  try {
    const response = yield apiCall({
      method: "get",
      url: `cases/searchcount?${stringify(param)}`
    });
    yield put({
      type: SEARCH_COUNT_SUCCESS,
      payload: {
        caseCount: response?.totalCases || 0,
        userCount: response?.totalServiceUsers || 0,
        filters: action.payload,
        ...response
      }
    });
  } catch (e) {
    yield put(
      apiException({
        type: SEARCH_COUNT_ERROR,
        code: e.code || e,
        message: e.message || "CaseSearchCount-Fail"
      })
    );
  }
}

export const getsearchCount = (payload) => ({
  type: SEARCH_COUNT,
  payload
});

export const resetsearchCount = (payload) => ({
  type: SEARCH_COUNT_RESET,
  payload
});

export const updatesearchFilter = (payload) => ({
  type: SEARCH_UPDATE_FILTERS,
  payload
});

export function* searchCountGETAction() {
  yield takeLatest([SEARCH_COUNT], searchCountGet);
}

export const searchCountReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SEARCH_COUNT: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SEARCH_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload }
      };
    }

    case UPDATE_SEARCH_COUNT: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, updatedCount: action.payload }
      };
    }
    case SEARCH_COUNT_ERROR: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data.updatedCount
        },
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    case SEARCH_COUNT_RESET: {
      return {
        ...state,
        loading: false,
        data: { updatedCount: {} },
        isSearch: false,
        error: apiErrorObj
      };
    }
    case CLEAR_SEARCH_COUNT_NUMBERS: {
      return {
        ...state,
        data: { updatedCount: { ...state.data.updatedCount } }
      };
    }

    case SEARCH_UPDATE_FILTERS: {
      return {
        ...state,
        isSearch: {
          ...state.isSearch,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

import { Typography, Wrapper } from "preventx-storybook";
import React from "react";
import { InfoIconFilled } from "../../../atoms";

export const NotificationDisabled = () => (
  <Wrapper className="notification-disabled">
    <div className="notification-disabled__header">
      <div className="notification-disabled__icon">
        <InfoIconFilled />
      </div>
      <Typography className="notification-disabled__header-message">
        notifications-disabled
      </Typography>
    </div>
    <div className="notification-disabled__message">
      <Typography>notifications-settings_tweak-what</Typography>
    </div>
  </Wrapper>
);

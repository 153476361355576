const ERROR_STATUS = "ERROR_STATUS";

const initialState = {
  errorstatus: "",
};

export function errorStatusAction({ errorstatus }) {
  return {
    type: ERROR_STATUS,
    payload: {
      errorstatus,
    },
  };
}

export function errorStatusReducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_STATUS:
      return {
        ...state,
        ...(action?.payload || {}),
      };
    default:
      return state;
  }
}

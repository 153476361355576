import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";
import { updateCaseView } from "../caseHeader/logic";

const CASE_FOLLOW = "CASE_FOLLOW";
const CASE_FOLLOW_SUCCESS = "CASE_FOLLOW_SUCCESS";
const CASE_FOLLOW_ERROR = "CASE_FOLLOW_ERROR";

const initialState = {
  loading: false,
  error: apiErrorObj,
  data: []
};

function* caseFollowGet(action) {
  try {
    const response = yield apiCall({
      method: "put",
      url: `cases/${action.payload.caseRefrence}/${
        action.payload.isFollow ? "unfollow" : "follow"
      }`
    });

    yield put({
      type: CASE_FOLLOW_SUCCESS,
      payload: response
    });
    yield put(
      updateCaseView({
        isFollowingCase: !action.payload.isFollow
      })
    );
  } catch (e) {
    yield put(
      apiException({
        type: CASE_FOLLOW_ERROR,
        code: e.code || e,
        message: e.message || "CaseFollow-Fail"
      })
    );
  }
}

export const updatecaseFollow = (payload) => ({
  type: CASE_FOLLOW,
  payload
});

export function* caseFollowPOSTAction() {
  yield takeLatest([CASE_FOLLOW], caseFollowGet);
}

export const caseFollowReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CASE_FOLLOW: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CASE_FOLLOW_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case CASE_FOLLOW_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

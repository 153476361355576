import { Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { NewNote, NotesList } from ".";
import {
    warningStatusNotificationAction,
} from  "../../../../store/notification/logic"


import {
  addNotes,
  getNotesList,
  clearNotesList,
  getFlaggedUserNotes,
  triggerUserNotes
} from "../../../../store/actionTypes";
import { updateNoteOutcomesKey } from "../../../../utils/helper";

export const Notes = ({ noteType }) => {
  const { caseId, serviceuserReferenceId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [view, setView] = useState("notesList");
  const [noteName, setNoteName] = useState(
    ((serviceuserReferenceId || noteType === "serviceUser") && "user") ||
      (caseId && "case")
  );

  const dispatch = useDispatch();
  const caseHeaderReducer = useSelector((state) => state.caseHeaderReducer);

  const { form, filtersSelected } = useSelector((state) => state.notesReducer);
  const { isLoading, triggerEdit, logCareOutcome } = form;

  const reference =
    (noteType === "serviceUser" &&
      caseHeaderReducer?.data?.serviceUserReference) ||
    caseId ||
    serviceuserReferenceId;

  const viewHandler = (viewName = "notesList") => {
    setView(viewName);
  };
    const editNoteHandler = (note, viewName = "editNote") => {
       const {
        isEditable,
        noteTypeId,
        noteCategoryIds,
        content,
        caseNoteReference,
        serviceUserNoteId,
        outcomes
        } = note;
        let updatedContent = {};
        if (isEditable) {
            if (noteName === "user") {
                updatedContent = {
                    serviceUserReference: serviceuserReferenceId,
                    serviceProviderReference: "string",
                    serviceUserNoteId,
                    content,
                    noteTypeId: null
                };
            } else if (noteName === "case") {
                const outcomesCpy = updateNoteOutcomesKey(outcomes);
                updatedContent = {
                    caseReference: caseId,
                    noteTypeId,
                    noteCategoryIds,
                    caseNoteReference,
                    content,
                    outcomes: outcomesCpy
                };
            }
            dispatch(addNotes("edit", updatedContent));
            setView(viewName);
        } else {
            dispatch(
                warningStatusNotificationAction({
                    message: window.translate("noteeditable-notallowed")
                })
            );
        }
  };

  useEffect(() => {
    if (triggerEdit) {
      setView("editNote");
    }
    if (!triggerEdit && view === "editNote") {
      setView("notesList");
    }
    if (logCareOutcome) {
      setView("newNote");
    }
  }, [isLoading, logCareOutcome, triggerEdit]);

  useEffect(() => {
    dispatch(clearNotesList());
    if (noteName === "user") {
      const userFilter = { ...filtersSelected, filterBy: "byUser" };
      dispatch(getNotesList(noteName, reference, 0, 1, userFilter));
      return;
    }

    dispatch(getNotesList(noteName, reference));
    dispatch(
      getFlaggedUserNotes(caseHeaderReducer?.data?.serviceUserReference)
    );
  }, [noteName]);

  useEffect(() => {
    const menuName =
      ((serviceuserReferenceId || noteType === "serviceUser") && "user") ||
      (caseId && "case");
    setNoteName(menuName);
    dispatch(triggerUserNotes(false));
    if (noteType !== "serviceUser") {
      setTabIndex(0);
    }
  }, [noteType]);

  const setNoteNameHandler = (name) => {
    setNoteName(name);
    if (name === "serviceUser") {
      dispatch(triggerUserNotes(true));
      setTabIndex(1);
    }
  };

  return (
    <Wrapper fh>
      {view === "notesList" && !triggerEdit && (
        <NotesList
          newNoteHandler={() => viewHandler("newNote")}
          noteNameHandler={setNoteNameHandler}
          editNoteHandler={editNoteHandler}
          tabIndex={tabIndex}
          addDetailsHandler={(noteContent) =>
            editNoteHandler(noteContent, "newNote")
          }
          filterNotesHandler={() => viewHandler("filterNotes")}
          reference={reference}
          noteName={noteName}
        />
      )}

      {(view === "newNote" || view === "editNote") && (
        <Wrapper fh pb={10}>
          <NewNote
            closeHandler={viewHandler}
            isEdit={view === "editNote"}
            reference={reference}
            noteName={noteName}
            triggerEdit={triggerEdit}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};
Notes.defaultProps = {
  noteType: ""
};
Notes.propTypes = {
  noteType: PropTypes.string
};

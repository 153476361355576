import React from "react";
import { useLocation } from "react-router";
import { LabReports } from "../LabReport";
import { LabReportsV2 } from "./LabReportV2";

export function LabReportContainer() {
  const location = useLocation();
  const version = new URLSearchParams(location?.search).get("version");

  return version === "v2" ? <LabReportsV2 /> : <LabReports />;
}

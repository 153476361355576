import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const ADDITIONAL_METRICS = "ADDITIONAL_METRICS";
const ADDITIONAL_METRICS_SUCCESS = "ADDITIONAL_METRICS_SUCCESS";
const ADDITIONAL_METRICS_ERROR = "ADDITIONAL_METRICS_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {}
};

function* additionalMetricsGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `screens/${action.payload.screenReference}/additionalmetrics`
    });

    yield put({
      type: ADDITIONAL_METRICS_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: ADDITIONAL_METRICS_ERROR,
        code: e.code || e,
        message: e.message || "AdditionalMetrics-Fail"
      })
    );
  }
}

export const getAdditionalMetrics = (payload) => ({
  type: ADDITIONAL_METRICS,
  payload
});

export function* additionalMetricsGETAction() {
  yield takeLatest([ADDITIONAL_METRICS], additionalMetricsGet);
}

export const additionalMetricsReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case ADDITIONAL_METRICS: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case ADDITIONAL_METRICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case ADDITIONAL_METRICS_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

import { all } from "@redux-saga/core/effects";
import {
  activityLogGETAction,
  additionalMetricsGETAction,
  caseFollowPOSTAction,
  caselistGETAction,
  caseViewGETAction,
  createTaskGETAction,
  filterListGETAction,
  labAccreditationGETAction,
  notesGETAction,
  notesPOSTAction,
  notesPUTAction,
  processCareGETAction,
  processPartnerGETAction,
  processSafeguardingGETAction,
  processTestingGETAction,
  recordSummaryGETAction,
  sampleReceivedsGETAction,
  searchCaseGETAction,
  searchCountGETAction,
  searchUserGETAction,
  taskTypeGETAction,
  testBookingsGETAction,
  todaylistGETAction,
  userDetailsGETAction,
  userNotificationDELETEAction,
  userNotificationGETAction,
  userNotificationPUTAction,
  userSubscriptionPUTAction,
  userSubscriptionsGETAction,
  userSubscriptionsPOSTAction,
  resultSummaryGETAction,
  caseHistoryGETAction,
  serviceUserReferenceGETAction,
  triageReportGETAction,
  serviceProvidersGETAction,
  navbarCasesCountGETAction,
  serviceUserReferencePUTAction,
  createCasePostAction,
  accountStatusGETAction,
  partnerListGETAction,
  messageGETAction,
  eventLogGETAction,
  taskLogGETAction,
  triageHeaderGETAction,
  showOdnListForHepcAction,
  searchScreenReferenceGETAction,
  labReportGetAction,
  serviceProviderSettingsGetAction
} from "./logics";

export default function* rootSaga() {
  yield all([
    testBookingsGETAction(),
    todaylistGETAction(),
    caseViewGETAction(),
    notesGETAction(),
    notesPOSTAction(),
    notesPUTAction(),
    filterListGETAction(),
    userDetailsGETAction(),
    searchCountGETAction(),
    searchCaseGETAction(),
    caselistGETAction(),
    searchUserGETAction(),
    userNotificationGETAction(),
    userNotificationPUTAction(),
    userNotificationDELETEAction(),
    userSubscriptionsGETAction(),
    userSubscriptionsPOSTAction(),
    userSubscriptionPUTAction(),
    caseFollowPOSTAction(),
    taskTypeGETAction(),
    createTaskGETAction(),
    processSafeguardingGETAction(),
    processPartnerGETAction(),
    processCareGETAction(),
    processTestingGETAction(),
    sampleReceivedsGETAction(),
    additionalMetricsGETAction(),
    recordSummaryGETAction(),
    activityLogGETAction(),
    resultSummaryGETAction(),
    labAccreditationGETAction(),
    caseHistoryGETAction(),
    serviceUserReferenceGETAction(),
    triageReportGETAction(),
    serviceProvidersGETAction(),
    navbarCasesCountGETAction(),
    serviceUserReferencePUTAction(),
    createCasePostAction(),
    accountStatusGETAction(),
    partnerListGETAction(),
    messageGETAction(),
    eventLogGETAction(),
    taskLogGETAction(),
    triageHeaderGETAction(),
    showOdnListForHepcAction(),
    searchScreenReferenceGETAction(),
    labReportGetAction(),
    serviceProviderSettingsGetAction()
  ]);
}

const FORCE_REFRESH = "FORCE_REFRESH";

const initialState = {
  isRefresh: false
};

export function forcePageRefresh(isRefresh) {
  return {
    type: FORCE_REFRESH,
    isRefresh
  };
}

export function forceRefreshReducer(state = initialState, action) {
  switch (action.type) {
    case FORCE_REFRESH:
      return {
        ...state,
        isRefresh: action.isRefresh
      };
    default:
      return state;
  }
}

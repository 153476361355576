import { useDispatch, useSelector } from "react-redux";

// Constants
import {
  API_ERROR_500,
  KIT_ORDER_API_ERROR
} from "../../constants/errormessage";
import { ORDERS_API_TYPE } from "../../constants/defaults";

// Services
import { apiCall, apiException } from "../../services/api";
import { getSpecificSettings } from "../../utils/settings";
import { goToMessage, goToOldApp } from "../../utils/helper";

const useMenuSettings = () => {
  const dispatch = useDispatch();

  const serviceUserReferenceState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );

  const safeGuardingState = useSelector((state) => state.safeGuardingReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const { data } = useSelector((state) => state.serviceProviderSettingsReducer);

  const redirectToOrderKit = () => {
    let url = `serviceUsers/${caseHeaderState?.data?.serviceUserReference}/kitredirect`;
    if (safeGuardingState?.data?.questionnaireReference) {
      url = `${url}?questionnaireReference=${safeGuardingState?.data?.questionnaireReference}`;
    }
    apiCall({
      apiType: ORDERS_API_TYPE,
      url
    })
      .then((response) => {
        if (response?.url !== null) {
          if (response?.url.indexOf("?") > -1) {
            goToMessage(response?.url);
          } else {
            goToOldApp(response?.url, true);
          }
        } else {
          dispatch(
            apiException({
              type: null,
              message: KIT_ORDER_API_ERROR
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          apiException({
            type: null,
            message: API_ERROR_500
          })
        );
      });
  };

  let moreAction = {};
  const suppressOperations = getSpecificSettings(
    data,
    caseHeaderState?.data?.serviceProviderReference,
    "SuppressOperations"
  );

  if (
    !suppressOperations?.includes("KitOrder") &&
    serviceUserReferenceState?.data?.LegacyUID
  ) {
    moreAction = {
      menu: [
        {
          title: "Direct-Order",
          handler: redirectToOrderKit
        }
      ],
      title: "more-actions"
    };
  }

  return { moreAction };
};

export default useMenuSettings;

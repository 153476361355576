import {
  GET_LABREPORT_REQUEST,
  GET_LABREPORT_SUCCESS,
  GET_LABREPORT_ERROR
} from "./actionType";
import { apiErrorObj } from "../../constants/defaults";

const initialState = {
  data: {},
  loading: false,
  error: apiErrorObj
};

export const labReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABREPORT_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_LABREPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case GET_LABREPORT_ERROR: {
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }

    default:
      return state;
  }
};

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import {
  FILTER_FOOTER_HEIGHT,
  FILTER_HEADER_HEIGHT,
  NAV_HEIGHT
} from "../constants/defaults";

export function ValidateDateGreaterToday(dt, format, isMandatory = false) {
  if (!isMandatory && !dt) {
    return true;
  }
  dayjs.extend(customParseFormat);
  const date = dayjs(dt, format, true);
  const today = dayjs();
  const futureDate = dayjs(dt, format);
  if (date.isValid()) {
    if (futureDate.isAfter(today)) {
      return true;
    }
    return "Date should be greater then today";
  }
  return "Invalid date";
}

export function ValidateDate(dt, format, isMandatory = false) {
  if (!isMandatory && !dt) {
    return true;
  }
  dayjs.extend(customParseFormat);
  const date = dayjs(dt, format, true);
  if (date.isValid()) {
    return true;
  }
  return window.translate("Invalid-Date");
}

export function DateShouldNotBeGreaterThenToday(
  dt,
  format,
  isMandatory = false
) {
  if (!isMandatory && !dt) {
    return true;
  }
  dayjs.extend(customParseFormat);
  const date = dayjs(dt, format, true);
  const futureDate = dayjs(dt, format);
  const today = dayjs();
  if (date.isValid()) {
    if (futureDate.isAfter(today)) {
      return window.translate("Birth-Date-Error");
    }
    return true;
  }
  return window.translate("Invalid-Date");
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

export function convertToFormat(dt, format) {
  return dayjs(dt).format(format);
}

export function convertToUTC(dt, format) {
  dayjs.extend(utc);
  return dayjs(dt, format).utc().format();
}

export function scrollContent(content, className) {
  if (content.scrollposition) {
    const ele = document.getElementsByClassName(className)[0];
    if (ele) {
      ele.scrollTo(0, content.scrollposition);
    }
  }
}

export function addDay(day, format) {
  dayjs.extend(advancedFormat);
  const dt = dayjs().add(day, "day");
  return dayjs(dt).format(format);
}

export const minWindowWidth = () => window.innerWidth < 950;

export const dropdownConverter = (options, textKey, valueKey) => {
  const newOptions = [];
  options.forEach((item) => {
    if (typeof item === "object") {
      newOptions.push({
        text: item[textKey],
        value: item[valueKey]
      });
    } else {
      newOptions.push({
        text: item,
        value: item
      });
    }
  });
  return newOptions;
};
export const notesFilterOptions = (options) => {
  const newOptions = [];
  options.forEach((item) => {
    newOptions.push({
      text: `${item.text}-Notes`,
      value: item.value
    });
  });
  return newOptions;
};

export const convertToDate = (dateString, currentformat, expectedformat) => {
  dayjs.extend(customParseFormat);
  const dt = dayjs(dateString, currentformat);
  return dayjs(dt).endOf("day").format(`${expectedformat}THH:mm:ss`);
};

export const convertToDateTime = (
  dateString,
  currentformat,
  expectedformat
) => {
  dayjs.extend(customParseFormat);
  const dt = dayjs(dateString, currentformat);
  return dayjs(dt).format(`${expectedformat}THH:mm:ss`);
};

export const datePickerFormat = (dateString, currentformat, expectedformat) => {
  if (!dateString) {
    return null;
  }
  dayjs.extend(customParseFormat);
  const dt = dayjs(dateString, currentformat);
  return dayjs(dayjs(dt).format(expectedformat)).toDate();
};

export const extractDate = (timeStamp) => {
  if (!timeStamp) return false;
  return dayjs(timeStamp).format("DD MMM YYYY");
};

export const extractTime = (timeStamp) => {
  if (!timeStamp) return false;
  return dayjs(timeStamp).format("HH:mm");
};

export function dayconvertToFormat(dt, format) {
  dayjs.extend(customParseFormat);
  return dayjs(dt).format(format);
}

export const mainInfiniteScrollHeight = () =>
  minWindowWidth() ? "calc(100vh - 190px)" : "calc(100vh - 170px)";

export const rightPanelInfiniteScrollHeight = () => {
  if (window.innerWidth < 400) {
    return 380;
  }
  if (window.innerWidth < 770) {
    return 330;
  }
  return 325;
};

export const filterBodyHeight = () => {
  const { innerHeight, innerWidth } = window;
  const dynamicHeight =
    innerHeight -
    NAV_HEIGHT -
    FILTER_HEADER_HEIGHT -
    (innerWidth < 720 ? FILTER_FOOTER_HEIGHT : 0);
  return `${dynamicHeight}px`;
};

export const subtractNavHeight = () => `${window.innerHeight - NAV_HEIGHT}px`;

export const calcNotesListHeight = () => {
  const { innerHeight, innerWidth } = window;
  const dynamicHeight = innerHeight - (innerWidth < 720 ? 285 : 296);
  return `${dynamicHeight}px`;
};

export const calcMessageHeight = () => window.innerHeight - 218;

export const calcScrollHeight = (type) => {
  const { innerHeight, innerWidth } = window;

  let height = 0;
  switch (type) {
    case "userDetail":
      height = 75;
      break;
    case "triage":
      height = 142;
      break;
    case "caseHistory":
      height = innerWidth < 450 ? 100 : 100;
      break;
    case "messages":
      height = 128;
      break;
    case "activityLog":
      height = 68;
      break;
    default:
      height = 104;
  }

  const dynamicHeight =
    innerHeight -
    (innerWidth < 720 ? height + NAV_HEIGHT + 44 : height + NAV_HEIGHT);
  return `${dynamicHeight}px`;
};

export const convertToDateOnlyFormat = (dateString, currentformat, expectedformat) => {
  dayjs.extend(customParseFormat);
  const dt = dayjs(dateString, currentformat);
  return dayjs(dt).endOf("day").format(`${expectedformat}`);
};
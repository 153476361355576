import {
  MedicalIcon,
  MessageIcon,
  NotificationIcon,
  Typography,
  Wrapper,
  Switch
} from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { FeatureIcon } from "../../../atoms";

export const NotifyToggle = ({ icon, title, id, checked, handler }) => (
  <Wrapper
    flex
    justify="space-between"
    align="center"
    className="notify-toggle-alert">
    <Wrapper flex>
      <Wrapper className="notify-toggle-alert__icon">
        {icon === "AlertsAndPopupsSubscription" && <NotificationIcon />}
        {icon === "MessagesSubscription" && <MessageIcon />}
        {icon === "ClinicalCaseFollowingSubscription" && <MedicalIcon />}
        {icon === "FeatureUpdatesSubscription" && <FeatureIcon />}
      </Wrapper>
      <Wrapper className="notify-toggle-alert__title">
        <Typography translate>{title}</Typography>
      </Wrapper>
    </Wrapper>
    <Switch id={id} checked={!checked} onChange={handler} value={title} />
  </Wrapper>
);

NotifyToggle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handler: PropTypes.func.isRequired
};

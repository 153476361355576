import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const SEARCH_USER = "SEARCH_USER";
const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
const SEARCH_USER_ERROR = "SEARCH_USER_ERROR";
const SEARCH_USER_RESET = "SEARCH_USER_RESET";
const SEARCH_USER_RESET_ERROR_FLAG = "SEARCH_USER_RESET_ERROR_FLAG";

const initialState = {
  loading: false,
  error: apiErrorObj,
  data: {},
  flag: false
};

function* searchUserGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `cases/searchcount?ServiceUserReference=${action.payload.ServiceUserReference}`
    });

    if (response?.totalServiceUsers > 0) {
      yield put({
        type: SEARCH_USER_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: SEARCH_USER_ERROR,
        payload: {
          ...action.payload
        }
      });
    }
  } catch (e) {
    yield put(
      apiException({
        type: SEARCH_USER_ERROR,
        code: e.code || e,
        message: e.message || "ServiceUserSearchCount-Fail"
      })
    );
  }
}

export const getsearchUser = (payload) => ({
  type: SEARCH_USER,
  payload
});

export const resetsearchUser = () => ({
  type: SEARCH_USER_RESET
});

export const resetsearchUserErrorFlag = () => ({
  type: SEARCH_USER_RESET_ERROR_FLAG
});

export function* searchUserGETAction() {
  yield takeLatest([SEARCH_USER], searchUserGet);
}

export const searchUserReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SEARCH_USER: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SEARCH_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }
    case SEARCH_USER_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case SEARCH_USER_RESET: {
      return {
        ...state,
        data: "",
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    case SEARCH_USER_RESET_ERROR_FLAG: {
      return {
        ...state,
        error: apiErrorObj
      };
    }
    default:
      return state;
  }
};

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";
import { taskLogGet } from "../../utils/helper";
import { updateCaseView } from "../caseHeader/logic";

const TESTING_UPDATE = "TESTING_UPDATE";

const TESTING = "TESTING";
const TESTING_SUCCESS = "TESTING_SUCCESS";
const TESTING_ERROR = "TESTING_ERROR";
const TESTING_RESET = "TESTING_RESET";
const TESTING_UPDATE_SUCESS = "TESTING_UPDATE_SUCESS";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: [],
  flag: false
};

function* processGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `Cases/${action.payload.caseReference}`
    });
    const tmpData = response?.processes.filter(
      (item) => item.processReference === action.payload.processReference
    );
    if (tmpData.length) {
      yield taskLogGet({
        payload: tmpData[0],
        type: TESTING_UPDATE_SUCESS
      });
    } else {
      yield put({
        type: TESTING_SUCCESS,
        payload: {
          processReference: action.payload.processReference,
          process: response?.processes
        }
      });
    }
    yield put(updateCaseView(response));
  } catch (e) {
    yield put(
      apiException({
        type: TESTING_ERROR,
        code: e.code || e,
        message: e.message || "TestingProcess-Fail"
      })
    );
  }
}

function* taskGetLog(action) {
  yield taskLogGet({
    payload: action.payload,
    type: TESTING_UPDATE_SUCESS
  });
}

export const getTestingProcess = (payload) => ({
  type: TESTING,
  payload
});

export const updateTesting = (payload) => ({
  type: TESTING_UPDATE,
  payload
});

export const resetGetTesting = (payload) => ({
  type: TESTING_RESET,
  payload
});

export function* processTestingGETAction() {
  yield takeLatest([TESTING], processGet);
  yield takeLatest([TESTING_UPDATE], taskGetLog);
}

export const testingReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case TESTING: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case TESTING_SUCCESS: {
      const tmpData = action.payload.process.filter(
        (item) => item.processReference === action.payload.processReference
      );
      return {
        ...state,
        loading: false,
        flag: true,
        data: tmpData &&
          tmpData.length && {
            ...state?.data,
            ...tmpData[0]
          }
      };
    }
    case TESTING_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case TESTING_RESET: {
      return {
        ...state,
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    case TESTING_UPDATE: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }
    case TESTING_UPDATE_SUCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    default:
      return state;
  }
};

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { Localization } from "preventx-storybook";
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import App from "./App";
import { msalConfig } from "./AuthConfig";
import "./index.css";
import store from "./store/store";


export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { payload: account } = event;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <CookiesProvider>
        <Localization version={process.env.REACT_APP_LOCALIZATION_ENV}>
          <Provider store={store}>
            <App pca={msalInstance} />
          </Provider>
        </Localization>
      </CookiesProvider>
    </Suspense>
  </React.StrictMode>
);

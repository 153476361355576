import { Typography, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";

export const NotificationViewCase = ({ title, viewCase }) => (
  <Wrapper className="notification-view-case" handler={viewCase}>
    <Typography color="preventx-blue" size={14}>
      {title}
    </Typography>
  </Wrapper>
);

NotificationViewCase.defaultProps = {
  viewCase: () => {}
};

NotificationViewCase.propTypes = {
  title: PropTypes.string.isRequired,
  viewCase: PropTypes.func
};

import _ from "lodash";
import {
  Divider,
  Loader,
  SidebarTriageCard,
  TabButtons,
  Wrapper
} from "preventx-storybook";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { RightMenuPanel } from "..";
import {
  getTriageHistory,
  resetTriageHistory
} from "../../../store/triageReport/actionCreators";
import { calcScrollHeight } from "../../../utils/utilsFunction";

export const Triages = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { caseId, serviceuserReferenceId } = useParams();

  const {
    triageHistoryLoading,
    allTriagesError,
    relatedTriagesError,
    allTraiges,
    relatedTriages
  } = useSelector((state) => state.triageReportReducer);

  const { serviceUserReference, processes } = useSelector(
    (state) => state.caseHeaderReducer.data
  );
  const serviceUserId = serviceuserReferenceId || serviceUserReference;

  const [activeTab, setActiveTab] = useState(serviceuserReferenceId ? 1 : 0);

  const selectedTab = (index) => setActiveTab(index);

  const tabNames = [
    {
      name: "Related",
      count: relatedTriages?.length,
      isDisabled: serviceuserReferenceId || false
    },
    { name: "All", count: allTraiges?.length }
  ];

  const screenReferences = () => {
    const testIndex = _.find(processes, { processType: "ProcessType-Testing" });
    if (testIndex) {
      if (testIndex.screenReferences.length > 0) {
        return testIndex.screenReferences;
      }
    }
    return [];
  };

  useEffect(() => {
    dispatch(
      getTriageHistory(
        serviceUserId,
        activeTab === 0 ? screenReferences() : [],
        activeTab === 0 ? "related" : "all"
      )
    );
  }, []);

  useEffect(() => {
    if (!serviceuserReferenceId) {
      dispatch(getTriageHistory(serviceUserId, null, "all"));
    }
    return () => {
      dispatch(resetTriageHistory());
    };
  }, []);

  const showTriageReport = (QuestionnaireReference) => {
    history.push(`/triage-report/${QuestionnaireReference}/${serviceUserId}`);
  };

  return (
    <RightMenuPanel title="Triages" noAction>
      <Wrapper>
        <Wrapper className="triages-tab">
          <TabButtons
            tabNames={tabNames}
            activeTab={activeTab}
            handler={selectedTab}
          />
        </Wrapper>
        <Wrapper>
          <Divider absolute />
        </Wrapper>
        <Wrapper
          className="triages-card__body scrollbar"
          tabIndex={0}
          pt={16}
          style={{ height: calcScrollHeight("triage") }}>
          <Loader
            loading={triageHistoryLoading}
            error={
              activeTab === 0
                ? relatedTriagesError.isError
                : allTriagesError.isError
            }
            errorMessage={
              activeTab === 0
                ? relatedTriagesError.message
                : allTriagesError.message
            }
            errorCode={
              activeTab === 0 ? relatedTriagesError.code : allTriagesError.code
            }
            height="20vh"
            noData={
              activeTab === 0
                ? relatedTriages?.length < 1
                : allTraiges?.length < 1
            }
            noDataMessage={
              activeTab === 0
                ? relatedTriages?.length < 1 && "No-Triage-Found"
                : allTraiges?.length < 1 && "No-Triage-For-Case"
            }>
            {activeTab === 0 ? (
              <>
                {relatedTriages?.length > 0 &&
                  relatedTriages?.map((ele, index) => (
                    <SidebarTriageCard
                      key={`triage-card-${index + 1}`}
                      inputModel={ele}
                      mb={16}
                      caseId={caseId}
                      handler={showTriageReport}
                    />
                  ))}
              </>
            ) : (
              allTraiges?.map((ele, index) => (
                <SidebarTriageCard
                  key={`triage-history-${index + 1}`}
                  inputModel={ele}
                  mb={16}
                  caseId={caseId}
                  handler={showTriageReport}
                />
              ))
            )}
          </Loader>
        </Wrapper>
      </Wrapper>
    </RightMenuPanel>
  );
};

import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

// Components
import {
  Select,
  Wrapper,
  Divider,
  EventLog,
  translate
} from "preventx-storybook";

// State Management
import {
  getactivityLog,
  resetactivityLog,
  getactivityLogMore
} from "../../../store/activitylog/logic";

// Services
import { calcScrollHeight } from "../../../utils/utilsFunction";

// Components
import { RightMenuPanel } from "../PageUtils/RightMenuPanel/RightMenuPanel";

export const ActivityLog = () => {
  const { t } = translate();
  const dispatch = useDispatch();
  const { caseId, serviceuserReferenceId } = useParams();
  const reference = caseId || serviceuserReferenceId;
  const activityLogState = useSelector((state) => state.activityLogReducer);

  const [activityFilter, setActivityFilter] = useState("");

  useEffect(() => {
    dispatch(
      getactivityLog({
        reference,
        activityFilter,
        activityLogType: caseId ? "Case" : "ServiceUser"
      })
    );

    return () => {
      dispatch(resetactivityLog());
    };
  }, [activityFilter]);

  const handlerfetchMoreData = () =>
    dispatch(getactivityLogMore({ reference }));

  return (
    <Wrapper>
      <RightMenuPanel title="Activity Log" noAction pt={16}>
        <Wrapper>
          <Select
            selected={activityFilter}
            handler={setActivityFilter}
            placeHolder="Filter by..."
            options={[
              { text: t("NoFilter"), value: "" },
              { text: t("OrdersOnly"), value: "ActivityMetadataKey-LegacyId" }
            ]}
          />
          <Wrapper pt={16}>
            <Divider absolute />
          </Wrapper>
        </Wrapper>
        <EventLog
          hideHeader
          type="activity"
          className="case-activitylog"
          InfiniteScroll={InfiniteScroll}
          isLoading={activityLogState.loading}
          summary={activityLogState?.data || {}}
          isError={activityLogState.error.isError}
          handlerfetchMoreData={handlerfetchMoreData}
          scrollHeight={calcScrollHeight("activityLog")}
        />
      </RightMenuPanel>
    </Wrapper>
  );
};

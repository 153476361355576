import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Wrapper,
  Panel,
  translate
} from "preventx-storybook";
import { useSelector } from "react-redux";

const baseClass = "sb-closecard";

export const CaseClosed = ({ supportemail, maxWidth, className }) => {
  const { t } = translate();
  const serviceUserReferenceState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );
  const getClasses = () => {
    const classes = `${baseClass} ${className}`;
    return classes;
  };
  return (
    <Wrapper className={getClasses()} style={{ maxWidth, margin: "0 auto" }}>
      <Panel>
        <Wrapper p={16}>
          <Typography size={20} weight="medium">
            Account-Closed-Title
          </Typography>
          <Typography>{supportemail}</Typography>
          <Wrapper mt={8}>
            <Typography style={{ display: "inline-block" }}>
              Account-Closed-Subtitle1
            </Typography>
            <a
              href={`mailto:${
                process.env.REACT_APP_CONTACT_SUPPORT
              }?subject=${t(
                serviceUserReferenceState?.data?.AccountStatus || ""
              )}`}
              className="contact__link"
              title={process.env.REACT_APP_CONTACT_SUPPORT}>
              &nbsp;<Typography>Contact-Support</Typography>
            </a>
            &nbsp;
            <Typography style={{ display: "inline-block" }}>
              Account-Cloase-Subtitle2
            </Typography>
          </Wrapper>
        </Wrapper>
      </Panel>
    </Wrapper>
  );
};

CaseClosed.defaultProps = {
  supportemail: "",
  maxWidth: 720,
  className: ""
};

CaseClosed.propTypes = {
  supportemail: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import _ from "lodash";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";
import { getState, injectCreatedUserNames } from "../../utils/helper";
import { convertToFormat } from "../../utils/utilsFunction";

const ACTIVITY_LOG = "ACTIVITY_LOG";
const ACTIVITY_LOG_MORE = "ACTIVITY_LOG_MORE";
const ACTIVITY_LOG_SUCCESS = "ACTIVITY_LOG_SUCCESS";
const ACTIVITY_LOG_MORE_SUCCESS = "ACTIVITY_LOG_MORE_SUCCESS";
const ACTIVITY_LOG_ERROR = "ACTIVITY_LOG_ERROR";
const ACTIVITY_LOG_RESET = "ACTIVITY_LOG_RESET";
const SET_CREATED_USER_IDS = "SET_CREATED_USER_IDS";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {},
  createdUserIds: {},
  pageNumber: 1
};

export function* filterAndFetchCreatedUserNames(createdUserReferences = []) {
  const createdUserIds = yield getState("activityLogReducer", "createdUserIds");

  const fetchedKeys = _.keys(createdUserIds);

  /* Filter the user references that are not present in both arrays. 
     This is used to optimize the api call.
  */
  const keysToFetch = _.filter(
    createdUserReferences,
    (u) => fetchedKeys.indexOf(u) < 0
  );
  if (keysToFetch.length > 0) {
    let isError = false;
    try {
      const response = yield all([
        ..._.map(keysToFetch, (id) =>
          apiCall({
            apiType: "platformusers",
            url: `platformusers/${id}`
          })
            .then((res) => ({
              id,
              displayName: res.displayName
            }))
            .catch((e) => {
              isError = e;
              return { [id]: null };
            })
        )
      ]);
      if (isError) {
        throw isError;
      }

      const createdUserDetails = {};
      _.forEach(response, (r) => {
        createdUserDetails[r?.id] = r?.displayName;
      });
      yield put({
        type: SET_CREATED_USER_IDS,
        createdUserIds: createdUserDetails
      });
      return createdUserDetails;
    } catch (e) {
      const err = { code: e, message: "ActivityLog-CreatedUser-Fail" };
      throw err;
    }
  }
  return createdUserIds;
}

function* activityLogGet(action) {
  const { pageNumber } = yield select((state) => state.activityLogReducer);

  try {
    const response = yield apiCall({
      method: "get",
      apiType: "ActivityLogs",
      url: `logs/ActivityGroup-${action.payload.activityLogType}/${action.payload.reference}?pageSize=10&pageNumber=${pageNumber}&activityFilter=${action.payload.activityFilter}`
    });

    const updatedResponse = yield injectCreatedUserNames(response);

    yield put({
      type: action.payload.moreLoading
        ? ACTIVITY_LOG_MORE_SUCCESS
        : ACTIVITY_LOG_SUCCESS,
      payload: updatedResponse
    });
  } catch (e) {
    yield put(
      apiException({
        type: ACTIVITY_LOG_ERROR,
        code: e.code || e,
        message: e.message || "ActivityLog-Fail"
      })
    );
  }

  return 1;
}

export const getactivityLog = (payload) => ({
  type: ACTIVITY_LOG,
  payload
});

export const resetactivityLog = (payload) => ({
  type: ACTIVITY_LOG_RESET,
  payload
});

export const getactivityLogMore = (payload) => ({
  type: ACTIVITY_LOG_MORE,
  payload: { ...payload, moreLoading: true }
});

export function* activityLogGETAction() {
  yield takeLatest([ACTIVITY_LOG, ACTIVITY_LOG_MORE], activityLogGet);
}

const updateData = (data) => {
  const newData = _.chain(data)
    .groupBy((ob) => convertToFormat(ob.createdAt, "DD MMM YYYY"))
    .map((value, key) => ({ createdAt: key, data: value }))
    .value();
  return newData;
};

export const activityLogReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case ACTIVITY_LOG: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case ACTIVITY_LOG_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          pagedResults: action.payload.pagedResults,
          modifieddata: updateData(action.payload.pagedResults),
          ...action.payload
        },
        pageNumber: state.pageNumber + 1
      };
    }
    case ACTIVITY_LOG_MORE_SUCCESS: {
      const newResult = [
        ...state.data.pagedResults,
        ...action.payload.pagedResults
      ];
      const modifieddata = updateData(newResult);

      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          pagedResults: newResult,
          modifieddata
        },
        pageNumber: state.pageNumber + 1
      };
    }

    case ACTIVITY_LOG_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    case SET_CREATED_USER_IDS:
      return {
        ...state,
        createdUserIds: { ...state.createdUserIds, ...action.createdUserIds }
      };
    case ACTIVITY_LOG_RESET: {
      return {
        ...state,
        loading: false,
        data: [],
        error: apiErrorObj,
        pageNumber: 1
      };
    }
    default:
      return state;
  }
};

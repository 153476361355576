import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import {
  BackDrop,
  ErrorModal,
  Loader,
  PageRefreshOverlay,
  translate,
  Wrapper
} from "preventx-storybook";
import React, { useEffect, useState } from "react";
import { Offline, Online } from "react-detect-offline";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { getNavBarCasesCount } from "../../store/navbar/actionCreator";
import { RouteWithTitle } from "../atoms/RouteWithTitle";
import { useWindow } from "../hooks";
import { ErrorStatus, LoginRedirect, StatusNotification } from "../molecules";
import { NavMenus } from "../molecules/NavBar/Navigation";
import { getNotifications } from "../../store/userNotifications/actionCreator";
import { getServiceProvidersReferenceList } from "../../utils/settings";
import { getServiceProviderSettingRequest } from "../../store/serviceprovidersettings/actionCreator"

export const AppLayout = () => {
  const isAuthenticated = useIsAuthenticated();
  const [containerHeight, setContainerHeight] = useState(
    `${window.innerHeight - 56}px`
  );
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.navbarReducer);
  const { isRefresh } = useSelector((state) => state.forceRefreshReducer);
  const { data } = useSelector((state) => state.serviceProviderSettingsReducer);
  

  useEffect(() => {
    if (isAuthenticated) {
      const serviceProvidersList = getServiceProvidersReferenceList() 
      dispatch(getServiceProviderSettingRequest(serviceProvidersList));
      
      if (process.env.REACT_APP_NOTIFICATION_ENABLE === "true") {
        dispatch(getNotifications(false));
      }
      
      dispatch(getNavBarCasesCount(data));
    }
  }, [isAuthenticated,data]);

  const calculateHeight = () => {
    setContainerHeight(`${window.innerHeight - 56}px`);
  };

  useEffect(() => calculateHeight(), [window.innerWidth]);

  useWindow(calculateHeight, []);
  const { t } = translate();
  return (
    <Wrapper position="relative">
      <BrowserRouter>
        <StatusNotification />
        <ErrorStatus />
        <AuthenticatedTemplate>
          {isLoading ? (
            <Wrapper flex align="center" justify="center" fw>
              <Loader size={48} height="100vh" loading={isLoading} />
            </Wrapper>
          ) : (
            <>
              <PageRefreshOverlay
                message={t("force-refresh-message")}
                isShow={isRefresh}
                ctaText={t("refresh-cta")}
              />
              <NavMenus />
              <Wrapper
                className="cup-online"
                role="main"
                style={{ height: containerHeight }}>
                <Online polling={false}>
                  <Switch>
                    {ROUTES.map((r) => (
                      <RouteWithTitle
                        key={r.path}
                        path={r.path}
                        exact={r.isExact}
                        component={r.component}
                        title={r.title}
                      />
                    ))}
                  </Switch>
                </Online>
              </Wrapper>

              <Offline polling={false}>
                <Wrapper className="cup-offline">
                  <BackDrop toggleBackDrop />
                  <ErrorModal
                    title="Network-Title"
                    subTitle="Network-Subtitle"
                  />
                </Wrapper>
              </Offline>
            </>
          )}
        </AuthenticatedTemplate>
        {!isAuthenticated && (
          <>
            <RouteWithTitle
              path="/"
              exact
              component={LoginRedirect}
              title="Login"
            />
            {/* <RouteWithTitle
              path="*"
              exact
              component={LoginRedirect}
              title="Login"
            /> */}
          </>
        )}
      </BrowserRouter>
    </Wrapper>
  );
};

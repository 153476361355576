import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const SERCVICE_USER = "SERCVICE_USER";
const SERCVICE_USER_SUCCESS = "SERCVICE_USER_SUCCESS";
const SERCVICE_USER_ERROR = "SERCVICE_USER_ERROR";
const SERCVICE_USER_REFERENCE_UPDATE = "SERCVICE_USER_REFERENCE_UPDATE";
const CLEAR_SERCVICE_USER_DETAILS = "CLEAR_SERCVICE_USER_DETAILS";
const SERCVICE_USER_DETAILS_RELOAD = "SERCVICE_USER_DETAILS_RELOAD";

const initialState = {
  loading: true,
  error: apiErrorObj,
  reloading: false,
  data: {}
};

function* serviceUserReferenceGet(action) {
  try {
    let response = yield apiCall({
      method: "get",
      apiType: "serviceusers",
      url: `serviceusers/${action.payload.serviceUserReference}?profile=${action.payload.profile}`
    });

    const primaryAddress = [];
    if (Array.isArray(response?.PrimaryAddress) &&  response?.PrimaryAddress?.length) {
      response?.PrimaryAddress.forEach((item) => {
        primaryAddress.push({
          isError: false,
          value: item
        });
      });
    }
    response = {
      ...response,
      address1: primaryAddress
    };
    yield put({
      type: SERCVICE_USER_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: SERCVICE_USER_ERROR,
        code: e.code || e,
        message: e.message || "ServiceUserProfile-Fail"
      })
    );
  }
}

export const getserviceUserReference = (payload) => ({
  type: SERCVICE_USER,
  payload
});

export const getuserReferenceReload = (payload) => ({
  type: SERCVICE_USER_DETAILS_RELOAD,
  payload
});

export const updateserviceUserReference = (payload) => ({
  type: SERCVICE_USER_REFERENCE_UPDATE,
  payload
});

export const clearServiceUserDetails = () => ({
  type: CLEAR_SERCVICE_USER_DETAILS
});

export function* serviceUserReferenceGETAction() {
  yield takeLatest(
    [SERCVICE_USER, SERCVICE_USER_DETAILS_RELOAD],
    serviceUserReferenceGet
  );
}

export const serviceUserReferenceReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case SERCVICE_USER_DETAILS_RELOAD: {
      return {
        ...state,
        reloading: true,
        error: apiErrorObj
      };
    }
    case SERCVICE_USER: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SERCVICE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        reloading: false,
        data: action.payload
      };
    }
    case CLEAR_SERCVICE_USER_DETAILS: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj,
        data: {}
      };
    }
    case SERCVICE_USER_REFERENCE_UPDATE: {
      let response = { ...action.payload.body };
      const primaryAddress = [];
      if (response?.PrimaryAddress?.length) {
        response?.PrimaryAddress.forEach((item) => {
          primaryAddress.push({
            isError: false,
            value: item
          });
        });
      }
      response = {
        ...response,
        address1: primaryAddress
      };
      return {
        ...state,
        data: {
          ...state.data,
          ...response
        }
      };
    }
    case SERCVICE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: { isError: true, code: action.code, message: action.message }
      };
    }
    default:
      return state;
  }
};

/* eslint-disable react/prop-types */
import dayjs from "dayjs";
import {
  DateField,
  DynamicTextField,
  GridContainer,
  GridItem,
  PhoneNumber,
  Select,
  TextField,
  translate,
  Wrapper
} from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { DATE_FORMAT } from "../../../constants/defaults";
import { datePickerFormat } from "../../../utils/utilsFunction";

export const FormBuilder = ({
  formjson,
  inputModel,
  errors,
  control,
  onChangeHandle,
  onBlurHandle,
  isDisabled
}) => {
  const { t } = translate();
  const userDetailHandler = (key, value, onChange) => {
    onChange(value);
    onChangeHandle({
      ...inputModel,
      ...{
        [key]: value
      }
    });
  };

  return (
    <>
      <Wrapper className="modal_form_container">
        <GridContainer>
          {formjson.map((formItem, index) => (
            <GridItem
              xs={12}
              md={12}
              lg={formItem?.isFullRow ? 12 : 6}
              xlg={formItem?.isFullRow ? 12 : 6}
              key={`personalform-${index + 1}`}>
              <Controller
                key={`personalform-${index + 1}`}
                render={({ field: { onChange, ref } }) => {
                  if (formItem.type === "phone") {
                    return (
                      <PhoneNumber
                        disableCode
                        defaultCode={t(
                          process.env.REACT_APP_DEFAULT_COUNTRYCODE
                        )}
                        options={formItem.options}
                        contact={inputModel[formItem.name]}
                        label={formItem.label}
                        placeHolder={t(formItem.placeholder)}
                        handler={(value) =>
                          userDetailHandler(formItem.name, value, onChange)
                        }
                        isOptional={formItem?.isOptional}
                        error={errors[formItem.name]}
                        helperText={errors[formItem.name]?.message}
                        disabled={isDisabled || formItem?.disabled}
                        handlerBlur={(value) =>
                          onBlurHandle(formItem.name, value, onChange)
                        }
                      />
                    );
                  }
                  if (formItem.type === "select") {
                    return (
                      <Select
                        multi={formItem?.isMulti || false}
                        label={formItem.label}
                        options={formItem.options}
                        selected={inputModel[formItem.name] || []}
                        placeHolder={t(formItem.placeholder)}
                        handler={(value) =>
                          userDetailHandler(formItem.name, value, onChange)
                        }
                        isOptional={formItem?.isOptional}
                        error={errors[formItem.name]}
                        helperText={errors[formItem.name]?.message}
                        disabled={formItem.disabled}
                        multiSelectVariant={formItem?.variant || "completed"}
                      />
                    );
                  }
                  if (formItem.type === "date") {
                    return (
                      <DateField
                        label={formItem.label}
                        placeholder={t(formItem.placeholder)}
                        value={inputModel[formItem.name]}
                        handler={(e) => {
                          userDetailHandler(
                            formItem.name,
                            e.target.value,
                            onChange
                          );
                        }}
                        isOptional={formItem?.isOptional}
                        seperator={formItem?.seperator}
                        isError={errors[formItem.name]}
                        inputRef={ref}
                        isDisabled={isDisabled}
                        errorMessage={errors[formItem.name]?.message}
                      />
                    );
                  }

                  if (formItem.type === "datepicker") {
                    return (
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        fixedHeight
                        minDate={formItem.minDate}
                        popperPlacement="auto"
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [-50, -200]
                            }
                          }
                        ]}
                        showPopperArrow={false}
                        placeholderText={t(formItem.placeholder)}
                        selected={datePickerFormat(
                          inputModel[formItem.name],
                          DATE_FORMAT,
                          "MM/DD/YYYY"
                        )}
                        onChange={(date) => {
                          userDetailHandler(
                            formItem.name,
                            dayjs(date).format(DATE_FORMAT),
                            onChange
                          );
                        }}
                        customInput={
                          <CustomField
                            label={formItem.label}
                            isOptional={formItem?.isOptional}
                            seperator={formItem?.seperator}
                            isError={errors[formItem.name]}
                            inputRef={ref}
                            isDisabled={isDisabled}
                            errorMessage={errors[formItem.name]?.message}
                          />
                        }
                      />
                    );
                  }
                  if (formItem.type === "dynamicField") {
                    return (
                      <DynamicTextField
                        fullWidth
                        label={formItem.label}
                        placeholder={t(formItem.placeholder)}
                        fields={inputModel[formItem?.name] || []}
                        minFields={formItem.minFields}
                        maxFields={formItem.maxFields}
                        errorMessage={formItem.errorMessage}
                        requiredFields={formItem.requiredFields}
                        handler={(value) => {
                          userDetailHandler(formItem.name, value, onChange);
                        }}
                      />
                    );
                  }
                  return (
                    <TextField
                      label={formItem.label}
                      placeholder={t(formItem.placeholder)}
                      value={inputModel[formItem.name]}
                      handler={(e) => {
                        userDetailHandler(
                          formItem.name,
                          e.target.value,
                          onChange
                        );
                      }}
                      isOptional={formItem?.isOptional}
                      isError={errors[formItem.name]}
                      handlerBlur={(e) => {
                        onBlurHandle(e.target.value);
                      }}
                      isDisabled={isDisabled || formItem?.disabled}
                      errorMessage={errors[formItem.name]?.message}
                    />
                  );
                }}
                name={formItem.name}
                control={control}
                rules={formItem.rules}
                defaultValue={inputModel[formItem.name]}
              />
            </GridItem>
          ))}
        </GridContainer>
      </Wrapper>
    </>
  );
};

FormBuilder.defaultProps = {
  inputModel: {},
  onChangeHandle: () => null,
  onBlurHandle: () => null,
  isDisabled: false
};

const CustomField = ({
  placeholder,
  value,
  onClick,
  label,
  isError,
  isDisabled,
  errorMessage
}) => (
  <TextField
    label={label}
    placeholder={placeholder}
    value={value}
    onClick={onClick}
    isError={isError}
    isDisabled={isDisabled}
    errorMessage={errorMessage}
  />
);

FormBuilder.propTypes = {
  formjson: PropTypes.instanceOf(Object).isRequired,
  inputModel: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object).isRequired,
  control: PropTypes.instanceOf(Object).isRequired,
  onChangeHandle: PropTypes.func,
  onBlurHandle: PropTypes.func,
  isDisabled: PropTypes.bool
};

import React from "react";

import {
  UserIcon,
  NotesIcon,
  MessageIcon,
  ActivityIcon,
  CaseHistoryIcon,
  TriagerouteIcon
} from "preventx-storybook";

// Components
import {
  Notes,
  Profile,
  Triages,
  Messages,
  ActivityLog,
  CaseHistory
} from "../components/organisms/CaseView";

export const sideBarMenus = [
  {
    icon: <UserIcon />,
    menuName: "user-details",
    component: <Profile />
  },
  {
    icon: <TriagerouteIcon />,
    menuName: "triage",
    component: <Triages />
  },

  {
    icon: <NotesIcon />,
    menuName: "case-notes",
    component: <Notes />
  },
  {
    icon: <NotesIcon />,
    menuName: "user-notes",
    component: <Notes noteType="serviceUser" />
  },
  {
    icon: <CaseHistoryIcon />,
    menuName: "Cases",
    component: <CaseHistory />
  },
  {
    icon: <MessageIcon />,
    menuName: "MessagesSubscription",
    component: <Messages />
  },
  {
    icon: <ActivityIcon />,
    menuName: "activity-log",
    component: <ActivityLog />
  }
];

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducers from "./rootReducers";
import rootSaga from "./rootsaga";
import notificationMiddleware from "../middlewares/notificationMiddleware";
import Interceptors from "../interceptors/axios-interceptors";

const reducers = combineReducers(rootReducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware, notificationMiddleware))
);

Interceptors.axiosInterceptors(store);

sagaMiddleware.run(rootSaga);

export default store;

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const SEARCH_CASE = "SEARCH_CASE";
const SEARCH_CASE_SUCCESS = "SEARCH_CASE_SUCCESS";
const SEARCH_CASE_ERROR = "SEARCH_CASE_ERROR";
const SEARCH_CASE_RESET = "SEARCH_CASE_RESET";
const SEARCH_CASE_RESET_ERROR_FLAG = "SEARCH_CASE_RESET_ERROR_FLAG";

const initialState = {
  loading: false,
  error: apiErrorObj,
  data: {},
  flag: false
};

function* searchCaseGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `cases/searchcount?CaseReference=${action.payload.CaseReference}`
    });

    if (response?.totalCases > 0) {
      yield put({
        type: SEARCH_CASE_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: SEARCH_CASE_ERROR,
        payload: {
          ...action.payload
        }
      });
    }
  } catch (e) {
    yield put(
      apiException({
        type: SEARCH_CASE_ERROR,
        code: e.code || e,
        payload: {
          ...action.payload
        },
        message: e.message || "CaseSearch-Fail"
      })
    );
  }
}

export const getsearchCase = (payload) => ({
  type: SEARCH_CASE,
  payload
});

export const resetsearchCase = () => ({
  type: SEARCH_CASE_RESET
});

export const resetsearchCaseErrorFlag = () => ({
  type: SEARCH_CASE_RESET_ERROR_FLAG
});

export function* searchCaseGETAction() {
  yield takeLatest([SEARCH_CASE], searchCaseGet);
}

export const searchCaseReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SEARCH_CASE: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SEARCH_CASE_SUCCESS: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload,
        error: false
      };
    }
    case SEARCH_CASE_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case SEARCH_CASE_RESET: {
      return {
        ...state,
        data: "",
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    case SEARCH_CASE_RESET_ERROR_FLAG: {
      return {
        ...state,
        error: false
      };
    }
    default:
      return state;
  }
};

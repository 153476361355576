import {
  GET_SERVICE_PROVIDER_SETTING_REQUEST,
  GET_SERVICE_PROVIDER_SETTING_SUCCESS,
  GET_SERVICE_PROVIDER_SETTING_ERROR,
  GET_FILTER_COUNT_SETTING_SUCCESS
} from "./actionType";
import { apiErrorObj } from "../../constants/defaults";

const initialState = {
  data: [],
  loading: false,
  error: apiErrorObj,
  filterCounts: {}
};

export const serviceProviderSettingsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SERVICE_PROVIDER_SETTING_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_SERVICE_PROVIDER_SETTING_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload]
      };
    }
    case GET_SERVICE_PROVIDER_SETTING_ERROR: {
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }

    case GET_FILTER_COUNT_SETTING_SUCCESS: {
      return {
        ...state,
        loading: false,
        filterCounts: action.payload
      };
    }

    default:
      return state;
  }
};

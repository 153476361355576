import { apiErrorObj } from "../../constants/defaults";
import * as actionType from "./actionTypes";

const initialState = {
  triageData: {},
  loading: true,
  triageHistoryLoading: true,
  triageHistoryError: apiErrorObj,
  allTriagesError: apiErrorObj,
  relatedTriagesError: apiErrorObj,

  triageHistory: [],
  allTraiges: [],
  relatedTriages: []
};

export const triageReportReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionType.SET_TRIAGEREPORT_SUCCESS:
      return {
        ...state,
        triageData: action.data || "",
        loading: false
      };

    case actionType.GET_TRIAGE_HISTORY:
      return {
        ...state,
        triageHistoryLoading: true,
        triageHistoryError: apiErrorObj
      };

    case actionType.SET_TRIAGE_HISTORY:
      return {
        ...state,
        triageHistoryLoading: false,
        [action.triageType === "all" ? "allTraiges" : "relatedTriages"]:
          action.data
      };

    case actionType.SET_TRIAGE_HISTORY_ERROR:
      return {
        ...state,
        triageHistoryLoading: false,
        [action.triageType]: {
          ...state[action.triageType],
          isError: true,
          message: action.message,
          code: action.code
        }
      };

    case actionType.CLEAR_TRIAGE_HISTORY_ERROR:
      return {
        ...state,
        loading : true,
        triageHistoryLoading: false,
        [action.triageType]: apiErrorObj,
        triageHistoryError : apiErrorObj
      };

    case actionType.RESET_TRIAGE_HISTORY:
      return {
        ...state,
        triageHistoryLoading: false,
        relatedTriagesError: apiErrorObj,
        allTriagesError: apiErrorObj,
        allTraiges: [],
        relatedTriages: []
      };

    case actionType.SET_TRIAGEREPORT_ERROR:
      return {
        ...state,
        triageHistoryLoading: false,
        loading: false,
        triageHistoryError: {
          ...state.triageHistoryError,
          isError: true,
          message: action.message,
          code: action.code
        }
      };

    default:
      return state;
  }
};

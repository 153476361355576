import { Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import {
  deleteNotification,
  markAsReadNotification
} from "../../../../store/userNotifications/actionCreator";
import { NotificationDelete } from "../NotificationComponents/NotificationDelete";
import { NotificationReadStatus } from "../NotificationComponents/NotificationReadStatus";
import { NotificationViewCase } from "../NotificationComponents/NotificationViewCase";
import { NotifyMessageHeader } from "../NotificationComponents/NotifiyMessageHeader";

export const NotificationFeature = ({
  title,
  createdOn,
  subscriptionCode,
  id,
  markAsRead
}) => {
  const dispatch = useDispatch();
  const markReadHandler = () => {
    dispatch(markAsReadNotification(id));
  };
  const deleteHandler = () => {
    dispatch(deleteNotification(id));
  };

  return (
    <Wrapper className="notification-feature">
      <NotifyMessageHeader
        message={title}
        time={createdOn}
        icon={subscriptionCode}
      />
      <Wrapper className="notification-feature__viewCase">
        <Wrapper className="notification-feature__viewCase__status">
          {markAsRead && (
            <>
              <NotificationReadStatus
                title="Mark as read"
                markReadHandler={markReadHandler}
              />
            </>
          )}
          <NotificationViewCase title="view" />
        </Wrapper>
        <Wrapper className="notification-feature__delete">
          <NotificationDelete deleteHandler={deleteHandler} />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

NotificationFeature.defaultProps = {
  title: "",
  createdOn: "",
  subscriptionCode: "",
  id: "",
  markAsRead: false
};

NotificationFeature.propTypes = {
  title: PropTypes.string,
  createdOn: PropTypes.string,
  subscriptionCode: PropTypes.string,
  id: PropTypes.string,
  markAsRead: PropTypes.bool
};

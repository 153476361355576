import { Typography, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";

export const NotificationReadStatus = ({ title, markReadHandler }) => (
  <Wrapper className="notification-read-status" handler={markReadHandler}>
    <Typography color="preventx-blue" size={14}>
      {title}
    </Typography>
  </Wrapper>
);

NotificationReadStatus.propTypes = {
  title: PropTypes.string.isRequired,
  markReadHandler: PropTypes.func.isRequired
};

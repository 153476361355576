/* eslint-disable no-unused-vars */
import _ from "lodash";
import {
  Alert,
  Button,
  Divider,
  Loader,
  NoteFilters,
  Spinner,
  TabButtons,
  Typography,
  Wrapper
} from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { RightMenuPanel } from "../../..";
import { getNotesList, setNoteFlag } from "../../../../../store/actionTypes";
import {
  calcNotesListHeight,
  notesFilterOptions
} from "../../../../../utils/utilsFunction";
import { useWindow } from "../../../../hooks/useWindow";
import { NotesCard } from "./NotesCard";

export const NotesList = ({
  newNoteHandler,
  editNoteHandler,
  addDetailsHandler,
  noteName,
  noteNameHandler,
  reference,
  tabIndex
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showFilter, toggleFilter] = useState(false);
  const [notesListHeight, setNotesListHeight] = useState();
  const dispatch = useDispatch();
  const selectedTab = (index) => setActiveTab(index);

  const { response, filtersSelected, flagSpinner, form } = useSelector(
    (state) => state.notesReducer
  );

  const { notes, noteCategoryOptions, noteTypeOptions, error } = response;
  const { loading, notesList, recentNoteId, flaggedUserNotes } = notes;
  const { totalFlagged, totalCount, pagedResults } = notesList;

  const tabNames = [
    { name: "All", count: totalCount },
    { name: "Flagged", count: totalFlagged }
  ];

  const flagNoteHandler = (noteReference, isFlagged) => {
    dispatch(setNoteFlag(noteName, noteReference, isFlagged, reference));
  };

  const filterHandler = (filters) => {
    dispatch(getNotesList(noteName, reference, 0, 1, filters));
    toggleFilter(!showFilter);
  };
  const fetchMoreNotes = () => {
    dispatch(getNotesList(noteName, reference, 1));
  };

  const calculateHeight = () => {
    setNotesListHeight(calcNotesListHeight());
  };

  useEffect(() => calculateHeight(), [window.innerWidth]);

  useWindow(calculateHeight, []);

  const gotoFlaggedUserNotes = () => {
    noteNameHandler("serviceUser");
  };

  useEffect(() => {
    setActiveTab(tabIndex);
    toggleFilter(false);
  }, [tabIndex, noteName]);

  return (
    <RightMenuPanel
      title={noteName === "case" ? `case-notes` : `user-notes`}
      noAction={noteName !== "case"}
      action={showFilter ? "close" : "filter"}
      actionLabel={showFilter ? "Close-Filter" : ""}
      handler={() => toggleFilter(!showFilter)}>
      {showFilter ? (
        <NoteFilters
          categories={notesFilterOptions(noteCategoryOptions.updatedJson)}
          selected={filtersSelected}
          applyFilterHandler={filterHandler}
        />
      ) : (
        <>
          <Wrapper flex mb={16} style={{ flexDirection: "column" }}>
            <TabButtons
              tabNames={tabNames}
              activeTab={activeTab}
              handler={selectedTab}
            />

            <Button handler={newNoteHandler} mt={16}>
              create-new-note
            </Button>
          </Wrapper>

          <Divider absolute />
          <Wrapper mt={32} tabIndex={0}>
            <Loader
              loading={loading}
              error={
                error?.notesList?.isError ||
                error?.noteCategories?.isError ||
                error?.noteTypes?.isError
              }
              errorCode={
                error?.notesList?.code ||
                error?.noteCategories?.code ||
                error?.noteTypes?.code
              }
              errorMessage={
                error?.notesList?.message ||
                error?.noteCategories?.message ||
                error?.noteTypes?.message
              }
              height="20vh"
              noData={
                (!pagedResults || pagedResults?.length < 1) &&
                flaggedUserNotes?.totalFlagged < 1
              }
              noDataMessage={
                (!pagedResults || pagedResults?.length < 1) &&
                "Notes-List-Empty"
              }>
              <InfiniteScroll
                className="tablelist-scroll smothscroll scrollbar"
                dataLength={pagedResults?.length || 0}
                next={fetchMoreNotes}
                hasMore={pagedResults?.length < totalCount}
                scrollThreshold={0.9}
                height={notesListHeight}
                loader={<Spinner size={48} />}
                width="100%">
                {flaggedUserNotes?.totalFlagged > 0 && noteName === "case" && (
                  <Wrapper mb={12} b={4} borderColor="transparent">
                    <Alert
                      fullWidth
                      closable={false}
                      title="Flagged user notes">
                      <Wrapper
                        flex
                        align="center"
                        style={{ flexWrap: "wrap" }}
                        mt={5}>
                        <Button
                          dense
                          handler={gotoFlaggedUserNotes}
                          variant="tertiary"
                          mr={5}
                          style={{ flexShrink: 0 }}>
                          click-here
                        </Button>
                        <Typography display="inline" leading="normal">
                          view-flagged-user-notes
                        </Typography>
                      </Wrapper>
                    </Alert>
                  </Wrapper>
                )}
                {pagedResults?.length < 1 && (
                  <div
                    className="sb-nodata-block"
                    style={{
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      height: "20vh"
                    }}>
                    <Typography>Notes-List-Empty</Typography>
                  </div>
                )}
                {_.map(pagedResults, (item, index) =>
                  activeTab === 1 ? (
                    item.isFlagged && (
                      <NotesCard
                        id={item.caseReference}
                        key={`note-flagged-${index + 1}`}
                        isThisCase={item.caseReference === reference}
                        caseId={item.caseReference}
                        isEditable={item.isEditable}
                        caseIdHandler={() => []}
                        note={item}
                        noteCategories={noteCategoryOptions}
                        editNoteHandler={() => editNoteHandler(item)}
                        addDetailsHandler={() => addDetailsHandler(item)}
                        flagNoteHandler={() =>
                          flagNoteHandler(
                            item.caseNoteReference || item.serviceUserNoteId,
                            item.isFlagged
                          )
                        }
                        isLoading={
                          flagSpinner.noteId ===
                          (item.caseNoteReference || item.serviceUserNoteId)
                        }
                        noteName={noteName}
                        noteTypes={noteTypeOptions}
                        recentNoteId={recentNoteId}
                      />
                    )
                  ) : (
                    <NotesCard
                      key={`note-unflagged-${index + 1}`}
                      isThisCase={item.caseReference === reference}
                      caseId={item.caseReference}
                      caseIdHandler={() => []}
                      note={item}
                      noteCategories={noteCategoryOptions}
                      noteTypes={noteTypeOptions}
                      editNoteHandler={() => editNoteHandler(item)}
                      addDetailsHandler={() => addDetailsHandler(item)}
                      flagNoteHandler={() =>
                        flagNoteHandler(
                          item.caseNoteReference || item.serviceUserNoteId,
                          item.isFlagged
                        )
                      }
                      isLoading={
                        flagSpinner.noteId ===
                        (item.caseNoteReference || item.serviceUserNoteId)
                      }
                      noteName={noteName}
                      recentNoteId={recentNoteId}
                    />
                  )
                )}
              </InfiniteScroll>
            </Loader>
          </Wrapper>
        </>
      )}
    </RightMenuPanel>
  );
};

NotesList.propTypes = {
  newNoteHandler: PropTypes.func.isRequired,
  editNoteHandler: PropTypes.func.isRequired,
  addDetailsHandler: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  noteName: PropTypes.string.isRequired,
  noteNameHandler: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired
};

import produce from "immer";
import { apiErrorObj } from "../../constants/defaults";
import * as actionType from "./actionTypes";

const initialState = {
  subscriptions: [],
  error: apiErrorObj,
  openSettings: false
};

export const userSubscriptionsReducer = (state = initialState, action = null) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionType.SET_SUBSCRIPTIONS:
        draft.subscriptions = action.data;
        break;

      case actionType.SUBSCRIPTION_ERROR:
        draft.subscriptions = [];
        draft.error = {
          isError: true,
          message: action.message,
          code: action.code
        };
        break;

      case actionType.CLEAR_SUBSCRIPTION_ERROR:
        draft.subscriptions.error = apiErrorObj;
        break;
      default:
        break;
    }
  });

export const toggleSettingsReducer = (state = initialState, action = null) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionType.TOGGLE_SETTINGS:
        draft.openSettings = action.bool;
        break;
      default:
        break;
    }
  });

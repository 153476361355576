import {
  CLEAR_TRIAGE_HISTORY_ERROR,
  GET_TRIAGEREPORT,
  GET_TRIAGE_HISTORY,
  RESET_TRIAGE_HISTORY,
  SET_TRIAGEREPORT_SUCCESS,
  SET_TRIAGE_HISTORY,
  SET_TRIAGE_HISTORY_ERROR
} from "./actionTypes";

export const getTriageReport = (payload) => ({
  type: GET_TRIAGEREPORT,
  payload
});

export const setTriageReport = (data) => ({
  type: SET_TRIAGEREPORT_SUCCESS,
  data
});

export const getTriageHistory = (
  serviceuserReferenceId,
  screenReference,
  triageType = "all"
) => ({
  type: GET_TRIAGE_HISTORY,
  serviceuserReferenceId,
  screenReference,
  triageType
});

export const setTriageHistory = (triageType = "all", data) => ({
  type: SET_TRIAGE_HISTORY,
  data,
  triageType
});

export const resetTriageHistory = () => ({ type: RESET_TRIAGE_HISTORY });

export const setTriageHistoryError = (triageType) => ({
  type: SET_TRIAGE_HISTORY_ERROR,
  triageType
});

export const clearTriageHistoryError = () => ({
  type: CLEAR_TRIAGE_HISTORY_ERROR
});

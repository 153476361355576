import _ from "lodash";
import { stringify } from "query-string";
import { all, put, takeLatest } from "redux-saga/effects";
import { apiErrorObj, DEFAULT_PAGE_SIZE } from "../../constants/defaults";
import { apiCall, apiException, paging } from "../../services/api";
import {
  getState,
  injectScreenResultsSummary,
  injectUserDetails,
  pageStateDetails,
  updateFiltersParams
} from "../../utils/helper";
import { getUpdatedPagedResults } from "../../utils/settings";

const TODAY_LIST = "TODAY_LIST";
const TODAY_LIST_MORE = "TODAY_LIST_MORE";
const TODAY_LIST_SUCCESS = "TODAY_LIST_SUCCESS";
const TODAY_LIST_MORE_SUCCESS = "TODAY_LIST_MORE_SUCCESS";
export const TODAY_LIST_ERROR = "TODAY_LIST_ERROR";
const TODAY_LIST_FILTER = "TODAY_LIST_FILTER";
const SCROLL_POSITION = "SCROLL_POSITION";
const SCROLL_POSITION_UPDATE = "SCROLL_POSITION_UPDATE";
const TODAY_LIST_UPDATE = "TODAY_LIST_UPDATE";
const RESET_TODAY_LIST = "RESET_TODAY_LIST";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: [],
  screenResultsSummary: {},
  overDuePage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  scrollposition: 0
};
const setTodayList = (moreLoading, response, pageNumber) => ({
  type: moreLoading ? TODAY_LIST_MORE_SUCCESS : TODAY_LIST_SUCCESS,
  response,
  overDuePage: pageNumber
});

export function* filterAndFetchResultSummary(screenReferences) {
  if (screenReferences.length > 0) {
    let isError = false;
    try {
      const response = yield all([
        ..._.map(screenReferences, (sr) =>
          apiCall({
            apiType: "Laboratory",
            url: `screens/${sr}/results`
          })
            .then((res) => ({ [sr]: res?.screen }))
            .catch((e) => {
              isError = e;
              return { [sr]: [] };
            })
        )
      ]);
      if (isError) {
        throw isError;
      }
      return response;
    } catch (e) {
      const err = { code: e, message: "Caselist-ResultSummary-Fail" };
      throw err;
    }
  }
  return null;
}

function* todayListGet(action) {
  const { filterType, resetPage, moreLoading } = action.payload;
  const { currentFilters, overDuePage, pageSize } = yield pageStateDetails(
    filterType
  );
  const pageNumber = resetPage ? 1 : overDuePage + 1;

  const serviceProviderList = yield getState(
    "serviceProvidersReducer",
    "serviceProvidersList"
  );
  const updatedParams = updateFiltersParams(
    currentFilters,
    serviceProviderList
  );

  try {
    const response = yield apiCall({
      method: "get",
      url: `Worklists?${paging(pageNumber, pageSize)}&${
        updatedParams?.filters
          ? stringify({
              Filters: updatedParams.filters,
              ServiceProviderReference: updatedParams.serviceProviders
            })
          : ""
      }`
    });
    if (response) {
      const updatedPagedResults = yield getUpdatedPagedResults(response);

      const caseListResponse = {
        ...response,
        pagedResults: updatedPagedResults
      }

      const updatedcaseList = yield injectUserDetails(caseListResponse);
      const resultSummary = yield injectScreenResultsSummary(updatedcaseList);
      yield all([put(setTodayList(moreLoading, resultSummary, pageNumber))]);
    }
  } catch (e) {
    yield put(
      apiException({
        type: TODAY_LIST_ERROR,
        code: e.code || e,
        message: e.message || "Worklist-Fail"
      })
    );
  }
}

function* updateScrollPosition(action) {
  yield put({
    type: SCROLL_POSITION_UPDATE,
    payload: action.payload
  });
}

export const getTodayList = (payload) => ({
  type: TODAY_LIST,
  payload: { ...payload, resetPage: true }
});

export const getTodayListMore = (payload) => ({
  type: TODAY_LIST_MORE,
  payload: { ...payload, moreLoading: true }
});

export const updateTodayListFilter = (params = []) => ({
  type: TODAY_LIST_FILTER,
  payload: { filters: params }
});

export const updateScroll = (payload) => ({
  type: SCROLL_POSITION,
  payload
});

export const updateTodayList = (payload) => ({
  type: TODAY_LIST_UPDATE,
  payload
});
export const resetTodayList = () => ({
  type: RESET_TODAY_LIST
});

export function* todaylistGETAction() {
  yield takeLatest([TODAY_LIST, TODAY_LIST_MORE], todayListGet);

  yield takeLatest([SCROLL_POSITION], updateScrollPosition);
}

export const todayListReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case TODAY_LIST: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case TODAY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.response,
        overDuePage: action.overDuePage
      };
    }
    case TODAY_LIST_MORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        overDuePage: action.overDuePage,
        data: {
          pagedResults: [
            ...state.data.pagedResults,
            ...action.response.pagedResults
          ],
          totalCount: action.response.totalCount,
          totalOverdue: action.response.totalOverdue,
          totalDue: action.response.totalDue
        }
      };
    }
    case TODAY_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        overDuePage: action.pageNumber,

        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }

    case SCROLL_POSITION_UPDATE: {
      return {
        ...state,
        scrollposition: action.payload
      };
    }

    case RESET_TODAY_LIST: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj,
        data: []
      };
    }

    case TODAY_LIST_UPDATE: {
      const tmpstate = { ...state };
      if (tmpstate?.data?.pagedResults) {
        tmpstate.data.pagedResults.forEach((item) => {
          if (
            item?.caseDetail?.serviceUserDetails?.ServiceUserReference ===
            action.payload.ServiceUserReference
          ) {
            item.caseDetail.serviceUserDetails = {
              ...item.caseDetail.serviceUserDetails,
              ...action.payload
            };
          }
        });
        return tmpstate;
      }
      return state;
    }
    default:
      return state;
  }
};

export const ADD_NOTES = "ADD_NOTES";
export const EDIT_NOTES = "EDIT_NOTES";
export const RESET_NOTES = "RESET_NOTES";
export const RESET_NOTES_FORM = "RESET_NOTES_FORM";
export const LOG_CARE_OUTCOME = "LOG_CARE_OUTCOME";
export const VALIDATE_NOTES = "VALIDATE_NOTES";

export const GET_NOTES_LIST = "GET_NOTES_LIST";

export const SET_NOTES_LIST = "SET_NOTES_LIST";
export const GET_NOTES_EDIT_HISTORY = "GET_NOTES_EDIT_HISTORY";
export const SET_NOTES_EDIT_HISTORY = "SET_NOTES_EDIT_HISTORY";

export const SET_NOTES_ERROR = "SET_NOTES_ERROR";
export const CLEAR_NOTES_ERROR = "CLEAR_NOTES_ERROR";

export const UNSHIFT_NOTE = "UNSHIFT_NOTE";
export const GET_NOTE_BY_ID = "GET_NOTE_BY_ID";
export const SET_NOTES_LIST_LOADING = "SET_NOTES_LIST_LOADING";

export const CLEAR_NOTES_LIST = "CLEAR_NOTES_LIST";

export const GET_NOTE_CATEGORIES = "GET_NOTE_CATEGORIES";
export const SET_NOTE_CATEGORIES = "SET_NOTE_CATEGORIES";

export const GET_NOTE_TYPES = "GET_NOTE_TYPES";
export const SET_NOTE_TYPES = "SET_NOTE_TYPES";

export const GET_OUTCOME_TYPES = "GET_OUTCOME_TYPES";
export const SET_OUTCOME_TYPES = "SET_OUTCOME_TYPES";
export const DELETE_OUTCOMES = "DELETE_OUTCOMES";

export const GET_PROCESSES = "GET_PROCESSES";
export const SET_PROCESSES = "SET_PROCESSES";

export const FILTER_OUTCOME_TYPES = "FILTER_OUTCOME_TYPES";
export const SET_FILTER_OUTCOME_TYPES = "SET_FILTER_OUTCOME_TYPES";

export const GET_NOTE_TASKS = "GET_NOTE_TASKS";
export const SET_NOTE_TASKS = "SET_NOTE_TASKS";

export const INSERT_NEW_NOTE = "INSERT_NEW_NOTE";

export const SET_NOTE_FLAG = "SET_NOTE_FLAG";

export const SET_FLAG_SPINNER = "SET_FLAG_SPINNER";
export const SET_NOTE_SAVE_SPINNER = "SET_NOTE_SAVE_SPINNER";

export const SET_NOTE_DELETE_OUTCOMES_SPINNER =
  "SET_NOTE_DELETE_OUTCOMES_SPINNER";

export const UPDATE_FLAGGED_NOTES = "UPDATE_FLAGGED_NOTES";

export const ADD_NOTE_LIST_FILTER = "ADD_NOTE_LIST_FILTER";
export const SET_NOTES_LIST_FILTER = "SET_NOTES_LIST_FILTER";

export const SET_RECENT_NOTE_ID = "SET_RECENT_NOTE_ID";

export const GET_FLAGGED_USER_NOTES = "GET_FLAGGED_USER_NOTES";
export const SET_FLAGGED_USER_NOTES = "SET_FLAGGED_USER_NOTES";

export const TRIGGER_USER_NOTES = "TRIGGER_USER_NOTES";

import _ from "lodash";
import { stringify } from "query-string";
import { put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../services/api";
import { getState } from "../../utils/helper";
import { dropdownConverter } from "../../utils/utilsFunction";

const SERVICE_PROVIDER = "SERVICE_PROVIDER";
const SERVICE_PROVIDER_SUCCESS = "SERVICE_PROVIDER_SUCCESS";
export const SERVICE_PROVIDER_LIST_SUCCESS = "SERVICE_PROVIDER_LIST_SUCCESS";

const SERVICE_PROVIDER_ERROR = "SERVICE_PROVIDER_ERROR";

const initialState = {
  loading: true,
  error: false,
  data: {},
  serviceProvidersList: []
};

export function* serviceProvidersGet({ payload, isDropdown }) {
  try {
    const { serviceProvidersList } = yield getState("serviceProvidersReducer");
    const keysToFetch = _.filter(
      payload,
      (u) => serviceProvidersList.indexOf(u) < 0
    );

    const response = yield apiCall({
      method: "get",
      apiType: "serviceproviders",
      url: `serviceproviders?${stringify({
        serviceProviderReference: keysToFetch
      })}`
    });
    if (isDropdown) {
      response.serviceProviders.unshift({
        serviceProviderReference: -1,
        name: window.translate("All-Service-Providers")
      });
      yield put({
        type: SERVICE_PROVIDER_SUCCESS,
        payload: dropdownConverter(
          response.serviceProviders,
          "name",
          "serviceProviderReference"
        )
      });
    } else {
      return response.serviceProviders;
    }
  } catch (e) {
    const err = { code: e, message: "Filter-ServiceProviders-Fail" };
    throw err;
  }
  return null;
}

export const getServiceProviders = (payload, isDropdown = true) => ({
  type: SERVICE_PROVIDER,
  payload,
  isDropdown
});

export function* serviceProvidersGETAction() {
  yield takeLatest([SERVICE_PROVIDER], serviceProvidersGet);
}

export const serviceProvidersReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case SERVICE_PROVIDER: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case SERVICE_PROVIDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case SERVICE_PROVIDER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceProvidersList: action.payload
      };
    }

    case SERVICE_PROVIDER_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: true
      };
    }
    default:
      return state;
  }
};

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const ACCOUNT_STATUS = "ACCOUNT_STATUS";
const ACCOUNT_STATUS_SUCCESS = "ACCOUNT_STATUS_SUCCESS";
const ACCOUNT_STATUS_ERROR = "ACCOUNT_STATUS_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {}
};

function* accountStatusGet() {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/ClaimValue-ServiceUserStatusDropdown"
    });
    const dropdown = [];
    response.forEach((item) => {
      dropdown.push({
        text: `${item}-Custom`,
        value: item
      });
    });
    yield put({
      type: ACCOUNT_STATUS_SUCCESS,
      payload: dropdown
    });
  } catch (e) {
    yield put(
      apiException({
        type: ACCOUNT_STATUS_ERROR,
        code: e.code || e,
        message: e.message || "AccountStatus-Fail"
      })
    );
  }
}

export const getaccountStatus = (payload) => ({
  type: ACCOUNT_STATUS,
  payload
});

export function* accountStatusGETAction() {
  yield takeLatest([ACCOUNT_STATUS], accountStatusGet);
}

export const accountStatusReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case ACCOUNT_STATUS: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case ACCOUNT_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case ACCOUNT_STATUS_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

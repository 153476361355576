/* eslint-disable no-unused-vars */
import { Toast } from "preventx-storybook";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelStatusNotificationAction } from "../../../store/notification/logic";

export const StatusNotification = () => {
  const { type, status, message, uniqueRenderid } = useSelector(
    (state) => state.statusNotificationReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (type !== "CANCEL_STATUS_NOTIFICATION") {
      // dispatch(cancelStatusNotificationAction());
    }
  }, [type]);

  return (
    message &&
    status &&
    uniqueRenderid && (
      <Toast
        message={message}
        variant={status}
        autoClose={5000}
        hideProgressBar
        uniqueRenderid={uniqueRenderid}
        position="top-center"
      />
    )
  );
};

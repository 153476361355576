import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";
import { taskLogGet } from "../../utils/helper";
import { updateCaseView } from "../caseHeader/logic";

const SAFE_GUARDING_UPDATE = "SAFE_GUARDING_UPDATE";

const SAFE_GUARDING = "SAFE_GUARDING";
const SAFE_GUARDING_SUCCESS = "SAFE_GUARDING_SUCCESS";
const SAFE_GUARDING_ERROR = "SAFE_GUARDING_ERROR";
const SAFE_GUARDING_RESET = "SAFE_GUARDING_RESET";
const SAFE_GUARDING_UPDATE_SUCESS = "SAFE_GUARDING_UPDATE_SUCESS";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: []
};

function* processGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `Cases/${action.payload.caseReference}`
    });
    const tmpData = response?.processes.filter(
      (item) => item.processReference === action.payload.processReference
    );
    if (tmpData.length) {
      yield taskLogGet({
        payload: tmpData[0],
        type : SAFE_GUARDING_UPDATE_SUCESS
      });
    } else {
      yield put({
        type: SAFE_GUARDING_SUCCESS,
        payload: {
          processReference: action.payload.processReference,
          process: response?.processes
        }
      });
    }
    yield put(updateCaseView(response));
  } catch (e) {
    yield put(
      apiException({
        type: SAFE_GUARDING_ERROR,
        code: e.code || e,
        message: e.message || "SafeGuardingProcess-Fail"
      })
    );
  }
}

function* taskGetLog(action){
  yield taskLogGet({
    payload: action.payload,
    type : SAFE_GUARDING_UPDATE_SUCESS
  });
}

export const getSafeguardingProcess = (payload) => ({
  type: SAFE_GUARDING,
  payload
});

export const updateSafeGuarding = (payload) => ({
  type: SAFE_GUARDING_UPDATE,
  payload
});

export const resetGetSafeguarding = (payload) => ({
  type: SAFE_GUARDING_RESET,
  payload
});

export function* processSafeguardingGETAction() {
  yield takeLatest([SAFE_GUARDING], processGet);
  yield takeLatest([SAFE_GUARDING_UPDATE], taskGetLog);
}

export const safeGuardingReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SAFE_GUARDING: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SAFE_GUARDING_SUCCESS: {
      const tmpData = action.payload.process.filter(
        (item) => item.processReference === action.payload.processReference
      );
      return {
        ...state,
        loading: false,
        flag: true,
        data: tmpData && tmpData.length && tmpData[0]
      };
    }
    case SAFE_GUARDING_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case SAFE_GUARDING_RESET: {
      return {
        ...state,
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    case SAFE_GUARDING_UPDATE: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }
    case SAFE_GUARDING_UPDATE_SUCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    default:
      return state;
  }
};

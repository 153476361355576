export const FILTER_LIST = "FILTER_LIST";
export const FILTER_LIST_SUCCESS = "FILTER_LIST_SUCCESS";
export const FILTER_LIST_ERROR = "FILTER_LIST_ERROR";
export const FILTERS_SELECTED = "FILTERS_SELECTED";
export const SET_CURRENT_FILTERS = "SET_CURRENT_FILTERS";

export const RESET_FILTERS = "RESET_FILTERS";

export const TOGGLE_FILTER_COLLAPSE = "TOGGLE_FILTER_COLLAPSE";
export const SET_FILTER_COLLAPSE = "SET_FILTER_COLLAPSE";
export const SET_TOGGLE_SIDEBAR = "SET_TOGGLE_SIDEBAR";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_DEFAULT_COLLAPSED = "SET_DEFAULT_COLLAPSED";

export const CLEAR_FILTERS_FOR_SEARCH_FORM = "CLEAR_FILTERS_FOR_SEARCH_FORM";

export const NO_FILTERS = "NO_FILTERS";
export const SET_FILTER_COUNTS = "SET_FILTER_COUNTS";
export const GET_FILTER_COUNTS = "GET_FILTER_COUNTS";
export const SET_FILTER_COUNTS_ERROR = "SET_FILTER_COUNTS_ERROR";

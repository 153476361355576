import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const GET_LAB_ACCREDITATION = "GET_LAB_ACCREDITATION";
const LAB_ACCREDITATION_SUCCESS = "LAB_ACCREDITATION_SUCCESS";
const LAB_ACCREDITATION_ERROR = "LAB_ACCREDITATION_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {}
};

function* labAccreditationGET(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `screens/${action.payload.screenReference}/laboratoryAccreditation`
    });

    yield put({
      type: LAB_ACCREDITATION_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: LAB_ACCREDITATION_ERROR,
        code: e.code || e,
        message: e.message || "LabAccreditation-Fail"
      })
    );
  }
}

export const getLabAccreditation = (payload) => ({
  type: GET_LAB_ACCREDITATION,
  payload
});

export function* labAccreditationGETAction() {
  yield takeLatest([GET_LAB_ACCREDITATION], labAccreditationGET);
}

export const laboratoryAccreditateReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case GET_LAB_ACCREDITATION: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case LAB_ACCREDITATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...action.payload }
      };
    }

    case LAB_ACCREDITATION_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],

        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../../constants/defaults";
import { apiCall, apiSuccess, apiException } from "../../../services/api";
import { updateCaseList } from "../../caselist/logic";
import { updateTodayList } from "../../todaylist/logic";
import { updateUserList } from "../../userdetails/actionCreators";
import { updateserviceUserReference } from "../logic";

const SERCVICE_USER_UPDATE = "SERCVICE_USER_UPDATE";
const SERCVICE_USER_UPDATE_SUCCESS = "SERCVICE_USER_UPDATE_SUCCESS";
const SERCVICE_USER_UPDATE_ERROR = "SERCVICE_USER_UPDATE_ERROR";
const SERCVICE_USER_UPDATE_RESET = "SERCVICE_USER_UPDATE_RESET";

const initialState = {
  loading: false,
  error: apiErrorObj,
  flag: false,
  data: {}
};

function* serviceUserUpdatePut(action) {
  try {
    const response = yield apiCall({
      method: "put",
      apiType: "serviceusers",
      url: action.payload.url,
      data: action.payload.body
    });

    yield put(updateserviceUserReference(action.payload));
    yield put(updateUserList(action?.payload?.body));
    yield put(updateCaseList(action?.payload?.body));
    yield put(updateTodayList(action?.payload?.body));

    yield put({
      type: SERCVICE_USER_UPDATE_SUCCESS,
      payload: response
    });
    yield put(apiSuccess(action?.payload?.message));
  } catch (e) {
    yield put(
      apiException({
        type: SERCVICE_USER_UPDATE_ERROR,
        code: e.code || e,
        message: e.message || "ServiceUserUpdate-Fail"
      })
    );
  }
}

export const putserviceUserReference = (payload) => ({
  type: SERCVICE_USER_UPDATE,
  payload
});

export const resetserviceUserReference = () => ({
  type: SERCVICE_USER_UPDATE_RESET
});

export function* serviceUserReferencePUTAction() {
  yield takeLatest([SERCVICE_USER_UPDATE], serviceUserUpdatePut);
}

export const serviceUserReferenceUpdateReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case SERCVICE_USER_UPDATE: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj,
        flag: false
      };
    }
    case SERCVICE_USER_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }

    case SERCVICE_USER_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case SERCVICE_USER_UPDATE_RESET: {
      return {
        ...initialState,
        flag: false,
        loading: false,
        error: apiErrorObj
      };
    }
    default:
      return state;
  }
};

import { useMsal } from "@azure/msal-react";
import _ from "lodash";
import { NavBar } from "preventx-storybook";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, logoutRequest } from "../../../AuthConfig";
import { setNavMenus } from "../../../store/navbar/actionCreator";
import { apiException } from "../../../services/api";
import { goToOldApp } from "../../../utils/helper";
import { CLINICAL_USER_URL, MY_PROFILE_URL } from "../../../constants/defaults";
import { getSuppressedMenus } from "../../../utils/settings";

export const NavMenus = () => {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.userNotificationReducer);
  const { data, loading } = useSelector((state) => state.serviceProviderSettingsReducer);
  const { todayListCount, caseListCount, unReadMessagesCount, freetestMeCount, menu } =
    useSelector((state) => state.navbarReducer);
  const { brand, leftMenus, rightMenus, mobileMenus } = menu;
  const { instance } = useMsal();
  const [, , removeCookie] = useCookies(["accessToken"]);
  const [activeMenu, setActiveMenu] = useState("");
  const toggleModal = (menuName = null) => {
    setActiveMenu((active) => (menuName === active ? "" : menuName));
  };

  const updateCaseCounts = () => {
    const leftMenuCpy = _.cloneDeep(leftMenus);
    const updatedMenu = _.map(leftMenuCpy, (m) => {
      if (m.menuName === "Today") {
        m.tagCount = todayListCount || +localStorage.getItem("workListCount");
      }
      if (m.menuName === "Cases") {
        m.tagCount = caseListCount || +localStorage.getItem("caseListCount");
      }
      if (m.menuName === "Messages") {
        m.tagCount =
          unReadMessagesCount || +localStorage.getItem("unReadMessagesCount");
      }
      if (m.menuName === "FreeTestSmartKits") {
        m.tagCount = 
          freetestMeCount || +localStorage.getItem("freetestMeCount");
      }
      
      return m;
    });
    return updatedMenu;
  };

  const updatedRightMenus = () => {
    const rightMenusCpy = _.cloneDeep(rightMenus);
    const count = messages?.unRead?.totalCount || "";
    const updatedRightMenu = _.map(rightMenusCpy, (m, index) => {
      if (m.menuName === "notification") {
        m.icon = React.cloneElement(m.icon, {
          text: count,
          key: `right-menu-${index + 1}`
        });
      }
      m.handler = () => toggleModal(m.menuName);
      return m;
    });
    return updatedRightMenu;
  };

  const updatedLeftMenus = useCallback(() => {
    const mobileMenusCpy = _.cloneDeep(mobileMenus);
    const updatedMobileMenus = _.map(mobileMenusCpy, (m) => {
      if (m.menuName === "clinical-user-list") {
        m.handler = () => goToOldApp(CLINICAL_USER_URL);
      }
      if (m.menuName === "my-profile") {
        m.handler = () => goToOldApp(MY_PROFILE_URL);
      }
      if (m.menuName === "Search") {
        m.handler = () => toggleModal("Search");
      }
      if (m.menuName === "Sign out") {
        m.handler = async () => {
          const accounts = instance.getAllAccounts();
          const currentAccount = instance.getAccountByHomeId(
            accounts && accounts.length && accounts[0].homeAccountId
          );
          removeCookie("accessToken", { path: "/", maxAge: 0 });
          await instance
            .logoutRedirect({
              ...loginRequest,
              account: currentAccount,
              ...logoutRequest
            })
            .catch(() => dispatch(apiException(null, "Logout-Failed")));
        };
      }
      return m;
    });

    return [...updateCaseCounts(), ...updatedMobileMenus];
  }, [messages]);

  useEffect(() => {
    const rightMenu = updatedRightMenus();
    dispatch(setNavMenus({ rightMenu }));
  }, [messages]);

  useEffect(() => {
    const leftMenu = updatedLeftMenus();
    const suppressedMenus = getSuppressedMenus(data);
    const updatedLeftMenuCpy = leftMenu.filter(
      (menuItem) => suppressedMenus.indexOf(menuItem?.menuName) === -1
    );
    dispatch(setNavMenus({ leftMenu: updatedLeftMenuCpy }));
  }, [todayListCount, caseListCount, unReadMessagesCount, freetestMeCount, loading]);

  const getActiveMenu = (menuName) => {
    if (!menuName) return "";
    const { component } = _.find(_.concat(menu.rightMenu, menu.leftMenu), {
      menuName
    });
    return React.cloneElement(component, {
      openHandler: () => toggleModal(menuName),
      isOpen: activeMenu
    });
  };
  return (
    <>
      <NavBar
        brand={brand}
        leftMenus={menu?.leftMenu}
        rightMenus={menu?.rightMenu}
      />

      {getActiveMenu(activeMenu)}
    </>
  );
};

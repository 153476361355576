import React, { useEffect } from "react";
import { Route } from "react-router";
import PropTypes from "prop-types";
import { translate } from "preventx-storybook";

export const RouteWithTitle = ({ title, ...props }) => {
  const { t } = translate();
  useEffect(() => {
    document.title = t(title);
  }, [title]);
  return <Route {...props} />;
};

RouteWithTitle.defaultProps = {
  title: "Cup"
};

RouteWithTitle.propTypes = {
  title: PropTypes.string
};

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { Loader, Page, Wrapper } from "preventx-storybook";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ServiceUserProfile } from "../../components/organisms";
import RightMenus from "../../components/organisms/PageUtils/RightMenuPanel/RightMenus";
import { CloseAccountStatus } from "../../constants/closeaccountstatus";
import { resetMessage } from "../../store/message/logic";
import { getServiceProviders } from "../../store/serviceprovider/logic";
import {
  clearServiceUserDetails,
  getserviceUserReference
} from "../../store/serviceuserreference/logic";
import { AuthenticateTemplate } from "../AuthenticateTemplate";

export const ServiceUserPage = () => {
  const dispatch = useDispatch();
  const { serviceuserReferenceId } = useParams();

  const searchCountState = useSelector((state) => state.searchCountReducer);
  const { data: search } = searchCountState;
  const serviceUserState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );

  useEffect(() => {
    dispatch(resetMessage());
    dispatch(
      getserviceUserReference({
        serviceUserReference: serviceuserReferenceId,
        profile: "UserDetails"
      })
    );
    dispatch(getServiceProviders());
    return () => dispatch(clearServiceUserDetails());
  }, [serviceuserReferenceId]);

  useEffect(
    () => () => {
      dispatch(resetMessage());
    },
    [search]
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Page noScroll>
          <Loader
            loading={serviceUserState.loading}
            error={serviceUserState.error.isError}
            errorCode={serviceUserState?.error?.code}
            errorMessage={{
              404: "service-user-record-not-found"
            }}
            height="80vh">
            <Wrapper flex position="relative" fh>
              <Wrapper className="caseview scrollY scrollbar" tabIndex={0}>
                {Object.keys(serviceUserState?.data).length > 0 && (
                  <ServiceUserProfile />
                )}
              </Wrapper>
              <RightMenus
                defaultMenu="Cases"
                ignoreMenu={["case-notes", "user-details"]}
                isDisabled={
                  CloseAccountStatus.indexOf(
                    serviceUserState?.data?.AccountStatus
                  ) > -1
                }
              />
            </Wrapper>
          </Loader>
        </Page>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

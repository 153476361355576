import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const SAMPLE_RECEIVED = "SAMPLE_RECEIVED";
const SAMPLE_RECEIVED_SUCCESS = "SAMPLE_RECEIVED_SUCCESS";
const SAMPLE_RECEIVED_ERROR = "SAMPLE_RECEIVED_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: []
};

function* sampleReceivedsGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `screens/${action.payload.screenReference}/specimens`
    });

    yield put({
      type: SAMPLE_RECEIVED_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: SAMPLE_RECEIVED_ERROR,
        code: e.code || e,
        message: e.message || "Specimens-Fail"
      })
    );
  }
}

export const getsampleReceiveds = (payload) => ({
  type: SAMPLE_RECEIVED,
  payload
});

export function* sampleReceivedsGETAction() {
  yield takeLatest([SAMPLE_RECEIVED], sampleReceivedsGet);
}

export const sampleReceivedReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SAMPLE_RECEIVED: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SAMPLE_RECEIVED_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case SAMPLE_RECEIVED_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

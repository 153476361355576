import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "preventx-storybook";
import { resetsearchCaseErrorFlag } from "../../../store/searchcase/logic";
import { resetsearchUserErrorFlag } from "../../../store/searchuser/logic";
import {resetsearchScreenReferenceFlag} from '../../../store/searchscreenreference/logic';

const SearchFooter = ({ active, isDisable, onSubmitHandler, onClose }) => {
  const dispatch = useDispatch();
  const searchCountState = useSelector((state) => state.searchCountReducer);
  const searchCaseState = useSelector((state) => state.searchCaseReducer);
  const searchUserState = useSelector((state) => state.searchUserReducer);
  const searchScreenReference = useSelector((state)=> state.searchScreenReferenceReducer);

  const renderButton = () => {
    if (active === 0) {
      return (
        <>
          <Button
            isDisabled={
              searchCountState.loading || !searchCountState?.data?.userCount > 0
            }
            loading={searchCountState.loading}
            variant="primary"
            tag={searchCountState?.data?.userCount || "0"}
            handler={() => onSubmitHandler("user")}
            mr={16}>
            show-users
          </Button>
          <Button
            isDisabled={
              searchCountState.loading || !searchCountState?.data?.caseCount > 0
            }
            loading={searchCountState.loading}
            handler={() => onSubmitHandler("case")}
            tag={searchCountState?.data?.caseCount || "0"}>
            show-cases
          </Button>
        </>
      );
    }
    if (active === 1) {
      return (
        <Button
          isDisabled={isDisable || searchUserState.loading}
          loading={searchUserState.loading}
          handler={() => onSubmitHandler("case")}
          className="mobile-full">
          search-for-user
        </Button>
      );
    }
    if (active === 2) {
      return (
        <Button
          isDisabled={isDisable || searchCaseState.loading}
          loading={searchCaseState.loading}
          handler={() => onSubmitHandler("case")}
          className="mobile-full">
          search-for-case
        </Button>
      );
    }
    if (active === 3) {
      return (
        <Button
          isDisabled={isDisable ||  searchScreenReference.loading}
          loading={searchScreenReference.loading}
          handler={() => onSubmitHandler("case")}
          className="mobile-full">
          search-for-case
        </Button>
      );
    }
    return null;
  };

  return (searchUserState?.error?.isError && !searchUserState?.loading) ||
    (searchCaseState?.error?.isError && !searchCaseState?.loading) ||  (searchScreenReference?.error?.isError && !searchScreenReference?.loading)  ? (
    <>
      <Button variant="secondary" handler={onClose} mr={16}>
        Cancel
      </Button>
      <Button
        handler={() => {
          if (active === 1) {
            dispatch(resetsearchUserErrorFlag());
          }
          if (active === 2) {
            dispatch(resetsearchCaseErrorFlag());
          }
          if(active === 3){
            dispatch(resetsearchScreenReferenceFlag());
          }
        }}>
        edit-search
      </Button>
    </>
  ) : (
    // <Wrapper className="search-footer">
    renderButton()
    // </Wrapper>
  );
};

SearchFooter.defaultProps = {
  onClose: () => null
};

SearchFooter.propTypes = {
  active: PropTypes.number.isRequired,
  isDisable: PropTypes.bool.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default SearchFooter;

import {
  ADD_NOTES,
  ADD_NOTE_LIST_FILTER,
  CLEAR_NOTES_ERROR,
  CLEAR_NOTES_LIST,
  DELETE_OUTCOMES,
  EDIT_NOTES,
  FILTER_OUTCOME_TYPES,
  GET_FLAGGED_USER_NOTES,
  GET_NOTES_EDIT_HISTORY,
  GET_NOTES_LIST,
  GET_NOTE_BY_ID,
  GET_NOTE_CATEGORIES,
  GET_NOTE_TASKS,
  GET_NOTE_TYPES,
  GET_OUTCOME_TYPES,
  GET_PROCESSES,
  INSERT_NEW_NOTE,
  LOG_CARE_OUTCOME,
  RESET_NOTES,
  RESET_NOTES_FORM,
  SET_FILTER_OUTCOME_TYPES,
  SET_FLAGGED_USER_NOTES,
  SET_FLAG_SPINNER,
  SET_NOTES_EDIT_HISTORY,
  SET_NOTES_ERROR,
  SET_NOTES_LIST,
  SET_NOTES_LIST_FILTER,
  SET_NOTES_LIST_LOADING,
  SET_NOTE_CATEGORIES,
  SET_NOTE_DELETE_OUTCOMES_SPINNER,
  SET_NOTE_FLAG,
  SET_NOTE_SAVE_SPINNER,
  SET_NOTE_TASKS,
  SET_NOTE_TYPES,
  SET_OUTCOME_TYPES,
  SET_PROCESSES,
  SET_RECENT_NOTE_ID,
  TRIGGER_USER_NOTES,
  UNSHIFT_NOTE,
  UPDATE_FLAGGED_NOTES,
  VALIDATE_NOTES
} from "./actionTypes";

export const addNotes = (key, data) => ({
  type: ADD_NOTES,
  key,
  data
});

export const getNotesEditHistory = (caseNoteReference) => ({
  type: GET_NOTES_EDIT_HISTORY,
  caseNoteReference
});

export const setNotesEditHistory = (data, caseNoteReference) => ({
  type: SET_NOTES_EDIT_HISTORY,
  data,
  caseNoteReference
});

export const saveNoteSpinner = (isSaving = false) => ({
  type: SET_NOTE_SAVE_SPINNER,
  isSaving
});

export const deleteOutcomesSpinner = (isSaving = false) => ({
  type: SET_NOTE_DELETE_OUTCOMES_SPINNER,
  isSaving
});
export const resetNotes = () => ({ type: RESET_NOTES });
export const resetNotesForm = () => ({ type: RESET_NOTES_FORM });

export const setNotesList = (data, pageNumber, loadMore) => ({
  type: SET_NOTES_LIST,
  data,
  pageNumber,
  loadMore
});

export const setNotesListLoading = (isLoading) => ({
  type: SET_NOTES_LIST_LOADING,
  isLoading
});

export const getNoteById = (caseNoteReference) => ({
  type: GET_NOTE_BY_ID,
  caseNoteReference
});

export const getNoteByIdAndTriggerEdit = (
  caseNoteReference,
  isEdit = true
) => ({
  type: GET_NOTE_BY_ID,
  caseNoteReference,
  isEdit
});

export const deleteOutcomes = (caseNoteReference) => ({
  type: DELETE_OUTCOMES,
  caseNoteReference
});
export const unshiftNotesList = (data) => ({
  type: UNSHIFT_NOTE,
  data
});

export const setNotesError = (payload) => ({
  type: SET_NOTES_ERROR,
  ...payload
});

export const setRecentNoteId = (caseNoteReference) => ({
  type: SET_RECENT_NOTE_ID,
  caseNoteReference
});

export const clearNotesError = (key) => ({
  type: CLEAR_NOTES_ERROR,
  key
});

export const getNotesList = (
  noteType = "case",
  id,
  loadMore = false,
  resetPage = 0,
  filters = null
) => ({
  type: GET_NOTES_LIST,
  noteType,
  id,
  filters,
  resetPage,
  loadMore
});

export const clearNotesList = () => ({ type: CLEAR_NOTES_LIST });

export const getNoteCategories = () => ({ type: GET_NOTE_CATEGORIES });

export const setNoteCategories = (data) => ({
  type: SET_NOTE_CATEGORIES,
  data
});

export const getNoteTypes = () => ({ type: GET_NOTE_TYPES });
export const setNoteTypes = (data) => ({ type: SET_NOTE_TYPES, data });

export const getOutcomeTypes = () => ({ type: GET_OUTCOME_TYPES });
export const setOutcomeTypes = (data) => ({ type: SET_OUTCOME_TYPES, data });

export const getProcesses = (caseId) => ({ type: GET_PROCESSES, caseId });
export const setProcesses = (data) => ({ type: SET_PROCESSES, data });

export const filterOutcomeTypes = (options) => ({
  type: FILTER_OUTCOME_TYPES,
  options
});
export const setFilterOutcomeTypes = (data) => ({
  type: SET_FILTER_OUTCOME_TYPES,
  data
});

export const getNoteTasks = (caseId) => ({ type: GET_NOTE_TASKS, caseId });

export const setNoteTasks = (data) => ({ type: SET_NOTE_TASKS, data });

export const insertNewNote = (
  noteType = "case",
  id,
  data = {},
  handler = () => {},
  isEdit,
  isLogCareOutcome
) => ({
  type: INSERT_NEW_NOTE,
  noteType,
  id,
  data,
  handler,
  isEdit,
  isLogCareOutcome
});

export const setEditNote = (data) => ({ type: EDIT_NOTES, ...data });

export const logCareOutcome = (isLogCareOutcome = true) => ({
  type: LOG_CARE_OUTCOME,
  isLogCareOutcome
});

export const setNoteFlag = (
  noteType = "case",
  noteReference,
  isFlagged,
  id
) => ({
  type: SET_NOTE_FLAG,
  noteType,
  isFlagged,
  noteReference,
  id
});

export const setFlagSpinner = (noteId, isLoading) => ({
  type: SET_FLAG_SPINNER,
  noteId,
  isLoading
});

export const addNoteListFilter = (filters, caseId) => ({
  type: ADD_NOTE_LIST_FILTER,
  filters,
  caseId
});

export const updateFlaggedNotes = (data, totalCount, totalFlagged) => ({
  type: UPDATE_FLAGGED_NOTES,
  data,
  totalCount,
  totalFlagged
});

export const setNoteListFilter = (data) => ({
  type: SET_NOTES_LIST_FILTER,
  data
});

export const setNotesValidation = (data) => ({ type: VALIDATE_NOTES, data });

export const getFlaggedUserNotes = (serviceUserReference) => ({
  type: GET_FLAGGED_USER_NOTES,
  serviceUserReference
});
export const setFlaggedUserNotes = (data) => ({
  type: SET_FLAGGED_USER_NOTES,
  data
});

export const triggerUserNotes = (isShow) => ({
  type: TRIGGER_USER_NOTES,
  isShow
});

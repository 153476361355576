import {
  GET_PORTALTYPE_SUCCESS,
  GET_PORTALTYPE_ERROR,
  CASE_HEPC_GETODNLIST_SUCCESS,
  CASE_HEPC_GETODNLIST_ERROR,
  GET_SERVICE_PROVIDER_SETTINGS_SUCCESS,
  GET_SERVICE_PROVIDER_SETTINGS_ERROR,
  RESET_SERVICE_PROVIDER_SETTINGS
} from "./actionType";
import { apiErrorObj } from "../../constants/defaults";

const initialState = {
  portalType: "",
  data: [],
  loading: false,
  error: apiErrorObj,
  flag: false,
  serviceProviderSettings: null
};

export const caseTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CASE_HEPC_GETODNLIST_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: true,
        flag: true
      };
    }
    case CASE_HEPC_GETODNLIST_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case GET_PORTALTYPE_SUCCESS : {
      return {
        ...state,
        portalType : action.payload.portalName
      }
    }
    case GET_PORTALTYPE_ERROR : {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case GET_SERVICE_PROVIDER_SETTINGS_SUCCESS: {
      return {
        ...state,
        serviceProviderSettings: action.payload,
        loading: true
      };
    }
    case GET_SERVICE_PROVIDER_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case RESET_SERVICE_PROVIDER_SETTINGS: {
      return initialState;
    }
    default:
      return state;
  }
};

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const RECORD_SUMMARY = "RECORD_SUMMARY";
const RECORD_SUMMARY_SUCCESS = "RECORD_SUMMARY_SUCCESS";
const RECORD_SUMMARY_ERROR = "RECORD_SUMMARY_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: []
};

function* recordSummaryGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `screens/${action.payload.screenReference}/summary`
    });

    yield put({
      type: RECORD_SUMMARY_SUCCESS,
      payload: {
        ...response,
        screenReference: action.payload.screenReference
      }
    });
  } catch (e) {
    yield put(
      apiException({
        type: RECORD_SUMMARY_ERROR,
        code: e.code || e,
        message: e.message || "RecordSummary-Fail"
      })
    );
  }
}

export const getrecordSummary = (payload) => ({
  type: RECORD_SUMMARY,
  payload
});

export function* recordSummaryGETAction() {
  yield takeLatest([RECORD_SUMMARY], recordSummaryGet);
}

export const recordSummaryReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case RECORD_SUMMARY: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case RECORD_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }

    case RECORD_SUMMARY_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

import jwtDecode from "jwt-decode";
import Luhn from "luhn-js";
import { getCookie } from "./utilsFunction";
import { getState } from "./helper";

export function getSuppressedMenus(data) {
  const suppressedMenuList = [];
  data.forEach((serviceProviderItem) => {
    Object.values(serviceProviderItem).forEach((settingItem) => {
      if (Array.isArray(settingItem)) {
        settingItem.forEach((item) => {
          if (item.Key === "SuppressMenus") {
            const menus = item?.Value ? JSON.parse(item.Value) : [];
            suppressedMenuList.push(...menus);
          }
        });
      }
    });
  });
  return [...new Set(suppressedMenuList)];
}

export function getSpecificSettings(data, spRef, settingName) {
  const settings = data?.find((item) => Object.keys(item)?.[0] === spRef);
  const operations = settings?.[spRef]?.find(
    (item) => item.Key === settingName
  );
  return operations?.Value && JSON.parse(operations.Value);
}

export function getServiceProvidersReferenceList() {
  let serviceProvidersList = [];
  try {
    const authtoken = getCookie("accessToken");
    const decodedAccessToken = jwtDecode(authtoken);

    serviceProvidersList = decodedAccessToken?.extension_ServiceProviderIds
      .split(",")
      .map((serviceProviderId) => `SP${Luhn?.generate(serviceProviderId)}`);
    return serviceProvidersList;
  } catch (e) {
    /* tslint:disable:no-empty */
  }
  return serviceProvidersList;
}

export function* getUpdatedPagedResults(caseListRes) {
  const { data } = yield getState("serviceProviderSettingsReducer");

  return caseListRes?.pagedResults?.map((caseItem) => {
    const enabledProcessTypes = getSpecificSettings(
      data,
      caseItem?.caseDetail?.serviceProviderReference,
      "EnabledProcessTypes"
    );
    const tasks =
      enabledProcessTypes?.length > 0
        ? caseItem?.tasks?.filter((taskItem) =>
            enabledProcessTypes?.includes(taskItem?.processTypeLocalisationKey)
          )
        : caseItem?.tasks;
    return {
      ...caseItem,
      tasks
    };
  });
}

export function* getUpdatedFiltersList(filters) {
  const settings = yield getState("serviceProviderSettingsReducer");

  return filters?.filter(
    (filterItem) =>
      settings.filterCounts?.[filterItem?.code] > 0 ||
      settings.filterCounts?.[filterItem?.code] === undefined
  );
}

import { RightAngleIcon, Typography, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const RightMenuLink = ({ title, path }) => (
  <Link to={path} className="redirectional-link">
    <Wrapper className="redirectional">
      <Typography className="redirectional__title">{title}</Typography>
      <RightAngleIcon className="redirectional__icon" />
    </Wrapper>
  </Link>
);

RightMenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { apiCall } from "../../../services/api";
import { getcaseHistory } from "../../../store/casehistory/logic";
import { ERROR_STATUS_400 } from "../../../constants/defaults";

const useServiceProviderSettings = () => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { serviceuserReferenceId } = useParams();
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const caseHistoryState = useSelector((state) => state.caseHistoryReducer);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const serviceProviderReference =
      caseHistoryState?.data?.caseHistories?.[0]?.serviceProviderReference;
    setLoading(true);
    apiCall({
      method: "get",
      apiType: "serviceproviders",
      url: `serviceproviders/${serviceProviderReference}/setting/CUPServiceUserProfileReadonlyFields`
    })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        if (
          err === ERROR_STATUS_400 &&
          Object.keys(caseHistoryState?.data).length === 0
        ) {
          dispatch(
            getcaseHistory({
              ServiceUserReference:
                caseHeaderState?.data?.serviceUserReference ||
                serviceuserReferenceId
            })
          );
        } else {
          setLoading(false);
        }
      });
  }, [caseHistoryState?.data]);

  return { loading, data };
};

export default useServiceProviderSettings;

import _ from "lodash";
import { Icon, Typography, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { useCookies } from "react-cookie";
import { useClickOutside } from "../../hooks";
import { loginRequest, logoutRequest } from "../../../AuthConfig";
import { goToOldApp } from "../../../utils/helper";
import { CLINICAL_USER_URL, MY_PROFILE_URL } from "../../../constants/defaults";

export const Settings = ({ className, openHandler, isOpen }) => {
  const { instance } = useMsal();
  const [, , removeCookie] = useCookies(["accessToken"]);

  const settings = [
    {
      title: "clinical-user-list",
      handler: () => goToOldApp(CLINICAL_USER_URL)
    },
    {
      title: "my-profile",
      handler: () => goToOldApp(MY_PROFILE_URL)
    },
    {
      title: "sign-out",
      handler: async () => {
        const accounts = instance.getAllAccounts();
        const currentAccount = instance.getAccountByHomeId(
          accounts && accounts.length && accounts[0].homeAccountId
        );
        removeCookie("accessToken", { path: "/", maxAge: 0 });
        await instance.logoutRedirect({
          ...loginRequest,
          account: currentAccount,
          ...logoutRequest
        });
      }
    }
  ];
  const node = useRef();
  useClickOutside(node, openHandler, isOpen);
  return (
    <div className={`nav-settings ${className}`} ref={node}>
      {_.map(settings, ({ title, icon, handler }, index) => (
        <Wrapper
          className="nav-settings__title"
          handler={handler}
          key={`settings-${index + 1}`}>
          {icon && <Icon icon={icon} mr={11} />}
          <Typography color="preventx-blue">{title}</Typography>
        </Wrapper>
      ))}
    </div>
  );
};

Settings.defaultProps = {
  className: "",
  openHandler: null,
  isOpen: false
};

Settings.propTypes = {
  className: PropTypes.string,
  openHandler: PropTypes.func,
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export const GET_SERVICE_USER_REFERENCES = "GET_SERVICE_USER_REFERENCES";
export const GET_SERVICE_USERS_BY_REFERENCES =
  "GET_SERVICE_USERS_BY_REFERENCES";

export const GET_SERVICE_USER_REFERENCES_MORE =
  "GET_SERVICE_USER_REFERENCES_MORE";

export const CLEAR_SERVICE_USER_REFERENCES_ERROR =
  "CLEAR_SERVICE_USER_REFERENCES_ERROR";

export const SERVICE_USER_REFERENCES_SUCCESS =
  "SERVICE_USER_REFERENCES_SUCCESS";

export const SERVICE_USER_REFERENCES_MORE_SUCCESS =
  "SERVICE_USER_REFERENCES_MORE_SUCCESS";

export const SET_SERVICE_USER_DETAILS = "SET_SERVICE_USER_DETAILS";

export const SERVICE_USER_REFERENCES_ERROR = "SERVICE_USER_REFERENCES_ERROR";

export const UPDATE_USERS_LIST_FILTERS = "UPDATE_USERS_LIST_FILTERS";
export const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";

export const SET_PLATFORM_USER_NAME = "SET_PLATFORM_USER_NAME";

export const RESET_USER_LIST = "RESET_USER_LIST";

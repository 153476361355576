import { BadgeIcon, Icon } from "preventx-storybook";
import React from "react";
import {
  BrandDefault,
  NotificationIcon,
  SearchIcon,
  SettingsIcon
} from "../assets/IconList";
import { Settings } from "../components/molecules/Settings/Settings";
import { SearchModal } from "../components/organisms/Search/SearchModal";
import { goToOldApp } from "../utils/helper";
import { CASE_LIST, MESSAGE_LIST, TODAY_LIST, USER_LIST } from "./paths";
import { Notification } from "../components/organisms/Notification/Notification";
import {
  FREE_TEST_ME_SMART_KITS_URL,
  MANAGE_NOTIFICATON_URL,
  REFERRER_URL,
  REPORTING_URL,
  SMART_KITS_URL
} from "./defaults";

const menusModel = {
  brand: <BrandDefault />,
  leftMenus: [
    {
      icon: "",
      menuName: "Today",
      tagCount: "",
      route: TODAY_LIST
    },

    { icon: "", menuName: "Cases", tagCount: "", route: CASE_LIST },
    { icon: "", menuName: "Messages", tagCount: "", route: MESSAGE_LIST },
    {
      icon: "",
      menuName: "Users",
      tagCount: "",
      route: USER_LIST
    },
    {
      icon: "",
      menuName: "FreeTestSmartKits",
      tagCount: "",
      route: "#",
      handler: () => goToOldApp(FREE_TEST_ME_SMART_KITS_URL)
    },
    {
      menuName: "Tools",
      tagCount: "",
      route: "#",
      subMenu: [
        {
          title: "Reporting",
          handler: () => goToOldApp(REPORTING_URL)
        },
        {
          title: "SmartKits",
          handler: () => goToOldApp(SMART_KITS_URL)
        },
        {
          title: "list-referers",
          handler: () => goToOldApp(REFERRER_URL)
        },
        {
          title: "manage-notification",
          handler: () => goToOldApp(MANAGE_NOTIFICATON_URL)
        }
      ]
    }
  ],
  rightMenus: [
    {
      icon: (
        <Icon icon={<SearchIcon title="Search" />} stroke="preventx-blue" />
      ),
      menuName: "search",
      component: <SearchModal />,
      visible: ["xlg", "md", "lg"]
    },
    {
      icon: (
        <Icon icon={<SettingsIcon title="Settings" />} stroke="preventx-blue" />
      ),
      menuName: "settings",
      component: <Settings />,
      visible: ["xlg", "md", "lg"]
    }
  ],
  mobileMenus: [
    {
      icon: <Icon icon={<SearchIcon />} stroke="preventx-blue" />,
      component: <SearchModal />,
      menuName: "Search",
      handler: null,
      divider: false,
      visible: "xs",
      route: "#"
    },
    {
      menuName: "clinical-user-list",
      handler: null,
      visible: "xs",
      route: "#"
    },
    {
      menuName: "my-profile",
      handler: null,
      visible: "xs",
      route: "#"
    },
    {
      menuName: "sign-out",
      handler: null,
      visible: "xs",
      route: "#"
    }
  ]
};

if (process.env.REACT_APP_NOTIFICATION_ENABLE === "true") {
  menusModel.rightMenus.splice(1, 0, {
    icon: <BadgeIcon variant="count" icon={<NotificationIcon />} />,
    menuName: "notification",
    component: <Notification />,
    visible: "all"
  });
}

export const menus = {
  ...menusModel
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Tasks,
  Wrapper,
  translate,
  Safeguardingcard
} from "preventx-storybook";

// Constants
import {
  DATE_FORMAT,
  PROCESS_DEFAULT_OPEN,
  ProcessTypeSafeguarding
} from "../../../constants/defaults";

// Hooks
import useMenuSettings from "../../hooks/useMenuSettings";

// Services
import { convertToDateTime } from "../../../utils/utilsFunction";
import { EditOutcomes, putTaskAction } from "../../../utils/helper";

// State Management
import {
  postCreateTask,
  resetCreateTask
} from "../../../store/createTask/logic";
import { getNoteByIdAndTriggerEdit } from "../../../store/actionTypes";
import { resetGetSafeguarding } from "../../../store/safeguarding/logic";

// Components
import { NewTask } from "../NewTask/NewTask";

export const Safeguarding = () => {
  const { t } = translate();
  const dispatch = useDispatch();

  const taskTypeState = useSelector((state) => state.taskTypeReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const createTaskState = useSelector((state) => state.createTaskReducer);
  const safeGuardingState = useSelector((state) => state.safeGuardingReducer);

  const { moreAction } = useMenuSettings();

  const [showModal, setShowModal] = useState(false);
  const [inputData, setInputData] = useState({});
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (safeGuardingState.flag) {
      setShowModal(false);
      dispatch(resetGetSafeguarding());
    }
  }, [safeGuardingState.flag]);

  useEffect(() => {
    if (createTaskState.flag) {
      setInputData({});
      // setEditData({});
      dispatch(resetCreateTask());
      setShowModal(false);
    }
  }, [createTaskState.flag]);

  if (!safeGuardingState.data) {
    return null;
  }

  const submitForm = (obj) => {
    obj.dueDate = convertToDateTime(obj.dueDate, DATE_FORMAT, "YYYY-MM-DD");
    if (Object.keys(obj).length > 1) {
      dispatch(
        postCreateTask({
          processReference: safeGuardingState.data.processReference,
          body: {
            ...obj,
            ProcessReference: safeGuardingState.data.processReference
          },
          caseReference: caseHeaderState.data.caseReference,
          processType: ProcessTypeSafeguarding,
          message: t("Task-Created-Message")
        })
      );
    } else {
      dispatch(
        postCreateTask({
          processReference: safeGuardingState.data.processReference,
          body: obj,
          caseReference: caseHeaderState.data.caseReference,
          taskReference: editData.taskReference,
          processType: ProcessTypeSafeguarding,
          message: t("Task-Edit-Due-Date-Message")
        })
      );
    }
  };

  return (
    <Wrapper className="mb-25">
      <Safeguardingcard
        showNewTask
        defaultOpen={PROCESS_DEFAULT_OPEN}
        moreAction={moreAction}
        maxWidth={736}
        inputModel={safeGuardingState?.data}
        handlerEditOutcome={(item) =>
          item?.caseNoteReference && EditOutcomes(item?.caseNoteReference)
        }
        handlerNewTask={() => {
          let taskTypeId = "";
          if (
            taskTypeState?.data &&
            taskTypeState?.data[ProcessTypeSafeguarding] &&
            taskTypeState?.data[ProcessTypeSafeguarding].length
          ) {
            taskTypeId = taskTypeState?.data[ProcessTypeSafeguarding][0].id;
          }
          setInputData({
            taskTypeId
          });
          setEditData({});
          setShowModal(true);
        }}
        handlerTriage={() => {
          window.open(
            `/triage-report/${safeGuardingState?.data?.questionnaireReference}/${caseHeaderState?.data?.serviceUserReference}`,
            "_blank"
          );
        }}>
        <Tasks
          onhandlerAttempted={(noteid) => {
            dispatch(getNoteByIdAndTriggerEdit(noteid));
          }}
          tasksData={safeGuardingState?.data && safeGuardingState?.data.tasks}
          taskApiFn={putTaskAction}
          caseReference={caseHeaderState.data.caseReference}
          processReference={safeGuardingState.data.processReference}
          taskHandler={(item, taskReference) => {
            if (item?.localisationKey?.includes("ChangeDueDate")) {
              const tmpTask = safeGuardingState.data.tasks.find(
                (subitem) => subitem.taskReference === taskReference
              );
              setShowModal(true);
              setEditData(tmpTask);
            }
          }}
        />
      </Safeguardingcard>
      <NewTask
        toggleModal={() => {
          setShowModal(!showModal);
          setInputData({});
          setEditData({});
        }}
        showModal={showModal}
        handlerSubmit={submitForm}
        inputModel={inputData}
        editData={editData}
        onChangeHandle={(obj) => setInputData(obj)}
        handlerDueDateChange={(dt) =>
          setInputData({
            ...inputData,
            ...{
              dueDate: dt
            }
          })
        }
        processTypeCode={ProcessTypeSafeguarding}
        isPrimaryLoading={createTaskState.loading}
      />
    </Wrapper>
  );
};

import {
  Button,
  CloseIcon,
  SearchIcon,
  Typography,
  Wrapper
} from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters } from "../../../store/filterlist/actionCreator";
import { resetsearchCase } from "../../../store/searchcase/logic";
import { resetsearchCount } from "../../../store/searchcount/logic";
import { resetsearchUser } from "../../../store/searchuser/logic";
import { SearchModal } from "./SearchModal";

export const SearchListHeader = ({ pageType }) => {
  const dispatch = useDispatch();
  const searchCountState = useSelector((state) => state.searchCountReducer);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
    dispatch(resetsearchCase());
    dispatch(resetsearchUser());
  };
  return (
    <Wrapper className="editsearch__container">
      <Wrapper className="editsearch__container__count mr-25">
        <Typography weight="medium" mr={5}>
          {pageType === "caseList"
            ? searchCountState.data?.updatedCount?.caseCount
            : searchCountState.data?.updatedCount?.userCount}
        </Typography>
        <Typography weight="medium">search results</Typography>
      </Wrapper>
      <Wrapper className="editsearch__container__right">
        <Wrapper className="editsearch__container__editbtn mr-25">
          <Button
            variant="secondary"
            handler={toggleModal}
            icon={<SearchIcon />}>
            edit-search
          </Button>
        </Wrapper>
        <Wrapper className="editsearch__container__editbtn mr-25">
          <Button
            variant="tertiary"
            icon={<CloseIcon />}
            handler={() => {
              dispatch(resetsearchCount());
              dispatch(resetFilters(pageType));
            }}>
            clear-search
          </Button>
        </Wrapper>
      </Wrapper>
      <SearchModal isOpen={showModal} openHandler={toggleModal} />
    </Wrapper>
  );
};

SearchListHeader.propTypes = {
  pageType: PropTypes.string.isRequired
};

import { useIdleTimer } from "react-idle-timer";
import { useMsal } from "@azure/msal-react";
import { logoutRequest, loginRequest } from "../../AuthConfig";

export const IdleTimer = () => {
  const { instance } = useMsal();

  const onIdle = async () => {
    const accounts = instance.getAllAccounts();
    const currentAccount = instance.getAccountByHomeId(
      accounts && accounts.length && accounts[0].homeAccountId
    );
    await instance.logoutRedirect({
      ...loginRequest,
      account: currentAccount,
      ...logoutRequest
    });
  };

  useIdleTimer({
    onIdle,
    crossTab: true,
    timeout: process.env.REACT_APP_IDLE || 300000
  });

  return null;
};

import { updateCaseListFilter } from "../caselist/logic";
import { updateTodayListFilter } from "../todaylist/logic";
import {
  CLEAR_FILTERS_FOR_SEARCH_FORM,
  FILTERS_SELECTED,
  FILTER_LIST,
  FILTER_LIST_ERROR,
  FILTER_LIST_SUCCESS,
  GET_FILTER_COUNTS,
  NO_FILTERS,
  RESET_FILTERS,
  SET_CURRENT_FILTERS,
  SET_DEFAULT_COLLAPSED,
  SET_DEFAULT_FILTERS,
  SET_FILTER_COLLAPSE,
  SET_FILTER_COUNTS,
  SET_TOGGLE_SIDEBAR,
  TOGGLE_FILTER_COLLAPSE
} from "./actionType";

export const getfilterList = (filterType = "workList") => ({
  type: FILTER_LIST,
  filterType
});

export const setFilterList = (treeState, filterType) => ({
  type: FILTER_LIST_SUCCESS,
  treeState,
  filterType
});

export const updateFilterPayload = (params, filterType) =>
  filterType === "caseList"
    ? updateCaseListFilter(params)
    : updateTodayListFilter(params);

export const setFilterListError = (response = [], filterType) => ({
  type: FILTER_LIST_ERROR,
  response,
  filterType
});

export const setCurrentFilters = (data) => ({
  type: SET_CURRENT_FILTERS,
  data
});

export const getFilterCounts = (filterType, filters) => ({
  type: GET_FILTER_COUNTS,
  filters,
  filterType
});
export const setFilterCounts = (filterType, counts = {}) => ({
  type: SET_FILTER_COUNTS,
  counts,
  filterType
});

export const setFiltersSelected = (data) => ({
  type: FILTERS_SELECTED,
  data
});

export const resetFilters = (filterType, isReloadPage = true) => ({
  type: RESET_FILTERS,
  filterType,
  isReloadPage
});
export const noFilters = (filterType) => ({
  type: NO_FILTERS,
  filterType
});

export const toggleFilterCollapse = (collapsedList, filterType) => ({
  type: TOGGLE_FILTER_COLLAPSE,
  filterType,
  collapsedList
});

export const setFilterCollapse = (collapsedList, filterType) => ({
  type: SET_FILTER_COLLAPSE,
  filterType,
  collapsedList
});

export const setDefaultFilters = (defaultFilters, filterType) => ({
  type: SET_DEFAULT_FILTERS,
  filterType,
  defaultFilters
});

export const setDefaultCollapsed = (defaultCollapsed, filterType) => ({
  type: SET_DEFAULT_COLLAPSED,
  filterType,
  defaultCollapsed
});

export const setToggleFilterDrawer = (isOpen) => ({
  type: SET_TOGGLE_SIDEBAR,
  isOpen
});

// to be enhanced
export const clearFiltersForSearch = (filters, filterType) => ({
  type: CLEAR_FILTERS_FOR_SEARCH_FORM,
  filters,
  filterType
});

import React, { useState, useEffect } from "react";
import { Wrapper, CareCard, Tasks, translate } from "preventx-storybook";
import { useDispatch, useSelector } from "react-redux";
import { NewTask } from "../NewTask/NewTask";
import { resetGetCare } from "../../../store/care/logic";
import {
  postCreateTask,
  resetCreateTask
} from "../../../store/createTask/logic";
import { convertToDateTime } from "../../../utils/utilsFunction";
import { DATE_FORMAT, ProcessTypeCare, PROCESS_DEFAULT_OPEN } from "../../../constants/defaults";
import { putTaskAction } from "../../../utils/helper";
import { OutComeConfirmModal } from "./OutcomeDeleteConfirm";
import { getNoteByIdAndTriggerEdit, logCareOutcome } from "../../../store/actionTypes";

export const Care = () => {
  const {t} = translate();
  const dispatch = useDispatch();
  const careState = useSelector((state) => state.careReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const createTaskState = useSelector((state) => state.createTaskReducer);
  const taskTypeState = useSelector((state) => state.taskTypeReducer);
  
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [inputData, setInputData] = useState({});
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (careState.flag) {
      setShowModal(false);
      dispatch(resetGetCare());
    }
  }, [careState.flag]);

  useEffect(() => {
    if (createTaskState.flag) {
      setInputData({});
      // setEditData({});
      dispatch(resetCreateTask());
      setShowModal(false);
    }
  }, [createTaskState.flag]);

  if (!careState.data) {
    return null;
  }

  const submitForm = (obj) => {
    obj.dueDate = convertToDateTime(obj.dueDate, DATE_FORMAT, "YYYY-MM-DD");
    if (Object.keys(obj).length > 1) {
      dispatch(
        postCreateTask({
          processReference : careState.data.processReference,
          body: {
            ...obj,
            ProcessReference : careState.data.processReference
          },
          caseReference: caseHeaderState.data.caseReference,
          processType: ProcessTypeCare,
          message : t('Task-Created-Message')
        })
      );
    } else {
      dispatch(
        postCreateTask({
          processReference: careState.data.processReference,
          body: obj,
          caseReference: caseHeaderState.data.caseReference,
          taskReference: editData.taskReference,
          processType: ProcessTypeCare,
          message: t("Task-Edit-Due-Date-Message")
        })
      );
    }
  };

  return (
    <Wrapper className="mb-25">
      <CareCard
        showNewTask
        defaultOpen={PROCESS_DEFAULT_OPEN}
        maxWidth={736}
        inputModel={careState?.data}
        handlerEditOutcome={(item) => {
          setShowConfirmModal(true);
          setEditData(item);
        }}
        handlerNewTask={() => {
          let taskTypeId = "";
          if(taskTypeState?.data && taskTypeState?.data[ProcessTypeCare] && taskTypeState?.data[ProcessTypeCare].length){
            taskTypeId = taskTypeState?.data[ProcessTypeCare][0].id
          }
          setInputData({
            taskTypeId
          });
          setEditData({});
          setShowModal(true);
        }}
        handlerLogOutcome={() => dispatch(logCareOutcome())}>
        <Tasks
          onhandlerAttempted={(noteid) => {
            dispatch(
              getNoteByIdAndTriggerEdit(noteid)
            )
          }}
          tasksData={careState?.data && careState?.data.tasks}
          taskApiFn={putTaskAction}
          caseReference={caseHeaderState.data.caseReference}
          processReference={careState.data.processReference}
          taskHandler={(item, taskReference) => {
            if (item?.localisationKey?.includes("ChangeDueDate")) {
              const tmpTask = careState.data.tasks.find(
                (subitem) => subitem.taskReference === taskReference
              );
              setShowModal(true);
              setEditData(tmpTask);
            }
          }}
        />
      </CareCard>
      <NewTask
        toggleModal={() => {
          setShowModal(!showModal);
          setInputData({});
          setEditData({});
        }}
        showModal={showModal}
        handlerSubmit={submitForm}
        inputModel={inputData}
        editData={editData}
        onChangeHandle={(obj) => setInputData(obj)}
        handlerDueDateChange={(dt) =>
          setInputData({
            ...inputData,
            ...{
              dueDate: dt
            }
          })
        }
        processTypeCode={ProcessTypeCare}
        isPrimaryLoading={createTaskState.loading}
      />
      <OutComeConfirmModal
        toggleModal={() => {
          setShowConfirmModal(!showConfirmModal);
          setEditData({});
        }}
        showModal={showConfirmModal}
        inputModel={editData}
      />
    </Wrapper>
  );
};

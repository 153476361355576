import {
  Button,
  Divider,
  Loader,
  SidebarMessage,
  Spinner,
  Wrapper
} from "preventx-storybook";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RightMenuPanel } from "..";
import { apiCall, apiException } from "../../../services/api";
import {
  getMessage,
  getMessageMore,
  resetMessage
} from "../../../store/message/logic";
import { goToOldApp, replyThread } from "../../../utils/helper";
import { calcMessageHeight } from "../../../utils/utilsFunction";
import { useWindow } from "../../hooks";

export const Messages = () => {
  const dispatch = useDispatch();
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const messageState = useSelector((state) => state.messageReducer);
  const { serviceuserReferenceId } = useParams();
  const [messageListHeight, setmessageListHeight] = useState();
  const serviceUserState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );

  useEffect(() => {
    if (
      !caseHeaderState.loading &&
      !messageState?.all?.data?.pagedResults?.length
    ) {
      dispatch(
        getMessage({
          serviceUserReference: caseHeaderState?.data?.serviceUserReference
        })
      );
    }
  }, [caseHeaderState?.loading]);

  useEffect(() => {
    if (
      serviceuserReferenceId &&
      !messageState?.all?.data?.pagedResults?.length &&
      serviceUserState?.data?.ServiceUserReference === serviceuserReferenceId
    ) {
      dispatch(
        getMessage({
          serviceUserReference: serviceuserReferenceId
        })
      );
    }
    return () => {
      dispatch(resetMessage());
    };
  }, [serviceuserReferenceId, serviceUserState?.data]);

  const fetchMoreMessage = () => {
    dispatch(
      getMessageMore({
        serviceUserReference:
          caseHeaderState?.data?.serviceUserReference || serviceuserReferenceId
      })
    );
  };

  const calculateHeight = () => {
    setmessageListHeight(calcMessageHeight());
  };

  useEffect(() => calculateHeight(), [window.innerWidth]);

  useWindow(calculateHeight, []);

  const componsenew = async () => {
    let url = "";
    if (caseHeaderState?.data?.serviceProviderReference) {
      url = `serviceUsers/${
        caseHeaderState?.data?.serviceUserReference || serviceuserReferenceId
      }/redirecturl?action=new&serviceproviderreference=${
        caseHeaderState?.data?.serviceProviderReference
      }`;
    } else {
      url = `serviceUsers/${
        caseHeaderState?.data?.serviceUserReference || serviceuserReferenceId
      }/redirecturl?action=new`;
    }
    apiCall({
      apiType: "messaging",
      url
    })
      .then((response) => {
        if (response?.redirectUrl) {
          goToOldApp(response?.redirectUrl, true);
        }
      })
      .catch(() => {
        dispatch(
          apiException({
            type: null,
            message: "Error-500"
          })
        );
      });
  };

  return (
    <Wrapper className="sb-message">
      <RightMenuPanel title="Messages" noAction>
        <Wrapper>
          {!serviceuserReferenceId && (
            <Button fullWidth handler={componsenew}>
              compose-new-thread
            </Button>
          )}
          <Wrapper pt={16}>
            <Divider absolute />
          </Wrapper>
        </Wrapper>
        <Loader
          loading={messageState?.all?.loading}
          error={messageState?.all?.error.isError}
          errorMessage={messageState?.all?.error.message}
          errorCode={messageState?.all?.error.code}
          height="20vh"
          noData={!messageState?.all?.data?.pagedResults?.length}>
          <Wrapper pt={16} tabIndex={0}>
            <InfiniteScroll
              className="tablelist-scroll smothscroll scrollbar"
              dataLength={messageState?.all?.data?.totalCount || 0}
              next={fetchMoreMessage}
              hasMore={
                messageState?.all?.data?.pagedResults?.length <
                messageState?.all?.data?.totalCount
              }
              scrollThreshold={0.9}
              height={messageListHeight}
              loader={<Spinner size={48} />}
              width="100%">
              {!!messageState?.all?.data?.pagedResults?.length &&
                messageState?.all?.data?.pagedResults.map((item, index) => (
                  <SidebarMessage
                    mb={16}
                    inputModel={item}
                    key={`message-${index + 1}`}
                    handlerReply={replyThread}
                  />
                ))}
            </InfiniteScroll>
          </Wrapper>
        </Loader>
      </RightMenuPanel>
    </Wrapper>
  );
};

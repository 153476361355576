import {
  ContactCard,
  ProfileCard,
  SidebarAccountCard,
  Wrapper,
  translate
} from "preventx-storybook";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StausBar } from "..";
import PersonalInfoForm from "../../../../json/personalinfoForm";
import ContactForm from "../../../../json/contactinfoForm";
import { InformationEdit } from "./InformationEdit";
import {
  convertToDate,
  dayconvertToFormat
} from "../../../../utils/utilsFunction";
import { DATE_FORMAT } from "../../../../constants/defaults";
import {
  putserviceUserReference,
  resetserviceUserReference
} from "../../../../store/serviceuserreference/update/logic";
import { CloseAccountStatus } from "../../../../constants/closeaccountstatus";
import { CaseClosed } from "../../CaseClosed/CaseClosed";

export const ServiceUserProfile = () => {
  const {t} = translate();
  const dispatch = useDispatch();
  const serviceUserState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );
  const [inputModel, setinputModel] = useState(serviceUserState?.data);
  const [showModal, setShowModal] = useState(false);
  const [formjson, setformJson] = useState({});
  const [title, setTitle] = useState("");
  const serviceUserUpdateState = useSelector(
    (state) => state.serviceUserReferenceUpdateReducer
  );

  useEffect(() => {
    if (serviceUserUpdateState.flag) {
      setShowModal(false);
      dispatch(resetserviceUserReference());
    }
  }, [serviceUserUpdateState.flag]);

  useEffect(() => {
    PersonalInfoForm.forEach(async (item) => {
      if (
        item?.options &&
        typeof item.options === "function" &&
        !item.isDependent
      ) {
        const response = await item.options();
        item.options = response;
      }
      if (item?.isDependent && serviceUserState?.data[item.isDependent]) {
        const response = await item.getOptions(
          serviceUserState?.data[item.isDependent]
        );
        item.disabled = false;
        item.options = response;
      }
    });
    ContactForm.forEach(async (item) => {
      if (item?.options && typeof item.options === "function") {
        const response = await item.options();
        item.options = response;
      }
    });
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onSubmit = (formObj) => {
    const copyInputState = { ...serviceUserState.data };
    const copyInputModel = { ...serviceUserState.data };
    const subFormObj = { ...formObj };
    if (subFormObj?.address1?.length) {
      subFormObj.PrimaryAddress = [];
      subFormObj.address1.forEach((item) => {
        if (!item.isError && item.value) {
          subFormObj.PrimaryAddress.push(item.value);
        }
      });
      if (!subFormObj.PrimaryAddress.length) {
        return false;
      }
      delete subFormObj.address1;
    }
    if (subFormObj?.DateOfBirth) {
      subFormObj.DateOfBirth = convertToDate(
        subFormObj.DateOfBirth,
        DATE_FORMAT,
        "YYYY-MM-DD"
      );
    }
    if (subFormObj?.MobileNumber?.number) {
      subFormObj.MobileNumber = subFormObj?.MobileNumber?.number;
    }

    delete copyInputModel?.address1;
    delete subFormObj?.address1;

    delete subFormObj?.isProfileComplete;
    delete copyInputModel?.isProfileComplete;

    const updatedModel = { ...copyInputModel, ...subFormObj };
    if (JSON.stringify(copyInputState) !== JSON.stringify(updatedModel)) {
      dispatch(
        putserviceUserReference({
          url: `serviceusers/${serviceUserState?.data?.ServiceUserReference}`,
          body: updatedModel,
          message: `${t(`${title}-Update`)}`
        })
      );
    }
    return null
  };

  const onFormChange = async (dt) => {
    const tmpjson = [...formjson];
    const promises = [];
    const index = [];
    for (let i = 0; i < tmpjson.length; i += 1) {
      if (
        tmpjson[i]?.isDependent &&
        dt[tmpjson[i]?.isDependent] &&
        dt[tmpjson[i].isDependent] !== inputModel[tmpjson[i].isDependent]
      ) {
        promises.push(tmpjson[i].getOptions(dt[tmpjson[i]?.isDependent]));
        index.push(i);
      }
    }
    if (promises.length) {
      const responses = await Promise.all(promises);
      index.forEach((item, ind) => {
        tmpjson[item].options = responses[ind];
        tmpjson[item].disabled = false;
        dt[tmpjson[item].name] = "";
      });
      setformJson([...tmpjson]);
    }
    setinputModel(dt);
  };

  const openModal = (tmpformjson) => {
    let inputmodel = "";
    if (serviceUserState?.data?.DateOfBirth) {
      inputmodel = {
        DateOfBirth: dayconvertToFormat(
          serviceUserState?.data?.DateOfBirth,
          DATE_FORMAT
        )
      };
    }
    if (serviceUserState?.data?.MobileNumber) {
      inputmodel = {
        ...inputmodel,
        ...{
          MobileNumber: {
            value: serviceUserState?.data?.MobileNumberCountryCode,
            number: serviceUserState?.data?.MobileNumber
          }
        }
      };
    }
    if (inputmodel) {
      setinputModel({
        ...serviceUserState?.data,
        ...inputmodel
      });
    }
    setShowModal(true);
    setformJson(tmpformjson);
  };

  return (
    <Wrapper className="servicepage">
      <Wrapper className="servicepage__leftContext">
        <StausBar />
        <Wrapper className="servicepage__leftContext__details">
          {CloseAccountStatus.indexOf(serviceUserState?.data?.AccountStatus) ===
          -1 ? (
            <>
              {!!Object.keys(serviceUserState?.data).length && (
                <SidebarAccountCard
                  inputModel={serviceUserState?.data}
                  title="Account-Details"
                  flexDirection="row"
                  mb={24}
                />
              )}
              <ProfileCard
                inputModel={serviceUserState?.data}
                title="Personal-Information"
                mb={24}
                handlerEdit={() => {
                  setTitle("Personal-Information");
                  openModal(PersonalInfoForm);
                }}
              />
              <ContactCard
                inputModel={serviceUserState?.data}
                title="Contact-Information"
                flexDirection="row"
                handlerEdit={() => {
                  setTitle("Contact-Information");
                  openModal(ContactForm);
                }}
              />
            </>
          ) : (
            <CaseClosed />
          )}
        </Wrapper>
      </Wrapper>
      {showModal && (
        <InformationEdit
          showModal={showModal}
          toggleModal={toggleModal}
          onFormChange={onFormChange}
          onSubmit={onSubmit}
          inputModel={inputModel}
          formJson={formjson}
          title={title}
        />
      )}
    </Wrapper>
  );
};

import React, { useEffect } from "react";
import { Spinner, Wrapper } from "preventx-storybook";
import { useHistory } from "react-router-dom";

export const AuthenticateTemplate = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/");
  }, []);

  return (
    <Wrapper
      flex
      align="center"
      style={{ height: "100vh", "justify-content": "center" }}>
      <Spinner size={48} />
    </Wrapper>
  );
};

import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException, apiSuccess } from "../../services/api";
import { getCareProcess } from "../care/logic";
import { getPartnerTracingProcess } from "../partnertracing/logic";
import { getSafeguardingProcess } from "../safeguarding/logic";
import { getTestingProcess } from "../testing/logic";

const CREATE_TASK = "CREATE_TASK";
const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
const CREATE_TASK_ERROR = "CREATE_TASK_ERROR";
const CREATE_TASK_RESET = "CREATE_TASK_RESET";

const initialState = {
  loading: false,
  error: apiErrorObj,
  data: {},
  flag: false
};

function* createTask(action) {
  try {
    const response = yield apiCall({
      method: action.payload.taskReference ? "put" : "post",
      url: action.payload.taskReference
        ? `tasks/${action.payload.taskReference}/duedate`
        : `tasks`,
      data: action.payload.body,
    });

    yield put(apiSuccess(action.payload.message));

    yield put({
      type: CREATE_TASK_SUCCESS,
      payload: {
        ...action.payload,
        response
      }
    });
    if (
      action.payload.processType.toLocaleLowerCase().includes("partnertracing")
    ) {
      yield put(
        getPartnerTracingProcess({
          caseReference: action.payload.caseReference,
          processReference: action.payload.processReference
        })
      );
    } else if (
      action.payload.processType.toLocaleLowerCase().includes("safeguarding")
    ) {
      yield put(
        getSafeguardingProcess({
          caseReference: action.payload.caseReference,
          processReference: action.payload.processReference
        })
      );
    } else if (
      action.payload.processType.toLocaleLowerCase().includes("care")
    ) {
      yield put(
        getCareProcess({
          caseReference: action.payload.caseReference,
          processReference: action.payload.processReference
        })
      );
    } else if (
      action.payload.processType.toLocaleLowerCase().includes("testing")
    ) {
      yield put(
        getTestingProcess({
          caseReference: action.payload.caseReference,
          processReference: action.payload.processReference
        })
      );
    }
  } catch (e) {
    yield put(
      apiException({
        type: CREATE_TASK_ERROR,
        code: e.code || e,
        message:
          e.message || action.payload.taskReference
            ? "TaskUpdate-Fail"
            : "TaskCreate-Fail"
      })
    );
  }
}

export const postCreateTask = (payload) => ({
  type: CREATE_TASK,
  payload
});

export const resetCreateTask = (payload) => ({
  type: CREATE_TASK_RESET,
  payload
});

export function* createTaskGETAction() {
  yield takeLatest([CREATE_TASK], createTask);
}

export const createTaskReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CREATE_TASK: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CREATE_TASK_SUCCESS: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }
    case CREATE_TASK_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case CREATE_TASK_RESET: {
      return {
        ...state,
        loading: false,
        data: {},
        error: apiErrorObj,
        flag: false
      };
    }
    default:
      return state;
  }
};

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import _ from "lodash";
import { Loader, Page, Spinner, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { FilterButton } from "../../components/molecules";
import { Filters } from "../../components/organisms";
import { SearchListHeader } from "../../components/organisms/Search/SearchListHeader";
import {
  ServiceUserDetails,
  UserListHeader
} from "../../components/organisms/UserList";
import { resetFilters } from "../../store/filterlist/actionCreator";
import {
  getServiceUserReferences,
  getServiceUserReferencesMore,
  resetUserList
} from "../../store/userdetails/actionCreators";
import { scrollContent } from "../../utils/utilsFunction";
import { AuthenticateTemplate } from "../AuthenticateTemplate";

export const ServiceUserList = ({ filterType }) => {
  const dispatch = useDispatch();
  const { loading, error, scrollposition, serviceUserDetails } = useSelector(
    (state) => state.userDetailsReducer
  );
  const searchCountState = useSelector((state) => state.searchCountReducer);

  const { filters, error: filterError } = useSelector(
    (state) => state.filterListReducer.usersList
  );

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(getServiceUserReferences({ filterType }));
    }
    scrollContent(scrollposition, "case-list-scroll");
    return () => {
      dispatch(resetUserList());
    };
  }, [filters.length]);

  const fetchMoreData = () => {
    dispatch(getServiceUserReferencesMore({ filterType }));
  };

  useEffect(
    () => () => {
      if (filters?.length > 0 && !searchCountState.isSearch) {
        dispatch(resetFilters(filterType, false));
      }
    },
    [filterType, filters?.length, searchCountState.isSearch]
  );

  const filterButton = () => (
    <>
      <FilterButton />
      {searchCountState?.isSearch?.user && (
        <Wrapper className="editsearch-container-web">
          <SearchListHeader pageType={filterType} />
        </Wrapper>
      )}
    </>
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Wrapper
          flex
          fw
          className={`caselist__container ${
            searchCountState?.isSearch?.user && "search-enabled"
          }`}>
          <Page
            title="Users"
            miniActions={filterButton()}
            sideBar={<Filters filterType={filterType} />}>
            {searchCountState?.isSearch?.user && (
              <Wrapper className="editsearch-container-mobile">
                <SearchListHeader pageType={filterType} />
              </Wrapper>
            )}
            <UserListHeader />

            <Loader
              loading={filterError.isError ? false : loading}
              error={error.isError}
              errorMessage={filterError.message || error.message}
              errorCode={error.code}
              className="todaylist-loader"
              height="calc(100vh - 170px)"
              noData={
                !serviceUserDetails.pagedResults ||
                _.keys(serviceUserDetails.pagedResults).length < 1
              }>
              <InfiniteScroll
                className="case-list-scroll smothscroll scrollbar"
                dataLength={_.keys(serviceUserDetails.pagedResults).length || 0}
                next={fetchMoreData}
                hasMore={
                  _.keys(serviceUserDetails.pagedResults).length <
                  serviceUserDetails.totalCount
                }
                scrollThreshold={0.9}
                loader={<Spinner size={48} />}
                height="calc(100vh - 170px)"
                width="100%">
                <Wrapper tabIndex={0}>
                  <ServiceUserDetails data={serviceUserDetails.pagedResults} />
                </Wrapper>
              </InfiniteScroll>
            </Loader>
          </Page>
        </Wrapper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

ServiceUserList.defaultProps = {
  filterType: "usersList"
};
ServiceUserList.propTypes = {
  filterType: PropTypes.string
};

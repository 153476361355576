import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import { apiCall, apiException } from "../../services/api";
import { updateSafeGuarding } from "../safeguarding/logic";
import { updateTesting } from "../testing/logic";
import { updateCare } from "../care/logic";
import { updatePartnerTracing } from "../partnertracing/logic";
import {
  apiErrorObj,
  PROCESS_PRIORITY,
  STATUS_PROPRITY
} from "../../constants/defaults";

const CASE_VIEW = "CASE_VIEW";
const CASE_VIEW_SUCCESS = "CASE_VIEW_SUCCESS";
const CASE_VIEW_ERROR = "CASE_VIEW_ERROR";
const CASE_VIEW_UPDATE = "CASE_VIEW_UPDATE";
const CASE_VIEW_RESET = "CASE_VIEW_RESET";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: [],
  flag: false
};

function* caseViewGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `Cases/${action.payload.caseId}`
    });
    let safeguarding = null;
    let testing = null;
    let care = null;
    let partnertracing = null;
    if (response && response.processes) {
      response.processes.forEach((item) => {
        if (item?.processType.toLocaleLowerCase().includes("safeguarding")) {
          safeguarding = item;
        } else if (item?.processType.toLocaleLowerCase().includes("testing")) {
          testing = item;
        } else if (item?.processType.toLocaleLowerCase().includes("care")) {
          care = item;
        } else if (
          item?.processType.toLocaleLowerCase().includes("partnertracing")
        ) {
          partnertracing = item;
        }
      });
    }
    yield put({
      type: CASE_VIEW_SUCCESS,
      payload: response
    });
    yield put(updateSafeGuarding(safeguarding));
    yield put(updateTesting(testing));
    yield put(updateCare(care));
    yield put(updatePartnerTracing(partnertracing));
  } catch (e) {
    yield put(
      apiException({
        type: CASE_VIEW_ERROR,
        code: e.code || e,
        message: e.message || "CaseView-Fail"
      })
    );
  }
}

const sortbyPriority = (response) => {
  const priorityProcess = [];
  const newProcess = [];
  PROCESS_PRIORITY.forEach((item) => {
    response?.processes?.forEach((proc) => {
      if (proc.processType.toLocaleLowerCase().includes(item)) {
        priorityProcess.push(proc);
      }
    });
  });
  STATUS_PROPRITY.forEach((status) => {
    priorityProcess.forEach((item) => {
      if (item.status === status) {
        newProcess.push(item);
      }
    });
  });
  const merged = _.unionBy(newProcess, priorityProcess, "processType");
  response.processes = merged;
  return response;
};

export const getCaseView = (payload) => ({
  type: CASE_VIEW,
  payload
});

export const updateCaseView = (payload) => ({
  type: CASE_VIEW_UPDATE,
  payload
});

export const resetCaseView = (payload) => ({
  type: CASE_VIEW_RESET,
  payload
});

export function* caseViewGETAction() {
  yield takeLatest([CASE_VIEW], caseViewGet);
}

export const caseHeaderReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CASE_VIEW: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CASE_VIEW_SUCCESS: {
      const newResponse = sortbyPriority(action.payload);
      return {
        ...state,
        loading: false,
        data: newResponse,
        flag: true
      };
    }

    case CASE_VIEW_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case CASE_VIEW_RESET: {
      return {
        ...state,
        loading: true,
        data: [],
        error: apiErrorObj,
        flag: false
      };
    }
    case CASE_VIEW_UPDATE: {
      const tmpCase = { ...state };
      tmpCase.data = {
        ...tmpCase.data,
        ...action.payload
      };
      const newProcess = sortbyPriority(tmpCase.data);
      return {
        ...state,
        data: {
          ...state?.data,
          ...newProcess
        }
      }
    }
    default:
      return state;
  }
};

import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const CASE_HISTORY = "CASE_HISTORY";
const CASE_HISTORY_SUCCESS = "CASE_HISTORY_SUCCESS";
const CASE_HISTORY_ERROR = "CASE_HISTORY_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  flag: false,
  data: {}
};

let screenResult = [];
let screenReference = [];

export function* filterAndFetchResultSummary(screenReferences) {
  if (screenReferences.length > 0) {
    let isError = false;

    try {
      const response = yield all([
        ..._.map(screenReferences, (sr) =>
          apiCall({
            apiType: "Laboratory",
            url: `screens/${sr}/results`
          })
            .then((res) => ({ [sr]: res?.screen }))
            .catch((e) => {
              isError = e;
              return { [sr]: [] };
            })
        )
      ]);
      if (isError) {
        throw isError;
      }
      return response;
    } catch (e) {
      const err = { code: e, message: "Caselist-ResultSummary-Fail" };
      throw err;
    }
  }
  return null;
}

function* caseHistoryGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "clinicalcases",
      url: action.payload.serviceprovider
        ? `serviceusers/${action.payload.ServiceUserReference}/caseHistory?serviceProviders=${action.payload.serviceprovider}`
        : `serviceusers/${action.payload.ServiceUserReference}/caseHistory`
    });

    let tmpScreenReference = [];
    if (response?.caseHistories) {
      response?.caseHistories.forEach((item) => {
        if (item?.tasks?.length) {
          item?.tasks.forEach((subitem) => {
            if (subitem?.screenReferences?.length) {
              tmpScreenReference = [
                ...tmpScreenReference,
                ...subitem?.screenReferences
              ];
            }
          });
        }
      });
    }

    const differenceScreenReference = tmpScreenReference.filter(
      (x) => !screenReference.includes(x)
    );
    screenReference = _.uniq([...screenReference, ...tmpScreenReference]);
    if (differenceScreenReference.length) {
      const resultSummary = yield filterAndFetchResultSummary(
        differenceScreenReference
      );
      screenResult = [...screenResult, ...resultSummary];
    }

    if (response?.caseHistories) {
      response?.caseHistories.forEach((item) => {
        if (item?.tasks?.length) {
          item?.tasks.forEach((subitem) => {
            if (subitem?.screenReferences?.length) {
              const tmpData = [];
              subitem?.screenReferences?.forEach((ref) => {
                screenResult.forEach((screen) => {
                  if (screen[ref]) {
                    tmpData.push(screen[ref]);
                  }
                });
              });
              subitem.screenReferences = tmpData;
            }
          });
        }
      });
    }

    yield put({
      type: CASE_HISTORY_SUCCESS,
      payload: {
        ...response,
        ServiceUserReference: action.payload.ServiceUserReference
      }
    });
  } catch (e) {
    yield put(
      apiException({
        type: CASE_HISTORY_ERROR,
        code: e.code || e,
        message: e.message || "CaseHistory-Fail"
      })
    );
  }
}

export const getcaseHistory = (payload) => ({
  type: CASE_HISTORY,
  payload
});

export function* caseHistoryGETAction() {
  yield takeLatest([CASE_HISTORY], caseHistoryGet);
}

export const caseHistoryReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CASE_HISTORY: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CASE_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        flag: true
      };
    }

    case CASE_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    default:
      return state;
  }
};

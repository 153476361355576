import {
  Button,
  Loader,
  MessageTile,
  PrevNavIcon,
  SidebarMessage,
  Spinner,
  TabButtons,
  translate,
  Typography,
  Wrapper
} from "preventx-storybook";

import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useWindow } from "../../components/hooks";
import {
  getAllMessages,
  getMessageMore,
  getTodoMessages,
  resetMessage
} from "../../store/message/logic";
import { replyThread } from "../../utils/helper";
import { mainInfiniteScrollHeight } from "../../utils/utilsFunction";

export const MessageList = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [isMobile, setMobile] = useState(false);
  const messageState = useSelector((state) => state.messageReducer);
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (index) => setActiveTab(index);

  const tabNames = [
    { name: "to-do", count: messageState?.todo?.data?.totalCount },
    { name: "all" }
  ];

  const calculateWidth = () => {
    if (window.innerWidth < 768 && !isMobile) {
      setMobile(true);
      setActive(-1);
    } else if (window.innerWidth >= 768 && isMobile) {
      setActive(0);
      setMobile(false);
    }
  };

  useEffect(() => {
    dispatch(getTodoMessages());
    dispatch(getAllMessages());

    calculateWidth();
    return () => {
      dispatch(resetMessage());
    };
  }, []);

  const onTileClick = (index) => {
    setActive(index);
  };

  const fetchMoreData = () => {
    dispatch(getMessageMore({ filters: activeTab === 0 }));
  };

  useWindow(calculateWidth, [window.innerWidth]);

  const serviceUserHandler = (suReference) => {
    window.open(`users/${suReference}`, "_blank");
  };
  const { t } = translate();
  const baseClass = "message-list-container";
  return (
    <Wrapper className={baseClass} fw>
      <Wrapper
        pl={24}
        pr={24}
        pt={16}
        pb={16}
        bb={1}
        borderColor="greyscale-grey-5"
        flex
        align="center">
        <Typography size={24} weight="medium" mr="5" variant="h1">
          Messages
        </Typography>
        {!messageState[activeTab === 0 ? "todo" : "all"]?.loading &&
        messageState?.todo?.data?.totalCount ? (
          <>
            <Typography size={24} weight="medium" translate={false} mr="5">
              ({messageState?.todo?.data?.totalCount})
            </Typography>
            <Typography size={24} weight="medium">
              message-action-required
            </Typography>
          </>
        ) : (
          ""
        )}
      </Wrapper>

      <Wrapper className={`${baseClass}__content scrollbar`} p={16}>
        <Wrapper flex>
          <Wrapper
            mr={24}
            className={active > -1 && isMobile ? "hide-cotentlist" : ""}>
            <Wrapper mb={10}>
              <TabButtons
                tabNames={tabNames}
                activeTab={activeTab}
                handler={toggleTab}
              />
            </Wrapper>

            <InfiniteScroll
              className="smothscroll scrollbar"
              dataLength={
                !messageState ||
                messageState[activeTab === 0 ? "todo" : "all"]?.data
                  ?.pagedResults?.length ||
                0
              }
              next={fetchMoreData}
              hasMore={
                messageState[activeTab === 0 ? "todo" : "all"]?.data
                  ?.pagedResults?.length <
                messageState[activeTab === 0 ? "todo" : "all"]?.data?.totalCount
              }
              scrollThreshold={0.9}
              loader={<Spinner size={48} />}
              height="calc(100vh - 222px)"
              width="100%">
              <Wrapper tabIndex={0} fw>
                <MessageTile
                  isLoading={
                    messageState[activeTab === 0 ? "todo" : "all"]?.loading
                  }
                  error={
                    messageState[activeTab === 0 ? "todo" : "all"]?.error
                      ?.isError ||
                    messageState[activeTab === 0 ? "todo" : "all"]?.data
                      ?.pagedResults?.length < 1
                  }
                  errorMessage={
                    messageState[activeTab === 0 ? "todo" : "all"]?.error
                      ?.message ||
                    messageState[activeTab === 0 ? "todo" : "all"]?.data
                      ?.pagedResults?.length < 1
                      ? t("no-messages")
                      : ""
                  }
                  inputModel={
                    messageState[activeTab === 0 ? "todo" : "all"]?.data
                      ?.pagedResults
                  }
                  active={active}
                  onHandler={onTileClick}
                />
              </Wrapper>
            </InfiniteScroll>
          </Wrapper>
          <Wrapper
            Wrapper
            tabIndex={0}
            className={`scrollbar ${active > -1 && isMobile && "show-cotent"} ${
              active === -1 && isMobile && "hide-content"
            }`}
            fw
            style={{ height: mainInfiniteScrollHeight(), overflow: "auto" }}>
            {isMobile && (
              <Button
                variant="tertiary"
                icon={<PrevNavIcon />}
                placement="left"
                handler={() => {
                  setActive(-1);
                }}>
                Back
              </Button>
            )}
            <Loader
              loading={messageState[activeTab === 0 ? "todo" : "all"]?.loading}
              error={
                messageState[activeTab === 0 ? "todo" : "all"]?.error.isError
              }
              size={48}
              className="message-loader"
              height="70vh"
              noData={
                !messageState[activeTab === 0 ? "todo" : "all"]?.data
                  ?.pagedResults?.length
              }>
              {messageState[activeTab === 0 ? "todo" : "all"]?.data
                ?.pagedResults?.length
                ? messageState[
                    activeTab === 0 ? "todo" : "all"
                  ]?.data?.pagedResults.map((item, index) => (
                    <Wrapper
                      className={`hide-detail ${
                        active === index && "show-detail"
                      }`}>
                      <SidebarMessage
                        serviceUserHandler={serviceUserHandler}
                        key={`sidebar-msg-${index + 1}`}
                        inputModel={item}
                        handlerReply={replyThread}
                      />
                    </Wrapper>
                  ))
                : ""}
            </Loader>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

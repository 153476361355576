import produce from "immer";
import { TOGGLE_BACKDROP } from "./actionType";

const initialState = {
  showBackdrop: false
};

export const backDropReducer = (state = initialState, action = null) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_BACKDROP:
        draft.showBackdrop =
          action.isShow !== draft.showBackdrop
            ? action.isShow
            : draft.showBackdrop;
        break;

      default:
        break;
    }
  });

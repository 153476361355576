import { Loader, Spinner, TabButtons, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  getNotificationsMore,
  markAllAsReadNotification,
  resetNotification
} from "../../../store/userNotifications/actionCreator";
import {
  getSubscriptions,
  toggleNotificationSettings
} from "../../../store/userSubscription/actionCreator";
import { useClickOutside } from "../../hooks";
import {
  NotificationCard,
  NotificationDisabled,
  NotificationEndScroll,
  NotificationUpdated
} from "./NotificationComponents";
import { NotificationFooter } from "./NotificationFooter";
import { NotificationHeader } from "./NotificationHeader";
import { NotificationCases, NotificationFeature } from "./NotificationMessages";
import { NotificationSettings } from "./NotificationSettings";

export const Notification = ({ openHandler, isOpen }) => {
  const [notificationDisable, setNotificationDisable] = useState(false);
  const dispatch = useDispatch();
  const node = useRef();

  useClickOutside(node, openHandler, isOpen);

  const { subscriptions } = useSelector(
    (state) => state.userSubscriptionsReducer
  );

  const userNotificationData = useSelector(
    (state) => state.userNotificationReducer
  );

  const { messages } = useSelector((state) => state.userNotificationReducer);

  const { unRead, read } = messages;

  const tabNames = [
    { name: "Unread", count: unRead?.totalCount },
    { name: "Read", count: read?.totalCount }
  ];

  const { openSettings } = useSelector((state) => state.toggleSettingsReducer);

  /* fetch more data on lazy loading */
  const fetchMoreUnreadNotifications = () => {
    dispatch(getNotificationsMore(false));
  };

  const fetchMorereadNotifications = () => {
    dispatch(getNotificationsMore(true));
  };

  /* Mark All asread Message functionality */
  const markReadAllFunc = () => {
    dispatch(markAllAsReadNotification());
  };

  /* openning up of notification settings component */

  const openSettingsFunc = () => {
    dispatch(toggleNotificationSettings(!openSettings));
    dispatch(resetNotification());
    dispatch(getNotifications(false));
    dispatch(getNotifications(true));
  };

  /* notification tab */

  const [activeTab, setActiveTab] = useState(0);
  const selectedTab = (index) => setActiveTab(index);

  /* Function for notification Disabled */
  const notificationDisabledFunc = () => {
    const result = subscriptions.every(
      (subscription) => subscription.isMuted === true
    );
    setNotificationDisable(result);
  };

  useEffect(() => {
    dispatch(getNotifications(false));
    dispatch(getNotifications(true));
    dispatch(getSubscriptions());

    return () => {
      dispatch(resetNotification());
    };
  }, []);

  useEffect(() => {
    notificationDisabledFunc();
  }, [subscriptions]);

  return (
    <NotificationCard className="notification-card" elemRef={node}>
      {openSettings && (
        <NotificationCard>
          <NotificationSettings handler={openSettingsFunc} />
        </NotificationCard>
      )}
      {!openSettings && (
        <div>
          <div className="notification-card__header-tab">
            <NotificationHeader
              title="Notifications"
              settingsIcon
              handler={openSettingsFunc}
            />
            <Wrapper
              flex
              style={{ flexDirection: "column" }}
              className="notification-card__tab">
              <TabButtons
                tabNames={tabNames}
                handler={selectedTab}
                activeTab={activeTab}
              />
            </Wrapper>
            {notificationDisable ? (
              <div className="notification-card__disabled">
                <NotificationDisabled />
              </div>
            ) : null}
          </div>

          <div className="notification-card__body">
            <Wrapper
              className={
                notificationDisable
                  ? "notification-card__messages--active"
                  : "notification-card__messages"
              }
              tabIndex={0}>
              {activeTab === 0 && unRead?.totalCount !== 0 && (
                <Loader
                  loading={userNotificationData?.loading}
                  error={userNotificationData?.error?.isError}
                  errorCode={userNotificationData?.error?.code}
                  errorMessage={userNotificationData?.error?.message}
                  size={48}
                  className="todaylist-loader"
                  height="70vh">
                  <InfiniteScroll
                    className="tablelist-scroll smothscroll scrollbar"
                    scrollThreshold={0.9}
                    height="calc(100vh - 290px)"
                    width="100%"
                    next={fetchMoreUnreadNotifications}
                    loader={<Spinner size={48} />}
                    dataLength={unRead?.pagedResults?.length || 0}
                    hasMore={unRead?.pagedResults?.length < unRead?.totalCount}>
                    {activeTab === 0 &&
                      unRead?.pagedResults?.map((message, index) => {
                        const { subscriptionCode } = message;
                        return (
                          <Wrapper key={`unread-${index + 1}`}>
                            {subscriptionCode ===
                              "ClinicalCaseFollowingSubscription" && (
                              <NotificationCases
                                {...message}
                                markAsRead
                                caseType="Case"
                              />
                            )}
                            {subscriptionCode === "MessagesSubscription" && (
                              <NotificationCases
                                {...message}
                                markAsRead
                                caseType="Message"
                              />
                            )}
                            {subscriptionCode ===
                              "FeatureUpdatesSubscription" && (
                              <NotificationFeature {...message} markAsRead />
                            )}
                          </Wrapper>
                        );
                      })}

                    {activeTab === 0 && <NotificationEndScroll />}
                  </InfiniteScroll>
                </Loader>
              )}

              {activeTab === 1 && read?.totalCount !== 0 && (
                <Loader
                  loading={userNotificationData?.loading}
                  size={48}
                  className="todaylist-loader"
                  height="70vh">
                  <InfiniteScroll
                    className="tablelist-scroll smothscroll scrollbar"
                    scrollThreshold={0.9}
                    height="calc(100vh - 240px)"
                    width="100%"
                    next={fetchMorereadNotifications}
                    loader={<Spinner size={48} />}
                    dataLength={read?.pagedResults?.length || 0}
                    hasMore={read?.pagedResults?.length < read?.totalCount}>
                    {activeTab === 1 &&
                      read?.pagedResults?.map((message, index) => {
                        const { subscriptionCode } = message;
                        return (
                          <Wrapper key={`read-${index + 1}`}>
                            {subscriptionCode ===
                              "ClinicalCaseFollowingSubscription" && (
                              <NotificationCases {...message} caseType="Case" />
                            )}
                            {subscriptionCode === "MessagesSubscription" && (
                              <NotificationCases
                                {...message}
                                caseType="Message"
                              />
                            )}
                            {subscriptionCode ===
                              "FeatureUpdatesSubscription" && (
                              <NotificationFeature {...message} />
                            )}
                          </Wrapper>
                        );
                      })}
                    {activeTab === 1 && <NotificationEndScroll />}
                  </InfiniteScroll>
                </Loader>
              )}

              {activeTab === 0 && unRead?.totalCount === 0 && (
                <NotificationUpdated unRead />
              )}
              {activeTab === 1 && read?.totalCount === 0 && (
                <NotificationUpdated read />
              )}
            </Wrapper>
          </div>

          {activeTab === 0 && unRead?.totalCount !== 0 && (
            <NotificationFooter
              overrideClass="active-btn"
              title="Mark all as read"
              handler={markReadAllFunc}
            />
          )}
        </div>
      )}
    </NotificationCard>
  );
};
Notification.defaultProps = {
  notificationFooter: "",
  noNotificationFooter: ""
};
Notification.propTypes = {
  notificationFooter: PropTypes.string,
  noNotificationFooter: PropTypes.string,
  openHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.string.isRequired
};

import {
  Divider,
  Loader,
  SidebarAccountCard,
  SidebarProfileCard,
  Wrapper
} from "preventx-storybook";
import React from "react";
import { useSelector } from "react-redux";
import { RightMenuLink, RightMenuPanel } from "..";
import { USER_LIST } from "../../../constants/paths";
import { calcScrollHeight } from "../../../utils/utilsFunction";

export const Profile = () => {
  const serviceUserState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);

  return (
    <RightMenuPanel
      title="User detail"
      action={
        !serviceUserState.loading &&
        !serviceUserState.error.isError && (
          <RightMenuLink
            title="View full profile"
            path={`${USER_LIST}/${caseHeaderState?.data?.serviceUserReference}`}
            className="rightPanel-userprofile"
          />
        )
      }>
      <Wrapper>
        <Divider absolute />
      </Wrapper>
      <Wrapper
        pt={16}
        className="profilescrollbar scrollbar"
        tabIndex={0}
        style={{ height: calcScrollHeight("userDetail") }}>
        <Loader
          loading={serviceUserState.loading || serviceUserState.reloading}
          error={serviceUserState.error.isError}
          errorCode={serviceUserState?.error?.code}
          errorMessage={{
            404: "Service user record not found"
          }}>
          <SidebarProfileCard
            inputModel={serviceUserState?.data}
            title="Profile"
            mb={16}
          />
          <SidebarAccountCard
            inputModel={serviceUserState?.data}
            title="Account details"
          />
        </Loader>
      </Wrapper>
    </RightMenuPanel>
  );
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    knownAuthorities: process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES?.split(","),
    redirectUri: process.env.REACT_APP_ADB2C_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: process.env.REACT_APP_ADB2C_SCOPES?.split(",") ?? []
};

export const logoutRequest = {
  postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

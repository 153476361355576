import { put, take, all, call } from "redux-saga/effects";

// Constants
import {
  GET_FILTER_COUNT_SETTING_SUCCESS,
  GET_SERVICE_PROVIDER_SETTING_REQUEST,
  GET_SERVICE_PROVIDER_SETTING_SUCCESS
} from "./actionType";

// API
import { apiCall } from "../../services/api";

function* serviceProviderSettingsGet(serviceProviderReference) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "serviceproviders",
      url: `serviceproviders/${serviceProviderReference}/setting/CUPSettings`
    });

    const settings = response?.value && JSON.parse(response.value);
    yield put({
      type: GET_SERVICE_PROVIDER_SETTING_SUCCESS,
      payload: {
        [serviceProviderReference]: settings
      }
    });
  } catch (ex) {
    console.error(ex);
  }
}

function* filterCountSettingsGet() {
  try {
    const response = yield apiCall({
      method: "get",
      url: "worklists/filtercounts"
    });

    yield put({
      type: GET_FILTER_COUNT_SETTING_SUCCESS,
      payload: response
    });
  } catch (ex) {
    console.error(ex);
  }
}

export function* serviceProviderSettingsGetAction() {
  const action = yield take(GET_SERVICE_PROVIDER_SETTING_REQUEST);

  yield call(filterCountSettingsGet);

  yield all(
    action.payload.map((serviceProviderReference) =>
      call(serviceProviderSettingsGet, serviceProviderReference)
    )
  );
}

import { Typography, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptions,
  muteSubscription,
  unMuteSubscription
} from "../../../../store/userSubscription/actionCreator";
import { NotifySettingsHeader } from "./NotifySettingsHeader";
import { NotifyToggle } from "./NotifyToggle";

export const NotificationSettings = ({ handler }) => {
  const dispatch = useDispatch();

  const { subscriptions } = useSelector(
    (state) => state.userSubscriptionsReducer
  );

  useEffect(() => {
    if (subscriptions.length < 1) {
      dispatch(getSubscriptions());
    }
  }, []);
  const switchFunc = (e) => {
    const {
      target: { value, checked }
    } = e;
    const unChecked = !checked;
    if (unChecked) {
      dispatch(muteSubscription(value, unChecked, subscriptions));
    } else {
      dispatch(unMuteSubscription(value, unChecked, subscriptions));
    }
  };

  return (
    <Wrapper className="notification-settings">
      <NotifySettingsHeader handler={handler} />
      <Wrapper className="notification-settings__text">
        <Typography>notification-settings-header</Typography>
      </Wrapper>

      {subscriptions.map(({ code, isMuted }) => (
        <NotifyToggle
          icon={code}
          title={code}
          id={code}
          key={code}
          checked={isMuted}
          handler={switchFunc}
        />
      ))}
    </Wrapper>
  );
};

NotificationSettings.propTypes = {
  handler: PropTypes.func.isRequired
};

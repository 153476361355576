import { CloseIcon, Typography, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";

export const NotifySettingsHeader = ({ handler }) => (
  <Wrapper
    flex
    align="center"
    justify="space-between"
    className="notify-header-settings">
    <Typography>settings</Typography>
    <Wrapper className="notify-header-settings__icon" handler={handler}>
      <CloseIcon />
    </Wrapper>
  </Wrapper>
);

NotifySettingsHeader.propTypes = {
  handler: PropTypes.func.isRequired
};

import produce from "immer";
import { apiErrorObj } from "../../constants/defaults";
import {
  CLEAR_FILTERS_FOR_SEARCH_FORM,
  FILTERS_SELECTED,
  FILTER_LIST,
  FILTER_LIST_ERROR,
  FILTER_LIST_SUCCESS,
  GET_FILTER_COUNTS,
  SET_DEFAULT_COLLAPSED,
  SET_DEFAULT_FILTERS,
  SET_FILTER_COLLAPSE,
  SET_FILTER_COUNTS,
  SET_FILTER_COUNTS_ERROR,
  SET_TOGGLE_SIDEBAR
} from "./actionType";

const initialState = {
  loading: true,

  isFilterOpen: false,
  caseList: {
    error: apiErrorObj,
    filters: [],
    currentFilters: [],
    collapsedList: {},
    defaultChecked: [
      "CaseStatusUpcoming",
      "CaseStatusDue",
      "CaseStatusOverDue"
    ],
    defaultCollapsed: ["CaseStatus", "Open"],
    filterCounts: {},
    filterCountsError: apiErrorObj,
    filterCountLoading: false
  },

  workList: {
    error: apiErrorObj,
    filters: [],
    currentFilters: [],
    collapsedList: {},
    defaultChecked: [],
    defaultCollapsed: [],
    filterCounts: {},
    filterCountsError: apiErrorObj,
    filterCountLoading: false
  },
  usersList: {
    error: apiErrorObj,
    filters: [],
    currentFilters: [],
    collapsedList: {},
    defaultChecked: [],
    defaultCollapsed: [],
    filterCounts: {},
    filterCountsError: apiErrorObj,
    filterCountLoading: false
  }
};

export const filterListReducer = (state = initialState, action = null) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILTER_LIST:
        draft.loading = true;
        draft[action.filterType].error = apiErrorObj;
        draft[action.filterType].filterCountsError = apiErrorObj;

        break;

      case GET_FILTER_COUNTS:
        draft[action.filterType].filterCountLoading = true;
        draft[action.filterType].filterCountsError = apiErrorObj;
        break;

      case SET_FILTER_COUNTS:
        draft[action.filterType].filterCountLoading = false;
        draft[action.filterType].filterCounts = action.counts;
        break;

      case SET_FILTER_COUNTS_ERROR:
        draft[action.filterType].filterCountLoading = false;
        draft[action.filterType].filterCountsError.isError = true;
        draft[action.filterType].filterCountsError.message = action.message;
        draft[action.filterType].filterCountsError.code = action.code;
        break;

      case FILTER_LIST_SUCCESS:
        draft.loading = false;
        draft[action.filterType].filters = action.treeState;
        break;

      case FILTER_LIST_ERROR:
        draft.loading = false;
        draft[action.filterType].filters = [];
        draft[action.filterType].filterCounts = {};
        draft[action.filterType].filterCountLoading = false;
        draft[action.filterType].error.isError = true;
        draft[action.filterType].error.message = action.message;
        draft[action.filterType].error.code = action.code;
        break;

      case FILTERS_SELECTED:
        draft[action.data.filterType].currentFilters = action.data.filters;
        break;

      case SET_DEFAULT_FILTERS:
        draft[action.filterType].defaultChecked = action.defaultFilters;
        break;

      case SET_DEFAULT_COLLAPSED:
        draft[action.filterType].defaultCollapsed = action.defaultCollapsed;
        break;

      case SET_FILTER_COLLAPSE:
        draft[action.filterType].collapsedList = action.collapsedList;
        break;

      case SET_TOGGLE_SIDEBAR:
        draft.isFilterOpen = action.isOpen;
        break;

      case CLEAR_FILTERS_FOR_SEARCH_FORM:
        draft[action.filterType].filters = action.filters;
        draft[action.filterType].currentFilters = [];
        draft[action.filterType].defaultChecked = [];
        draft[action.filterType].defaultCollapsed = [];
        draft[action.filterType].collapsedList = {};
        draft[action.filterType].filterCounts = {};
        draft[action.filterType].filterCountsError = apiErrorObj;
        draft[action.filterType].filterCountLoading = false;

        break;

      default:
        break;
    }
  });

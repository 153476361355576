import {
  ServiceUserPage,
  TriageReport,
  TodaysList,
  CaseList,
  CaseView,
  ServiceUserList,
  NotFoundPage,
  LabReportContainer
} from "../pages";
import { MessageList } from "../pages/Messages/MessageList";
import * as PATH from "./paths";

const routes = [
  {
    isExact: true,
    path: PATH.ROOT,
    component: () => {
      window.location.replace(PATH.TODAY_LIST);
    },
    title: "today-list"
  },
  {
    isExact: true,
    path: PATH.CASE_LIST,
    component: CaseList,
    title: "case-list"
  },
  {
    isExact: true,
    path: PATH.MESSAGE_LIST,
    component: MessageList,
    title: "Messages"
  },
  {
    isExact: false,
    path: PATH.CASE_VIEW,
    component: CaseView,
    title: "case-detail"
  },
  {
    isExact: true,
    path: PATH.TODAY_LIST,
    component: TodaysList,
    title: "today-list"
  },

  {
    isExact: true,
    path: PATH.USER_LIST,
    component: ServiceUserList,
    title: "user-list"
  },
  {
    isExact: false,
    path: PATH.USER_VIEW,
    component: ServiceUserPage,
    title: "user-detail"
  },
  {
    isExact: true,
    path: PATH.TRIAGEREPORT_PAGE,
    component: TriageReport,
    title: "triage-report"
  },
  {
    isExact: true,
    path: PATH.LAB_REPORT,
    component: LabReportContainer,
    title: "lab-report"
  },
  {
    isExact: false,
    path: "*",
    component: NotFoundPage,
    title: "page-not-found"
  }
];

export default routes;

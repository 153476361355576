module.exports = {
  HasChlamydia: "Chlamydia",
  HasChlamydiaRectal: "Chlamydia Rectal",
  HasChlamydiaUrine: "Chlamydia Urine",
  HasChlamydiaVaginal: "Chlamydia Vaginal",
  HasChlamydiaPharyngeal: "Chlamydia Pharyngeal",
  HasGonorrhoea: "Gonorrhoea",
  HasGonorrhoeaPharyngeal: "Gonorrhoea Pharyngeal",
  HasGonorrhoeaRectal: "Gonorrhoea Rectal",
  HasGonorrhoeaVaginal: "Gonorrhoea Vaginal",
  HasHepatitisB: "Hepatitis B",
  HasHepatitisC: "Hepatitis C",
  HasHIV: "HIV",
  HasMycoplasmaGenitalium: "Mycoplasma Genitalium",
  HasMycoplasmaGentialium: "Mycoplasma Genitalium",
  HasSyphilis: "Syphilis",
  HasSyphilisAntibody: "Syphilis Antibody",
  HasSyphilisAntiGen: "Syphilis AntiGen",
  HasTrichomoniasis: "Trichomoniasis",
  CaseStatus: "Case Status",
  Open: "Open",
  CaseStatusUpcoming: "Upcoming",
  CaseStatusDue: "Due",
  CaseStatusOverDue: "Overdue",
  CaseStatusClosed: "Closed",
  Following: "Following",
  FollowingMyCases: "Following my cases",
  Testing: "Testing",
  BbvReactive: "BBV reactive",
  HasSyphilis1: "Syphilis 1",
  HasSyphilis2: "Syphilis 2",
  NoneBbvPositive: "None-BBV positive",
  AllOther: "All other",
  KitOrderHeld: "Kit order held",
  NegativeResults: "Negative results",
  OrderInProcess: "Order in process",
  Safeguarding: "Safeguarding triggers",
  TriageFlags: "Triage flags",
  SexualAssault: "Sexual assault",
  SexWorker: "Sex worker",
  PartnerAgeOrPower: "Partner age or power",
  PartnerAge: "Partner age",
  PartnerPower: "Partner power",
  CoercedSex: "Coerced sex",
  Uncomfortable: "Uncomfortable",
  GiftsMoneySex: "Gifts money sex",
  Sexting: "Sexting",
  AgenciesOrServices: "Agencies or services",
  SelfHarm: "Self harm",
  DrugAlcoholUser: "Drug alcohol User",
  AlcoholUser: "Alcohol user",
  DrugUser: "Drug user",
  FemaleGenitalMutilation: "Female genital mutilation",
  DomesticAbuseViolence: "Domestic abuse violence",
  PartnerTracing: "Partner tracing status",
  PartnerTracingNotStarted: "Not started",
  PartnerTracingInProgress: "In progress",
  PartnerTracingCompleted: "Completed",
  Care: "Care outcomes",
  CareNoFurtherActionRequired: "No further action required",
  CareTransferedToClinic: "Transfered to clinic",
  CareOverdue: "Overdue",
  CareClosed: "Closed",
  Services: "Services",
};

import _ from "lodash";
import { Userlist, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

export const ServiceUserDetails = ({ data }) => {
  const history = useHistory();
  return (
    <Wrapper className="Table caselist__table">
      <Wrapper className="Table__Body caselist__table__body">
        {_.keys(data).map((serviceuser, index) => (
          <Userlist
            key={`user-list-${index + 1}`}
            inputData={data[serviceuser]}
            handlerRowClick={() =>
              history.push(`/users/${data[serviceuser].ServiceUserReference}`)
            }
          />
        ))}
      </Wrapper>
    </Wrapper>
  );
};

ServiceUserDetails.defaultProps = {
  data: []
};

ServiceUserDetails.propTypes = {
  data: PropTypes.instanceOf(Object)
};

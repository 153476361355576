import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { apiCall, apiException } from "../../services/api";
import { setSubscriptions } from "./actionCreator";
import {
  GET_SUBSCRIPTIONS,
  MUTE_SUBSCRIPTION,
  SUBSCRIPTION_ERROR,
  UNMUTE_SUBSCRIPTION
} from "./actionTypes";

function* getUserSubscriptions() {
  try {
    const response = yield apiCall({
      apiType: "notifications",
      url: "Subscriptions"
    });
    yield put(setSubscriptions(response));
  } catch (e) {
    yield put(
      apiException({
        type: SUBSCRIPTION_ERROR,
        code: e.code || e,
        message: e.message || "Subscriptions-Fail"
      })
    );
  }
}

function* muteUserSubscriptions({ code, checked, data }) {
  let tempSubscriptions = _.cloneDeep(data);
  let index = tempSubscriptions.findIndex(
    (subscription) => subscription.code === code
  );
  tempSubscriptions[index].isMuted = checked;
  yield put(setSubscriptions(tempSubscriptions));

  try {
    yield apiCall({
      method: "PUT",
      apiType: "notifications",
      url: `Subscriptions/${code}/mute`
    });
  } catch (e) {
    tempSubscriptions = _.cloneDeep(data);
    index = tempSubscriptions.findIndex(
      (subscription) => subscription.code === code
    );
    tempSubscriptions[index].isMuted = !checked;
    yield put(setSubscriptions(tempSubscriptions));
    yield put(
      apiException({
        code: e.code || e,
        message: e.message || "Subscriptions-Mute-Fail"
      })
    );
  }
}

function* unMuteUserSubscription({ code, checked, data }) {
  let tempSubscriptions = _.cloneDeep(data);
  let index = tempSubscriptions.findIndex(
    (subscription) => subscription.code === code
  );
  tempSubscriptions[index].isMuted = checked;
  yield put(setSubscriptions(tempSubscriptions));

  try {
    yield apiCall({
      method: "PUT",
      apiType: "notifications",
      url: `Subscriptions/${code}/unmute`
    });
  } catch (e) {
    tempSubscriptions = _.cloneDeep(data);
    index = tempSubscriptions.findIndex(
      (subscription) => subscription.code === code
    );
    tempSubscriptions[index].isMuted = !checked;
    yield put(setSubscriptions(tempSubscriptions));
    yield put(
      apiException({
        code: e.code || e,
        message: e.message || "Subscriptions-UnMute-Fail"
      })
    );
  }
}

export function* userSubscriptionsGETAction() {
  yield takeLatest(GET_SUBSCRIPTIONS, getUserSubscriptions);
}

export function* userSubscriptionsPOSTAction() {
  yield takeLatest(MUTE_SUBSCRIPTION, muteUserSubscriptions);
}

export function* userSubscriptionPUTAction() {
  yield takeLatest(UNMUTE_SUBSCRIPTION, unMuteUserSubscription);
}

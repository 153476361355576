import React, { useState, useEffect } from "react";
import {
  GridContainer,
  GridItem,
  PanelHeader,
  Tasks,
  TestingCard,
  translate,
  Typography,
  Wrapper
} from "preventx-storybook";
import { useDispatch, useSelector } from "react-redux";
import { NewTask } from "../NewTask/NewTask";
import { resetGetTesting } from "../../../store/testing/logic";
import {
  postCreateTask,
  resetCreateTask
} from "../../../store/createTask/logic";
import { convertToDateTime } from "../../../utils/utilsFunction";
import {
  DATE_FORMAT,
  ProcessTypeTesting,
  PROCESS_DEFAULT_OPEN
} from "../../../constants/defaults";
import { getresultSummary } from "../../../store/resultsummary/logic";
import { SummaryCard } from "./SummaryCard";
import {
  EditOutcomes,
  putTaskAction
} from "../../../utils/helper";
import { getNoteByIdAndTriggerEdit } from "../../../store/actionTypes";
import useMenuSettings from "../../hooks/useMenuSettings";

export const Testing = () => {
  const { t } = translate();
  const dispatch = useDispatch();
  const testingState = useSelector((state) => state.testingReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const createTaskState = useSelector((state) => state.createTaskReducer);
  const resultSummaryState = useSelector((state) => state.resultSummaryReducer);
  const taskTypeState = useSelector((state) => state.taskTypeReducer);
  const { moreAction } = useMenuSettings();

  const [showModal, setShowModal] = useState(false);
  const [inputData, setInputData] = useState({});
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (testingState.flag) {
      testingState?.data?.screenReferences?.forEach((screenReferenceNumber) => {
        if (
          !Object.keys(resultSummaryState).length ||
          !resultSummaryState[screenReferenceNumber] ||
          (resultSummaryState[screenReferenceNumber]?.data &&
            !Object.keys(resultSummaryState[screenReferenceNumber]?.data)
              .length)
        ) {
          dispatch(
            getresultSummary({
              screenReference: screenReferenceNumber
            })
          );
        }
      });
    }
  }, [testingState.flag]);

  useEffect(() => {
    if (testingState.flag) {
      setShowModal(false);
      dispatch(resetGetTesting());
    }
  }, [testingState.flag]);

  useEffect(() => {
    if (createTaskState.flag) {
      setInputData({});
      dispatch(resetCreateTask());
      setShowModal(false);
    }
  }, [createTaskState.flag]);

  const submitForm = (obj) => {
    obj.dueDate = convertToDateTime(obj.dueDate, DATE_FORMAT, "YYYY-MM-DD");
    if (Object.keys(obj).length > 1) {
      dispatch(
        postCreateTask({
          processReference: testingState.data.processReference,
          body: {
            ...obj,
            ProcessReference: testingState.data.processReference
          },
          caseReference: caseHeaderState.data.caseReference,
          processType: ProcessTypeTesting,
          message: t("Task-Created-Message")
        })
      );
    } else {
      dispatch(
        postCreateTask({
          processReference: testingState.data.processReference,
          body: obj,
          caseReference: caseHeaderState.data.caseReference,
          taskReference: editData.taskReference,
          processType: ProcessTypeTesting,
          message: t("Task-Edit-Due-Date-Message")
        })
      );
    }
  };

  if (!testingState.data) {
    return null;
  }

  return (
    <Wrapper>
      <TestingCard
        showNewTask
        defaultOpen={PROCESS_DEFAULT_OPEN}
        className="cup-testingcard"
        moreAction={moreAction}
        maxWidth={736}
        inputModel={testingState?.data}
        handlerEditOutcome={(item) =>
          item?.caseNoteReference && EditOutcomes(item?.caseNoteReference)
        }
        handlerNewTask={() => {
          let taskTypeId = "";
          if (
            taskTypeState?.data &&
            taskTypeState?.data[ProcessTypeTesting] &&
            taskTypeState?.data[ProcessTypeTesting].length
          ) {
            taskTypeId = taskTypeState?.data[ProcessTypeTesting][0].id;
          }
          setInputData({
            taskTypeId
          });
          setEditData({});
          setShowModal(true);
        }}>
        {testingState?.data?.screenReferences &&
        testingState?.data?.screenReferences?.length ? (
          testingState?.data?.screenReferences?.map((item, i) => (
            <SummaryCard inputModel={item} key={`summary-card-${i + 1}`} />
          ))
        ) : (
          <PanelHeader>
            <GridContainer>
              <GridItem xs={12} md={12} lg={2} xlg={2}>
                <Typography color="greyscale-grey-8" className="main-title">
                  screens
                </Typography>
              </GridItem>
              <GridItem xs={12} md={12} lg={8} xlg={10}>
                <Typography variant="span">no-screens-ordered</Typography>
              </GridItem>
            </GridContainer>
          </PanelHeader>
        )}
        <Tasks
          onhandlerAttempted={(noteid) => {
            dispatch(getNoteByIdAndTriggerEdit(noteid));
          }}
          tasksData={testingState?.data && testingState?.data.tasks}
          taskApiFn={putTaskAction}
          caseReference={caseHeaderState.data.caseReference}
          processReference={testingState.data.processReference}
          taskHandler={(item, taskReference) => {
            if (item?.localisationKey?.includes("ChangeDueDate")) {
              const tmpTask = testingState.data.tasks.find(
                (subitem) => subitem.taskReference === taskReference
              );
              setShowModal(true);
              setEditData(tmpTask);
            }
          }}
        />
      </TestingCard>
      <NewTask
        toggleModal={() => {
          setShowModal(!showModal);
          setInputData({});
          setEditData({});
        }}
        showModal={showModal}
        handlerSubmit={submitForm}
        inputModel={inputData}
        editData={editData}
        onChangeHandle={(obj) => setInputData(obj)}
        handlerDueDateChange={(dt) =>
          setInputData({
            ...inputData,
            ...{
              dueDate: dt
            }
          })
        }
        processTypeCode={ProcessTypeTesting}
        isPrimaryLoading={createTaskState.loading}
      />
    </Wrapper>
  );
};

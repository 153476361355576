import { apiErrorObj } from "../../constants/defaults";
import * as actionType from "./actionTypes";

const initialState = {
  messages: {
    read: {},
    unRead: {}
  },
  loading: true,
  error: apiErrorObj,
  pageSize: 5,
  scrollposition: 0,
  overDuePage: {
    unReadOverDue: 1,
    readOverDue: 1
  }
};

export const userNotificationReducer = (
  state = initialState,
  action = null
) => {
  switch (action.type) {
    case actionType.SET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        overDuePage: {
          unReadOverDue: state.overDuePage.unReadOverDue,
          readOverDue: state.overDuePage.readOverDue
        },
        messages: {
          read: {
            ...state.messages.read,
            filter: action.status
              ? action.data.filter
              : state?.messages?.read?.filter,
            pagedResults: action.status
              ? action.data.pagedResults
              : state?.messages?.read?.pagedResults,
            totalCount: action.status
              ? action.data.totalCount
              : state?.messages?.read?.totalCount
          },
          unRead: {
            ...state.messages.unRead,
            filter: !action.status
              ? action.data.filter
              : state?.messages?.unRead?.filter,
            pagedResults: !action.status
              ? action.data.pagedResults
              : state?.messages?.unRead?.pagedResults,
            totalCount: !action.status
              ? action.data.totalCount
              : state?.messages?.unRead?.totalCount
          }
        }
      };
    case actionType.SET_NOTIFICATIONS_SUCCESS_MORE:
      return {
        ...state,
        loading: false,
        overDuePage: {
          unReadOverDue: action.overDuePage.unReadOverDue,
          readOverDue: action.overDuePage.readOverDue
        },
        messages: {
          read: {
            ...state.messages.read,
            filter: action.status
              ? action.data.filter
              : state?.messages?.read?.filter,
            pagedResults: action.status
              ? [
                  ...state.messages.read.pagedResults,
                  ...action.data.pagedResults
                ]
              : state?.messages?.read?.pagedResults,
            totalCount: action.status
              ? action.data.totalCount
              : state?.messages?.read?.totalCount
          },
          unRead: {
            ...state.messages.unRead,
            filter: !action.status
              ? action.data.filter
              : state?.messages?.unRead?.filter,
            pagedResults: !action.status
              ? [
                  ...state.messages.unRead.pagedResults,
                  ...action.data.pagedResults
                ]
              : state?.messages?.unRead?.pagedResults,
            totalCount: !action.status
              ? action.data.totalCount
              : state?.messages?.unRead?.totalCount
          }
        }
      };
    case actionType.RESET_NOTIFICATION:
      return {
        ...state,
        overDuePage: {
          unReadOverDue: 1,
          readOverDue: 1
        },
        error: apiErrorObj
      };
    case actionType.SET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    default:
      return state;
  }
};

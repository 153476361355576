import { ErrorModal, Wrapper } from "preventx-storybook";
import React from "react";
import { useHistory } from "react-router";
import * as PATH from "../constants/paths";

const baseClass = "sb-nopage";

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Wrapper
      className={baseClass}
      flex
      fw
      justify="center"
      align="center"
      style={{ background: "#EEF2F5" }}>
      <ErrorModal
        title="404"
        subTitle="Page-Not-Found"
        backHandler={() => history.replace(PATH.TODAY_LIST)}
        backText="Home-Button"
      />
    </Wrapper>
  );
};

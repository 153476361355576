import { put, select, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { apiCall, apiException } from "../../services/api";
import { apiErrorObj } from "../../constants/defaults";
import { convertToFormat } from "../../utils/utilsFunction";

const EVENT_LOG = "EVENT_LOG";
const EVENT_LOG_MORE = "EVENT_LOG_MORE";
const EVENT_LOG_SUCCESS = "EVENT_LOG_SUCCESS";
const EVENT_LOG_MORE_SUCCESS = "EVENT_LOG_MORE_SUCCESS";
const EVENT_LOG_ERROR = "EVENT_LOG_ERROR";
const EVENT_LOG_RESET = "EVENT_LOG_RESET";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {},
  pageNumber: 1
};

function* eventLogGet(action) {
  const { pageNumber } = yield select((state) => state.eventLogReducer);
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `eventlogs/screen/${action.payload.screenReference}?pageSize=10&pageNumber=${pageNumber}`
    });

    yield put({
      type: action.payload.moreLoading
        ? EVENT_LOG_MORE_SUCCESS
        : EVENT_LOG_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: EVENT_LOG_ERROR,
        code: e.code || e,
        message: e.message || "EventLog-Fail"
      })
    );
  }
}

export const geteventLog = (payload) => ({
  type: EVENT_LOG,
  payload
});

export const reseteventLog = (payload) => ({
  type: EVENT_LOG_RESET,
  payload
});

export const geteventLogMore = (payload) => ({
  type: EVENT_LOG_MORE,
  payload: { ...payload, moreLoading: true }
});

export function* eventLogGETAction() {
  yield takeLatest([EVENT_LOG, EVENT_LOG_MORE], eventLogGet);
}

const updateData = (data) => {
  const newdata = _.chain(data)
    .groupBy((ob) =>  convertToFormat(ob.created, "DD MMM YYYY"))
    .map((value, key) => ({ created: key, data: value }))
    .value();
  return newdata;
};

export const eventLogReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case EVENT_LOG: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case EVENT_LOG_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          pagedResults: action.payload.pagedResults,
          modifieddata: updateData(action.payload.pagedResults),
          ...action.payload
        },
        pageNumber: state.pageNumber + 1
      };
    }
    case EVENT_LOG_MORE_SUCCESS: {
      const newResult = [
        ...state.data.pagedResults,
        ...action.payload.pagedResults
      ];
      const modifieddata = updateData(newResult);
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          pagedResults: newResult,
          modifieddata
        },
        pageNumber: state.pageNumber + 1
      };
    }

    case EVENT_LOG_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }

    case EVENT_LOG_RESET: {
      return {
        ...state,
        loading: false,
        data: [],
        error: apiErrorObj,
        pageNumber: 1
      };
    }
    default:
      return state;
  }
};

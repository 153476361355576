import {
  Divider,
  Loader,
  Select,
  SidebarCaseHistoryCard,
  Wrapper
} from "preventx-storybook";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { RightMenuPanel } from "..";
import { getcaseHistory } from "../../../store/casehistory/logic";
import { calcScrollHeight } from "../../../utils/utilsFunction";

export const CaseHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { serviceuserReferenceId } = useParams();
  const [selectedProvider, setServiceProvider] = useState(-1);
  const caseHistoryState = useSelector((state) => state.caseHistoryReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const serviceProvidersState = useSelector(
    (state) => state.serviceProvidersReducer
  );

  useEffect(() => {
    dispatch(
      getcaseHistory({
        ServiceUserReference:
          caseHeaderState?.data?.serviceUserReference || serviceuserReferenceId
      })
    );
  }, []);

  const updateServiceProvider = (selectedpro) => {
    if (selectedpro === selectedProvider) {
      return;
    }
    setServiceProvider(selectedpro);
    dispatch(
      getcaseHistory({
        ServiceUserReference:
          caseHeaderState?.data?.serviceUserReference || serviceuserReferenceId,
        serviceprovider: selectedpro !== -1 ? selectedpro : null
      })
    );
  };

  return (
    <RightMenuPanel
      title="Case history"
      actionFullWidth
      action={
        <Select
          fullWidth
          disabled={
            serviceProvidersState.loading ||
            serviceProvidersState?.data?.length === 1
          }
          handler={updateServiceProvider}
          options={serviceProvidersState?.data}
          placeHolder="All service providers"
          selected={selectedProvider}
          className="cup-serviceprovider"
        />
      }>
      <Wrapper>
        <Divider absolute />
      </Wrapper>
      <Wrapper
        pt={16}
        tabIndex={0}
        className="casehistory__body scrollbar"
        style={{ height: calcScrollHeight("caseHistory") }}>
        <Loader
          loading={caseHistoryState.loading}
          error={caseHistoryState.error.isError}
          errorCode={caseHistoryState.error.code}
          errorMessage={caseHistoryState.error.message}
          noData={!caseHistoryState?.data?.caseHistories?.length}>
          {caseHistoryState?.data?.caseHistories?.length && (
            <SidebarCaseHistoryCard
              mb={16}
              inputModel={caseHistoryState?.data?.caseHistories}
              caseId={caseHeaderState?.data?.caseReference}
              handler={(item) => {
                if (item?.caseReference) {
                  history.push(`/case-list/${item?.caseReference}`);
                }
              }}
              serviceproviderModel={serviceProvidersState?.data}
            />
          )}
        </Loader>
      </Wrapper>
    </RightMenuPanel>
  );
};

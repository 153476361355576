import { apiErrorObj } from "../../constants/defaults";
import { menus } from "../../constants/menus";
import {
  CLEAR_NAV_CASES_COUNT_ERROR,
  GET_NAV_CASES_COUNT,
  SET_NAV_CASES_COUNT,
  SET_NAV_CASES_COUNT_ERROR,
  SET_NAV_MENUS
} from "./actiontypes";

const initialState = {
  menu: {
    ...menus
  },
  data :[],
  error: apiErrorObj,
  todayListCount: 0,
  caseListCount: 0,
  unReadMessagesCount: 0,
  freetestMeCount: 0,
  isLoading: true
};

export const navbarReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SET_NAV_MENUS:
      return {
        ...state,
        menu: { ...state.menu, ...action.menus },
        isLoading: false
      };

    case GET_NAV_CASES_COUNT:
      return { ...state,...action.data, error: apiErrorObj };

    case SET_NAV_CASES_COUNT:
      
      return { ...state, ...action.data, isLoading: false };

    case SET_NAV_CASES_COUNT_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        isLoading: false
      };

    case CLEAR_NAV_CASES_COUNT_ERROR:
      return { ...state, error: apiErrorObj };
    default:
      return state;
  }
};

import {
  GET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS,
  MUTE_SUBSCRIPTION,
  UNMUTE_SUBSCRIPTION,
  TOGGLE_SETTINGS
} from "./actionTypes";

export const getSubscriptions = () => ({ type: GET_SUBSCRIPTIONS });
export const setSubscriptions = (data) => ({ type: SET_SUBSCRIPTIONS, data });
export const muteSubscription = (code, checked, data) => ({
  type: MUTE_SUBSCRIPTION,
  code,
  checked,
  data
});
export const unMuteSubscription = (code, checked, data) => ({
  type: UNMUTE_SUBSCRIPTION,
  code,
  checked,
  data
});
export const toggleNotificationSettings = (bool) => ({
  type: TOGGLE_SETTINGS,
  bool
});

import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { translate } from "preventx-storybook";
import PropTypes from "prop-types";
import { AppLayout } from "./components/layouts";
import { IdleTimer } from "./components/atoms/IdleTime";

import "./styles/global.scss";

function App({ pca }) {
  localStorage.setItem("workListCount", 0);
  localStorage.setItem("caseListCount", 0);
  localStorage.setItem("unReadMessagesCount", 0);
  localStorage.setItem("freetestMeCount", 0);

  const { t } = translate();
  window.translate = t;
  return (
    <MsalProvider instance={pca}>
      <IdleTimer />
      <AppLayout />
    </MsalProvider>
  );
}

App.defaultProps = {
  pca: {}
};

App.propTypes = {
  pca: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object)
  ])
};

export default App;

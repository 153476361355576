import _ from "lodash";
import { all, put, select } from "redux-saga/effects";
import { apiCall, apiException, apiSuccess } from "../services/api";
import { getNoteByIdAndTriggerEdit } from "../store/actionTypes";
import { filterAndFetchCreatedUserNames } from "../store/activitylog/logic";
import { getCareProcess } from "../store/care/logic";
import { getPartnerTracingProcess } from "../store/partnertracing/logic";
import { getSafeguardingProcess } from "../store/safeguarding/logic";
import {
  serviceProvidersGet,
  SERVICE_PROVIDER_LIST_SUCCESS
} from "../store/serviceprovider/logic";
import { getuserReferenceReload } from "../store/serviceuserreference/logic";
import { getTestingProcess } from "../store/testing/logic";
import { filterAndFetchResultSummary } from "../store/todaylist/logic";
import { filterAndFetchUsers } from "../store/userdetails/logic";
import { dropdownConverter, getCookie } from "./utilsFunction";
import { ACTION_ATTEMPTED, HEPC } from "../constants/defaults";
import { forcePageRefresh } from "../store/refresh/logic";

export const getReducerName = (filterType) => {
  if (filterType === "caseList") return "caseListReducer";
  if (filterType === "workList") return "todayListReducer";
  if (filterType === "usersList") return "userDetailsReducer";
  return null;
};

export const selectNoteCategory = (
  noteCategoryOptions,
  categoryId = null,
  isLogCareOutcome
) => {
  if (isLogCareOutcome) {
    return _.find(noteCategoryOptions.updatedJson, {
      text: "Note-Category-Care"
    });
  }
  if (categoryId) {
    return _.find(noteCategoryOptions.updatedJson, {
      value: categoryId
    });
  }
  return _.find(noteCategoryOptions.updatedJson, {
    text: "Note-Category-General"
  });
};
export const selectNoteType = (
  noteTypeOptions,
  noteTypeId = null,
  isLogCareOutcome,
  portalType
) => {
  if (isLogCareOutcome) {
    if (portalType === HEPC) {
      return _.find(noteTypeOptions, {
        text: "NoteType-Care-LogHepCOutcome-Done"
      });
    }
    return _.find(noteTypeOptions, {
      text: "NoteType-Care-LogOutcome-Done"
    });
  }
  if (noteTypeId) {
    return _.find(noteTypeOptions, {
      value: noteTypeId
    });
  }
  return _.find(noteTypeOptions, {
    text: "NoteType-General-General"
  });
};

export function* getState(store = "caseListReducer", object = null) {
  return yield select((state) =>
    object ? state[store][object] : state[store]
  );
}

export function* pageStateDetails(filterType) {
  const { currentFilters, filters, defaultChecked, defaultCollapsed } =
    yield getState("filterListReducer", filterType);
  const { overDuePage, pageSize, pageNumber } = yield getState(
    getReducerName(filterType)
  );
  const { filters: formFilters } = yield getState("searchCountReducer", "data");
  const formFiltersCpy = _.cloneDeep(formFilters);
  if (formFiltersCpy?.ContactNumber) {
    formFiltersCpy.ContactNumber = `${formFiltersCpy.ContactNumber.value}${formFiltersCpy.ContactNumber.number}`;
  }

  return {
    currentFilters,
    overDuePage,
    pageSize,
    pageNumber,
    formFilters: formFiltersCpy,
    filters,
    defaultChecked,
    defaultCollapsed
  };
}

export const apiError = (isError = false, title = "", subTitle = "") => ({
  title,
  subTitle,
  isError
});

// Extract only the  serviceuser references into colleciton
export const serviceUserRefCollection = (list) =>
  _.map(list, "serviceUserReference");

export const addUserDetailsToCaseList = (response, userDetails) => {
  const pagedResults = _.clone(response?.pagedResults || []);
  const updatedlist = _.map(pagedResults, (r) => ({
    ...r,
    caseDetail: {
      ...r.caseDetail,
      serviceUserDetails: userDetails[r.caseDetail.serviceUserReference] || null
    }
  }));
  response.pagedResults = updatedlist;
  return response;
};

export const addUserDetailsToMessages = (response, userDetails) => {
  const pagedResults = _.clone(response?.pagedResults || []);
  const updatedlist = _.map(pagedResults, (r) => ({
    ...r,

    serviceUserName:
      `${userDetails[r.serviceUserReference]?.FirstName} ${
        userDetails[r.serviceUserReference]?.LastName
      }` || null
  }));
  response.pagedResults = updatedlist;
  return response;
};

const filterServiceUserReferences = (response, type) => {
  let userReferences = [];
  if (type === "caseDetails") {
    userReferences = _.map(
      response.pagedResults,
      ({ caseDetail }) => caseDetail.serviceUserReference || false
    );
  }
  if (type === "messages") {
    userReferences = _.map(
      response.pagedResults,
      (item) => item.serviceUserReference || false
    );
  }
  userReferences = _.compact(userReferences, false);
  return userReferences;
};

export function* injectUserDetails(response, type = "caseDetails") {
  const userReferences = filterServiceUserReferences(response, type);

  const serviceUserDetails = yield filterAndFetchUsers(userReferences);
  const updatedResponse =
    type === "caseDetails"
      ? addUserDetailsToCaseList(response, serviceUserDetails)
      : addUserDetailsToMessages(response, serviceUserDetails);
  return updatedResponse;
}

const mapScreenResults = (list, summary) => {
  if (list?.caseDetail?.screenReferences.length > 0) {
    const index = _.findIndex(list.tasks, {
      processTypeLocalisationKey: "ProcessType-Testing"
    });
    const tasksCpy = _.cloneDeep(list.tasks);
    if (index !== -1) {
      const updatedSummary = _.map(
        list.caseDetail.screenReferences,
        (ref) => summary[ref]
      );
      tasksCpy[index].summary = updatedSummary;
      return tasksCpy;
    }
    return list.tasks;
  }
  return list.tasks;
};
export const addResultSummaryToCaseList = (response, resultSummary) => {
  const pagedResults = _.clone(response?.pagedResults || []);
  const updatedlist = _.map(pagedResults, (list) => ({
    ...list,
    tasks: [...mapScreenResults(list, resultSummary)]
  }));

  response.pagedResults = updatedlist;

  return response;
};

export function* injectScreenResultsSummary(response) {
  let screenReferences = _.map(
    response.pagedResults,
    ({ caseDetail }) => caseDetail.screenReferences
  );
  screenReferences = _.uniq(_.flatten(screenReferences));

  const resultSummary = yield filterAndFetchResultSummary(screenReferences);
  let updatedResultSummary = {};
  _.map(resultSummary, (res) => {
    updatedResultSummary = { ...updatedResultSummary, ...res };
  });
  const updatedResponse = addResultSummaryToCaseList(
    response,
    updatedResultSummary
  );
  return updatedResponse;
}

export const addServiceProviderToSevices = (response, serviceProviders) => {
  const filters = _.cloneDeep(response || []);
  const serviceIndex = _.findIndex(filters, (f) => f.code === "Services");
  if (serviceIndex < 0) {
    return response;
  }
  const updatedServiceProviders = _.map(serviceProviders, (s) => ({
    code: s.name,
    children: []
  }));

  filters[serviceIndex].children = updatedServiceProviders;

  return filters;
};

export function* injectServiceProviderRef(response) {
  let serviceProviders = _.filter(response, { code: "Services" });
  if (serviceProviders.length < 1) {
    return response;
  }
  serviceProviders = _.map(serviceProviders[0]?.children, (s) => s.code);
  const serviceProviderList = yield serviceProvidersGet({
    payload: serviceProviders,
    isDropdown: false
  });
  const updatedResponse = addServiceProviderToSevices(
    response,
    serviceProviderList
  );
  yield put({
    type: SERVICE_PROVIDER_LIST_SUCCESS,
    payload: serviceProviderList
  });
  return updatedResponse;
}

export const updateFiltersParams = (params, serviceProvidersList) => {
  if (serviceProvidersList.length < 1) {
    return { filters: params, serviceProviders: [] };
  }
  const paramsCopy = _.cloneDeep(params);
  const updatedFilterParams = { filters: [], serviceProviders: [] };

  const serviceProvidersNames = [];
  _.forEach(serviceProvidersList, (s) => {
    if (_.includes(params, s.name)) {
      serviceProvidersNames.push(s.name);
    }
  });

  const serviceProviderCodes = [];
  _.forEach(serviceProvidersList, (s) => {
    if (_.includes(serviceProvidersNames, s.name)) {
      serviceProviderCodes.push(s.serviceProviderReference);
    }
  });

  if (serviceProviderCodes.length) {
    _.remove(paramsCopy, (p) => _.includes(serviceProvidersNames, p));
    updatedFilterParams.filters = paramsCopy;
    updatedFilterParams.serviceProviders = serviceProviderCodes;
  } else {
    updatedFilterParams.filters = paramsCopy;
  }
  return updatedFilterParams;
};

export function* injectCreatedUserNames(response) {
  let createdByUserIds = _.map(response.pagedResults, "createdByUserId");
  createdByUserIds = _.uniq(_.flatten(createdByUserIds));

  const createdUserNames = yield filterAndFetchCreatedUserNames(
    createdByUserIds
  );

  const pagedResults = _.clone(response?.pagedResults || []);
  const updatedlist = _.map(pagedResults, (list) => ({
    ...list,
    createdUserName: createdUserNames[list.createdByUserId]
  }));

  response.pagedResults = updatedlist;

  return response;
}

export const getCountryOfBirthIsoCode = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/CountryCode"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "CountryCode-Fail"
        })
      );
    });
    return [];
  }
};

export const getGenderLocalisationKey = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/Gender"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "GenderLocalisation-Fail"
        })
      );
    });
    return [];
  }
};

export const getEthnicityGroup = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/EthnicGroup"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "Ethinicity-Fail"
        })
      );
    });
    return [];
  }
};

export const getEthnicityBackground = async (ethinicgroup) => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: `claims/${ethinicgroup}`
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "EthinicityBackground-Fail"
        })
      );
    });
    return [];
  }
};

export const getGenderIdentityLocalisationKey = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/GenderIdentity"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "GenderIdentityLocalisation-Fail"
        })
      );
    });
    return [];
  }
};

export const getLanguageLocalisationKey = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/Language"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "LanguageLocalisation-Fail"
        })
      );
    });
    return [];
  }
};

export const getAssignedSexLocalisationKey = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/AssignedSex"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "AssignedSex-Fail"
        })
      );
    });
    return [];
  }
};

export const getAccountFlagsLocalisationKey = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/AccountFlags"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "AccountsFlagLocalisation-Fail"
        })
      );
    });
    return [];
  }
};

export const getContactPreferenceOrder = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/ContactPreferenceOrder"
    });
    return dropdownConverter(response);
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "ContactPreference-Fail"
        })
      );
    });
    return [];
  }
};

export const updateNoteOutcomesKey = (outcomes) => {
  const outcomesCpy = _.cloneDeep(outcomes);
  if (outcomesCpy.length > 0) {
    outcomesCpy[0].data.outcome = outcomesCpy[0].data.Outcome;
    outcomesCpy[0].data.outcomeDetails = outcomesCpy[0].data.OutcomeDetails;
    delete outcomesCpy[0].data.Outcome;
    delete outcomesCpy[0].data.OutcomeDetails;
  }
  return outcomesCpy;
};
export const putTaskAction = async (item) => {
  try {
    const response = await apiCall({
      method: "post",
      url: `tasks/${item?.taskReference}/actions/${item.verb}`
    });

    import("../store/store").then(async (res) => {
      const reducer = res?.default?.getState();
      if (
        item.taskTypeLocalisationKey === "TaskType-Safeguarding-UnlockUser" &&
        item.localisationKey.includes("Done")
      ) {
        res?.default?.dispatch(
          getuserReferenceReload({
            serviceUserReference:
              reducer?.caseHeaderReducer?.data?.serviceUserReference,
            profile: "UserDetails"
          })
        );
      }

      res?.default?.dispatch(
        getNoteByIdAndTriggerEdit(response.caseNoteReference)
      );

      res?.default?.dispatch(
        apiSuccess(
          `${item.trans(item.taskTypeLocalisationKey)} ${item.trans(
            "Marked-as"
          )} ${item.trans(item.localisationKey)} ${item.trans("Successful")}`
        )
      );
      if (item.taskTypeLocalisationKey.toLowerCase().includes("safeguarding")) {
        res?.default?.dispatch(
          getSafeguardingProcess({
            caseReference: item.caseReference,
            processReference: item.processReference
          })
        );
      } else if (
        item.taskTypeLocalisationKey.toLowerCase().includes("testing")
      ) {
        res?.default?.dispatch(
          getTestingProcess({
            caseReference: item.caseReference,
            processReference: item.processReference
          })
        );
      } else if (
        item.taskTypeLocalisationKey.toLowerCase().includes("partnertracing")
      ) {
        res?.default?.dispatch(
          getPartnerTracingProcess({
            caseReference: item.caseReference,
            processReference: item.processReference
          })
        );
      } else if (item.taskTypeLocalisationKey.toLowerCase().includes("care")) {
        res?.default?.dispatch(
          getCareProcess({
            caseReference: item.caseReference,
            processReference: item.processReference
          })
        );
      }
    });
    return response;
  } catch (e) {
    return import("../store/store").then((res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message:
            e.message ||
            `${item.trans(item.taskTypeLocalisationKey)} marked as ${item.trans(
              item.localisationKey
            )} failed`
        })
      );
    });
  }
};

export const EditOutcomes = (caseReference) => {
  try {
    import("../store/store").then((res) => {
      res?.default?.dispatch(getNoteByIdAndTriggerEdit(caseReference));
    });
  } catch (e) {
    apiException({
      code: e.code || e,
      message: e.message
    });
  }
};

export const showOutcome = (type) => {
  switch (type) {
    case "NoteType-Safeguarding-Callback-Done":
    case "NoteType-Safeguarding-ConsultationUnderage-Done":
    case "NoteType-Testing-ResultsConsultation-Done":
    case "NoteType-Testing-RecordHepBOutcome-Done":
    case "NoteType-Testing-RecordHepCOutcome-Done":
    case "NoteType-Testing-RecordHivOutcome-Done":
    case "NoteType-Testing-RecordSypOutcome-Done":
    case "NoteType-Care-LogOutcome-Done":
    case "NoteType-Care-CompleteChlamydiaPreTreatmentTriage-Done":
    case "NoteType-Care-CompleteChlamydiaPostTreatmentTriage-Done":
    case "NoteType-Care-GeneralCallback-Done":
    case "NoteType-Care-LogHepCOutcome-Done":
      return true;
    default:
      return false;
  }
};

export const getMobileNumberCode = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "serviceusers",
      url: "claims/MobileNumberCountryCode"
    });
    const newOptions = [];
    response.forEach((item) => {
      newOptions.push({
        text: `${item}-Country`,
        value: item
      });
    });
    return newOptions;
  } catch (e) {
    import("../store/store").then(async (res) => {
      res?.default?.dispatch(
        apiException({
          code: e.code || e,
          message: e.message || "MobileNumberCode-Fail"
        })
      );
    });
    return [];
  }
};

export const goToOldApp = (url, isFull = false) => {
  import("../store/store").then(async (res) => {
    res?.default?.dispatch(forcePageRefresh(true));
  });

  const authtoken = getCookie("accessToken");
  const oldAppUrl = isFull
    ? `${url}?accesstoken=${authtoken}`
    : `${process.env.REACT_APP_OLD_APP_URL}${url}?accesstoken=${authtoken}`;
  window.open(oldAppUrl, "_blank");
};

export const goToMessage = (url) => {
  import("../store/store").then(async (res) => {
    res?.default?.dispatch(forcePageRefresh(true));
  });
  const authtoken = getCookie("accessToken");
  const oldAppUrl = `${url}&accesstoken=${authtoken}`;
  window.open(oldAppUrl, "_blank");
};

let taskReferenceCount = {};
export function* taskLogGet(action) {
  const taskAttemp = [];
  try {
    if (action?.payload?.tasks?.length) {
      action?.payload?.tasks?.forEach((item) => {
        if (item?.actions?.length) {
          item?.actions?.forEach((tsk) => {
            if (tsk?.localisationKey.toLowerCase().includes("attempted")) {
              taskAttemp.push(item?.taskReference);
            }
          });
        }
      });
    }

    if (taskAttemp.length) {
      const activitylog = yield all([
        ..._.map(taskAttemp, (sr) =>
          apiCall({
            apiType: "activitylogs",
            url: `logs/ActivityGroup-Case/${sr}?PageSize=25&PageNumber=1`
          })
            .then((res) => ({ [sr]: res }))
            .catch(() => ({
              [sr]: null
            }))
        )
      ]);

      if (activitylog.length) {
        let newMapActivity = {};
        let clinicanId = [];
        _.map(activitylog, (sr) => {
          if (
            Object.keys(sr).length &&
            sr[Object.keys(sr)[0]] &&
            sr[Object.keys(sr)]?.pagedResults?.length
          ) {
            const attemptedTask = _.filter(
              sr[Object.keys(sr)]?.pagedResults,
              (item) => item?.activityType === ACTION_ATTEMPTED
            );
            const userid = _.map(attemptedTask, (tsk) => tsk.createdByUserId);
            clinicanId = [...clinicanId, ...userid];
            if (attemptedTask.length) {
              taskReferenceCount = {
                ...taskReferenceCount,
                ...{
                  [Object.keys(sr)[0]]: attemptedTask.length
                }
              };
              sr[Object.keys(sr)].pagedResults = attemptedTask;
              newMapActivity = {
                ...newMapActivity,
                ...{
                  [Object.keys(sr)]: sr[Object.keys(sr)]
                }
              };
            }
          }
        });
        clinicanId = _.uniq(clinicanId);
        const clinicanname = yield all([
          ..._.map(clinicanId, (id) =>
            apiCall({
              apiType: "platformusers",
              url: `platformusers/${id}`
            })
              .then((res) => ({ [id]: res }))
              .catch(() => ({
                [id]: null
              }))
          )
        ]);
        let newClinicanName = {};
        if (clinicanname.length) {
          clinicanname.forEach((ct) => {
            newClinicanName = { ...newClinicanName, ...ct };
          });
        }
        Object.keys(newMapActivity).forEach((key) => {
          if (newMapActivity[key]?.pagedResults?.length) {
            newMapActivity[key]?.pagedResults.forEach((item, ind) => {
              let tmpData = {};
              if (
                item?.createdByUserId &&
                newClinicanName[item.createdByUserId]
              ) {
                tmpData = { ...item, ...newClinicanName[item.createdByUserId] };
              }
              newMapActivity[key].pagedResults[ind] = tmpData;
            });
          }
        });

        if (action?.payload?.tasks?.length) {
          action?.payload?.tasks?.forEach((item, ind) => {
            let tmpItem = {};
            if (newMapActivity[item?.taskReference]) {
              tmpItem = { ...item, ...newMapActivity[item?.taskReference] };
              action.payload.tasks[ind] = tmpItem;
            }
          });
        }
      }
    }
    yield put({
      type: action.type,
      payload: action.payload
    });
  } catch {
    yield put({
      type: action.type,
      payload: action?.payload
    });
  }
}

export const replyThread = async (messageItem) => {
  const { conversationId, serviceProviderReference } = messageItem;
  let messageId = "";
  if (messageItem?.messages?.length) {
    const msg = messageItem.messages[messageItem.messages.length - 1];
    messageId = msg?.messageId || "";
  }
  apiCall({
    apiType: "messaging",
    url: `conversations/${conversationId}/${messageId}?action=reply&serviceproviderreference=${serviceProviderReference}`
  })
    .then((response) => {
      if (response?.redirectUrl) {
        goToMessage(response?.redirectUrl);
      }
    })
    .catch(() => {
      import("../store/store").then(async (res) => {
        res?.default?.dispatch(
          apiException({
            type: null,
            message: "Error-500"
          })
        );
      });
    });
};

import { put, takeLatest } from "redux-saga/effects";
import { apiCall, apiException } from "../../services/api";
import {
  GET_LABREPORT_ERROR,
  GET_LABREPORT_REQUEST,
  GET_LABREPORT_SUCCESS
} from "./actionType";

function* labReportGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `screens/${action.payload.screenReferenceId}/labresult`
    });

    yield put({
      type: GET_LABREPORT_SUCCESS,
      payload: {
        ...response,
        screenReference: action.payload.screenReference
      }
    });
  } catch (e) {
    yield put(
      apiException({
        type: GET_LABREPORT_ERROR,
        code: e.code || e,
        message: e.message || "LabReport-Fail"
      })
    );
  }
}

export function* labReportGetAction() {
  yield takeLatest([GET_LABREPORT_REQUEST], labReportGet);
}

import { put, takeEvery } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const TASK_LOG = "TASK_LOG";
const TASK_LOG_SUCCESS = "TASK_LOG_SUCCESS";
const TASK_LOG_ERROR = "TASK_LOG_ERROR";
const RESET_TASK_LOG = "RESET_TASK_LOG";

const initialState = {};

function* taskLogGet(action) {
  try {
    if (action.payload.taskReference) {
      const response = yield apiCall({
        method: "get",
        apiType: "activitylogs",
        url: `logs/ActivityGroup-Case/${action.payload.taskReference}`
      });
      yield put({
        type: TASK_LOG_SUCCESS,
        payload: {
          ...response,
          ...action.payload
        }
      });
    } else {
      yield put(
        apiException({
          type: TASK_LOG_ERROR,
          payload: {
            code: 404,
            ...action.payload
          }
        })
      );
    }
  } catch (e) {
    yield put(
      apiException({
        type: TASK_LOG_ERROR,
        code: e.code || e,
        message: e.message || "taskLog-Fail",
        payload: {
          ...e,
          ...action.payload
        }
      })
    );
  }
}

export const gettaskLog = (payload) => ({
  type: TASK_LOG,
  payload
});

export const resettaskLog = (payload) => ({
  type: RESET_TASK_LOG,
  payload
});

export function* taskLogGETAction() {
  yield takeEvery([TASK_LOG], taskLogGet);
}

export const taskLogReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case TASK_LOG: {
      return {
        ...state,
        [action.payload.taskReference]: {
          ...initialState,
          loading: true,
          error: apiErrorObj,
          flag: false,
          data: {}
        }
      };
    }
    case TASK_LOG_SUCCESS: {
      return {
        ...state,
        [action.payload.taskReference]: {
          ...state[action.payload.taskReference],
          loading: false,
          flag: true,
          data: action.payload
        }
      };
    }

    case TASK_LOG_ERROR: {
      return {
        ...state,
        [action.payload.taskReference]: {
          ...state[action.payload.taskReference],
          loading: false,
          flag: false,
          data: {},
          error: {
            ...state.error,
            isError: true,
            message: action.message,
            code: action.code
          }
        }
      };
    }
    case RESET_TASK_LOG: {
      return {
        ...state,
        loading: false,
        flag: false,
        data: {},
        error: apiErrorObj
      };
    }
    default:
      return state;
  }
};

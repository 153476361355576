import {
  GridContainer,
  GridItem,
  Typography,
  Wrapper
} from "preventx-storybook";
import React from "react";

export const UserListHeader = () => (
  <Wrapper className="user-list-header">
    <GridContainer>
      <GridItem xs={12} md={3} lg={3} xlg={3}>
        <Typography>personal-details</Typography>
      </GridItem>
      <GridItem xs={12} md={3} lg={3} xlg={3}>
        <Typography>contact-details</Typography>
      </GridItem>
      <GridItem xs={12} md={3} lg={3} xlg={3}>
        <Typography>AccountStatus</Typography>
      </GridItem>
      <GridItem xs={12} md={3} lg={3} xlg={3}>
        <Typography>Cases</Typography>
      </GridItem>
    </GridContainer>
  </Wrapper>
);

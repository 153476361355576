import { DateShouldNotBeGreaterThenToday } from "../utils/utilsFunction";
import { DATE_FORMAT } from "../constants/defaults";
import {
  getAccountFlagsLocalisationKey,
  getAssignedSexLocalisationKey,
  getCountryOfBirthIsoCode,
  getEthnicityBackground,
  getEthnicityGroup,
  getGenderIdentityLocalisationKey,
  getGenderLocalisationKey,
  getLanguageLocalisationKey
} from "../utils/helper";

const PersonalInfoForm = [
  {
    name: "FirstName",
    placeholder: "enter-name",
    label: "name",
    rules: {
      required: {
        value: true,
        message: "provide-name"
      }
    },
    isFullRow: true
  },
  {
    name: "LastName",
    placeholder: "enter-surname",
    label: "surname",
    rules: {
      required: {
        value: true,
        message: "provide-surname"
      }
    }
  },
  {
    name: "DateOfBirth",
    placeholder: DATE_FORMAT,
    label: "doB",
    rules: {
      required: {
        value: true,
        message: "provide-doB"
      },
      validate: {
        checkDate: (v) =>
          DateShouldNotBeGreaterThenToday(v, DATE_FORMAT) || "Invalid date"
      }
    },
    format: DATE_FORMAT,
    seperator: "/",
    type: "date"
  },
  {
    name: "CountryOfBirth",
    placeholder: "countryOfBirth",
    label: "countryOfBirth",
    rules: {
      required: {
        value: true,
        message: "select-country"
      }
    },
    type: "select",
    isMulti: false,
    options: getCountryOfBirthIsoCode
  },
  {
    name: "Gender",
    placeholder: "gender",
    label: "sex",
    rules: {
      required: {
        value: true,
        message: "select-gender"
      }
    },
    type: "select",
    isMulti: false,
    options: getGenderLocalisationKey
  },
  {
    name: "AssignedSex",
    placeholder: "sex-at-birth",
    label: "sex-at-birth",
    rules: {
      required: {
        value: true,
        message: "select-sex-at-birth"
      }
    },
    type: "select",
    isMulti: false,
    options: getAssignedSexLocalisationKey
  },
  {
    name: "GenderIdentity",
    placeholder: "gender-identity",
    label: "gender-identity",
    rules: {
      required: {
        value: true,
        message: "select-gender-identity"
      }
    },
    type: "select",
    isMulti: false,
    options: getGenderIdentityLocalisationKey
  },
  {
    name: "EthnicGroup",
    placeholder: "ons_ethnicgroup",
    label: "ons_ethnicgroup",
    rules: {
      required: {
        value: true,
        message: "select-ethnicity"
      }
    },
    type: "select",
    isMulti: false,
    options: getEthnicityGroup
  },
  {
    name: "EthnicBackground",
    placeholder: "ons_ethnicbackground",
    label: "ons_ethnicbackground",
    rules: {
      required: {
        value: true,
        message: "select-ethnicity-background"
      }
    },
    type: "select",
    isMulti: false,
    options: [],
    disabled: true,
    isDependent: "EthnicGroup",
    getOptions: getEthnicityBackground
  },
  {
    name: "AccountFlags",
    placeholder: "select-account-flags",
    label: "account-flags",
    type: "select",
    isMulti: true,
    variant: "due",
    options: getAccountFlagsLocalisationKey,
    isFullRow: true
  },
  {
    name: "Language",
    placeholder: "select-service-user-language",
    label: "service-user-language",
    rules: {
      required: {
        value: false,
        message: "select-service-user-language"
      }
    },
    type: "select",
    isMulti: false,
    options: getLanguageLocalisationKey,
    isFullRow: true
  }
];

export default PersonalInfoForm;

import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants/defaults";
import { ValidateDate } from "../utils/utilsFunction";

const EditTaskForm = [
  {
    name: "dueDate",
    placeholder: DATE_FORMAT,
    label: "new-dueDate",
    rules: {
      required: {
        value: true,
        message: "provide-dueDate"
      }
    },
    validate: {
      checkDate: (v) => ValidateDate(v, DATE_FORMAT)
    },
    format: DATE_FORMAT,
    seperator: "/",
    type: "datepicker",
    minDate: dayjs().toDate(),
    isFullRow: true
  }
];

export default EditTaskForm;

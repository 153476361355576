import {
  getContactPreferenceOrder,
  getMobileNumberCode
} from "../utils/helper";

const ContactForm = [
  {
    name: "address1",
    placeholder: "enter-address-1",
    label: "address-1",
    type: "dynamicField",
    minFields: 3,
    requiredFields: [1],
    isFullRow: true,
    errorMessage: "Address-Required"
  },
  {
    name: "Postcode",
    placeholder: "enter-postCode",
    label: "post-code",
    rules: {
      required: {
        value: true,
        message: "provide-postCode"
      }
    }
  },
  {
    name: "MobileNumber",
    placeholder: "071 123 45678",
    label: "mobile-number",
    type: "phone",
    options: getMobileNumberCode
  },
  {
    name: "EmailAddress",
    placeholder: "email@address.com",
    label: "email-address",
    rules: {
      required: {
        value: true,
        message: "provide-email"
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email-Validate-Message"
      }
    }
  },
  {
    name: "ContactPreferenceOrder",
    placeholder: "please-select",
    label: "contact-preference",
    type: "select",
    isMulti: true,
    rules: {
      required: {
        value: true,
        message: "select-contact-preference"
      }
    },
    options: getContactPreferenceOrder
  }
];

export default ContactForm;

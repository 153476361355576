/* eslint-disable no-nested-ternary */
import _ from "lodash";
import {
  Button,
  EditIcon,
  NoteContent,
  NoteSection,
  NotesHeader,
  Outcomes,
  PrevNavIcon,
  RightAngleIcon,
  Typography,
  Wrapper
} from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotesEditHistory } from "../../../../../store/actionTypes";
import {
  selectNoteCategory,
  selectNoteType
} from "../../../../../utils/helper";
import { extractDate, extractTime } from "../../../../../utils/utilsFunction";

const style = { p: 16, bb: 1, borderColor: "greyscale-grey-5" };

export const NotesCard = ({
  note,
  editNoteHandler,
  flagNoteHandler,
  isLoading,
  noteCategories,
  caseIdHandler,
  isThisCase,
  caseId,
  noteTypes,
  recentNoteId
}) => {
  const dispatch = useDispatch();
  const [noteDetails, setNoteDetails] = useState(note);
  const [noteHistoryIndex, setNotesHistoryIndex] = useState(0);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [isFirstRun, setFirstRun] = useState(true);

  useEffect(() => setNoteDetails(note), [note]);

  const notesEditHistory = useSelector(
    (state) => state.notesReducer.response.notes.notesEditHistory
  );

  const { totalCount, pagedResults } =
    _.keys(notesEditHistory).length > 0
      ? notesEditHistory[note?.caseNoteReference] || {}
      : {};

  useEffect(() => {
    if (totalCount && noteHistoryIndex < pagedResults.length) {
      setHistoryLoading(false);
      const notes = pagedResults[noteHistoryIndex];
      setNoteDetails(notes);
    }
  }, [noteHistoryIndex, pagedResults]);

  useEffect(() => {
    if (!isFirstRun) {
      const el = document.getElementById(`sb-note-${note.caseNoteReference}`);
      el.scrollIntoView(false);
    }
    setFirstRun(false);
  }, [noteDetails])

  const getHistory = (type, caseNoteReference) => {
    if (type === "prev") {
      if (pagedResults) {
        if (noteHistoryIndex + 1 < pagedResults.length) {
          setNotesHistoryIndex(noteHistoryIndex + 1);
        } else {
          setHistoryLoading(true);
          dispatch(getNotesEditHistory(caseNoteReference));
          setNotesHistoryIndex(noteHistoryIndex + 1);
        }
      } else {
        setHistoryLoading(true);
        dispatch(getNotesEditHistory(caseNoteReference));
        setNotesHistoryIndex(1);
      }
    } else if (type === "next") {
      if (noteHistoryIndex !== totalCount) {
        setNotesHistoryIndex(noteHistoryIndex - 1);
      }
    }
  };

  const getNoteType = (id) => {
    const noteType = _.find(noteTypes, { value: id })?.text;
    return noteType || "-";
  };

  return (
    <div id = {`sb-note-${note?.caseNoteReference}`}>
    <Wrapper
      borderRadius={8}
      borderColor={
        note?.caseNoteReference === recentNoteId
          ? "alert-highlight-general"
          : noteDetails?.isFlagged
          ? "alert-highlight-warning"
          : "none"
      }
      b={4}
      mb={12}>
      <Wrapper
        borderRadius={4}
        b={1}
        borderColor={
          note?.caseNoteReference === recentNoteId
            ? "preventx-blue"
            : noteDetails?.isFlagged
            ? "alert-warning"
            : "greyscale-grey-5"
        }>
        <NoteSection {...style}>
          <NotesHeader
            disableFlag={noteHistoryIndex > 0}
            isThisCase={isThisCase}
            caseIdHandler={caseIdHandler}
            caseId={caseId}
            user={note.authorName}
            createdDate={extractDate(
              noteDetails?.modifiedOn || note?.createdOn || null
            )}
            createdTime={extractTime(
              noteDetails?.modifiedOn || note?.createdOn || null
            )}
            flagHandler={flagNoteHandler}
            isLoading={isLoading}
            tags={
              !note?.noteCategoryIds
                ? [selectNoteCategory(noteCategories)?.text]
                : _.map(
                    note?.noteCategoryIds,
                    (c) =>
                      _.find(noteCategories.updatedJson, { value: c })?.text
                  )
            }
            isFlagged={noteDetails?.isFlagged}
          />
        </NoteSection>

        <NoteSection {...style} bg="greyscale-grey-1">
          <Typography weight="medium">
            {!note?.noteTypeId
              ? selectNoteType(noteTypes)?.text
              : getNoteType(note?.noteTypeId)}
          </Typography>
        </NoteSection>

        {noteDetails?.outcomes &&
          extractDate(noteDetails?.outcomes[0]?.data?.ConsulationDate) && (
            <NoteSection {...style}>
              <NoteContent
                title="Consultation date"
                note={
                  extractDate(
                    noteDetails?.outcomes[0]?.data?.ConsulationDate
                  ) || null
                }
              />
            </NoteSection>
          )}

        {noteDetails?.content && noteDetails?.content !== "<p><br></p>" && (
          <NoteSection {...style}>
            <NoteContent title="Note" note={noteDetails?.content} markdown />
          </NoteSection>
        )}

        {_.map(noteDetails?.outcomes, (item, index) => (
          <NoteSection {...style} key={`note-details-${index + 1}`}>
            <Outcomes outcomes={item?.data} />
          </NoteSection>
        ))}

        {(noteHistoryIndex === 0 || !note?.hasHistory) && (
          <NoteSection p={16}>
            <Button
              variant="secondary"
              fullWidth
              style={{ justifyContent: "center" }}
              handler={editNoteHandler}
              icon={<EditIcon />}>
              edit-note
            </Button>
          </NoteSection>
        )}
        {note?.hasHistory && (
          <NoteSection
            {...{
              bt: 1,
              borderColor: "greyscale-grey-5"
            }}>
            <Wrapper flex align="center" position="relative">
              <Wrapper br={1} borderColor="greyscale-grey-5" fw p={16}>
                <Button
                  loading={historyLoading}
                  variant="tertiary"
                  dense
                  icon={<PrevNavIcon />}
                  isDisabled={totalCount && totalCount === noteHistoryIndex + 1}
                  placement="left"
                  handler={() => getHistory("prev", note?.caseNoteReference)}>
                  {}
                  previous-edit
                </Button>
              </Wrapper>
              <Wrapper fw flex justify="right" p={16}>
                <Button
                  variant="tertiary"
                  dense
                  isDisabled={noteHistoryIndex === 0}
                  icon={<RightAngleIcon />}
                  handler={() => getHistory("next", note?.caseNoteReference)}>
                  next-edit
                </Button>
              </Wrapper>
            </Wrapper>
          </NoteSection>
        )}
      </Wrapper>
    </Wrapper>
    </div>
  );
};


NotesCard.propTypes = {
  caseIdHandler: PropTypes.func.isRequired,
  caseId: PropTypes.string.isRequired,
  isThisCase: PropTypes.bool.isRequired,
  note: PropTypes.instanceOf(Array).isRequired,
  noteCategories: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  editNoteHandler: PropTypes.func.isRequired,
  flagNoteHandler: PropTypes.func.isRequired,
  noteTypes: PropTypes.instanceOf(Array).isRequired,
  recentNoteId: PropTypes.string.isRequired
};

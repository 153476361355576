export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const GET_NOTIFICATIONS_MORE = "GET_NOTIFICATIONS_MORE";

export const SET_NOTIFICATIONS_SUCCESS = "SET_NOTIFICATIONS_SUCCESS";

export const SET_NOTIFICATIONS_SUCCESS_MORE = "SET_NOTIFICATIONS_SUCCESS_MORE";

export const SET_NOTIFICATIONS_ERROR = "SET_NOTIFICATIONS_ERROR";

export const MARKASREAD_NOTIFICATION = "MARKASREAD_NOTIFICATION";

export const MARKALLASREAD_NOTIFICATION = "MARKALLASREAD_NOTIFICATION";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

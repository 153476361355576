import {
  GET_NAV_CASES_COUNT,
  SET_NAV_CASES_COUNT,
  SET_NAV_MENUS
} from "./actiontypes";

export const getNavBarCasesCount = (data,menuName = "all") => ({
  type: GET_NAV_CASES_COUNT,
  data,
  menuName,
});
export const setNavCasesCount = (data) => ({ type: SET_NAV_CASES_COUNT, data });

export const setNavMenus = (menus) => ({ type: SET_NAV_MENUS, menus });

import React from "react";
import { Typography, GridContainer, GridItem } from "preventx-storybook";

export const TableHead = () => (
  <div className="Table__Head">
    <GridContainer>
      <GridItem className="Table__Head__Col-1" xs={12} md={4} lg={3} xlg={3}>
        <Typography className="head1 service-user" variant="span">
          service-user
        </Typography>
      </GridItem>
      <GridItem xs={12} md={8} lg={9} xlg={9}>
        <GridContainer>
          <GridItem xs={12} md={7} lg={5} xlg={5}>
            <Typography className="tasks" variant="span">
              Tasks
            </Typography>
          </GridItem>
          <GridItem xs={12} md={5} lg={6} xlg={6}>
            <Typography className="details" variant="span">
              Details
            </Typography>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  </div>
);

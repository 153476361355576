import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ResultSummary, translate } from "preventx-storybook";
import { dayconvertToFormat } from "../../../utils/utilsFunction";
import { apiSuccess } from "../../../services/api";

export const SummaryCard = ({ inputModel }) => {
  const { t } = translate();
  const dispatch = useDispatch();
  const serviceUserReferenceState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );
  const testingState = useSelector((state) => state.testingReducer);
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const resultSummaryState = useSelector((state) => state.resultSummaryReducer);

  const [resultData, setresultData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (
      resultSummaryState &&
      resultSummaryState[inputModel] &&
      resultSummaryState[inputModel].flag
    ) {
      setresultData({
        summary: {
          ...testingState?.data?.summary,
          ...resultSummaryState[inputModel].data
        }
      });
      setLoading(false);
    }
    if (
      resultSummaryState &&
      resultSummaryState[inputModel] &&
      resultSummaryState[inputModel].error.isError
    ) {
      setLoading(false);
    }
  }, [resultSummaryState]);

  const serviceUserDetails = (serviceUserName, serviceReference, dob) => {
    if (serviceUserName && serviceReference && dob) {
      return `${serviceUserName} | ${serviceReference} | DOB: ${dob}\n`;
    }
    if (serviceReference) {
      return `${serviceReference}\n`;
    }

    return "";
  };

  const CopyTextToClipboard = () => {
    let ListOfResults = "";
    const PxNumber = inputModel || "";
    const ServiceUserName = `${
      serviceUserReferenceState?.data?.FirstName || "NA"
    } ${serviceUserReferenceState?.data?.LastName || ""}`;
    const servicereference = caseHeaderState?.data?.serviceUserReference || "";
    const dob = serviceUserReferenceState?.data?.DateOfBirth
      ? dayconvertToFormat(
          serviceUserReferenceState?.data?.DateOfBirth,
          "DD/MM/YYYY"
        )
      : "";
    const screencompelteddate = dayconvertToFormat(
      resultSummaryState[inputModel]?.data?.screen?.dateCompleted,
      "DD/MM/YYYY"
    );

    if (
      resultSummaryState[inputModel] &&
      resultSummaryState[inputModel].data.screen.specimens.length
    ) {
      resultSummaryState[inputModel]?.data?.screen?.specimens.forEach(
        (item) => {
          const sample = item.sampleTypeLocalisationKey;
          if (item?.serviceRequests && item?.serviceRequests?.length) {
            item.serviceRequests.forEach((subitem) => {
              if (subitem?.results && subitem?.results?.length) {
                subitem.results.forEach((res) => {
                  const type = t(res?.caseCodeLocalisationKey) || "";
                  ListOfResults += `● ${sample && sample[0].toUpperCase() + sample.slice(1)} > ${res?.component?.commonNameLocalisationKey} : ${type}`;
                  if (res?.observations?.length && res?.observations[0]?.value > -1 && res?.observations[0]?.value !== null ) {
                    ListOfResults +=` (${res?.observations[0].value} ${res?.observations?.length && res?.observations[0]?.uoMLocalisationKey.toUpperCase()})`
                  }
                  ListOfResults += '\n';
                });
              }
            });
          }
        }
      );
    }

    const text = `SH.UK ${t(
      "Screen-Summary"
    )} (${PxNumber})\n${serviceUserDetails(
      ServiceUserName,
      servicereference,
      dob
    )}${t("Screen-Completed")}: ${screencompelteddate}\n${t(
      "results-summary"
    )}:\n${ListOfResults}`;
    navigator.clipboard.writeText(text);
    dispatch(apiSuccess("Result-Copy-Message"));
  };

  return (
    <ResultSummary
      isLoading={isLoading}
      loaderHeight={
        resultSummaryState[inputModel] &&
        resultSummaryState[inputModel].error.isError
          ? "auto"
          : "20vh"
      }
      errorMessage={{
        404: "Screen-Not-Found",
        400: "Screen-Not-Found"
      }}
      isError={
        resultSummaryState[inputModel] &&
        resultSummaryState[inputModel].error.isError
      }
      errorCode={
        resultSummaryState[inputModel] &&
        resultSummaryState[inputModel]?.error?.code
      }
      inputModel={resultData.summary}
      handlerCopy={CopyTextToClipboard}
      handlerFullRecord={(testReference) => {
        if (testReference) {
          const version = resultSummaryState[inputModel]?.data?.screen?.version;
          window.open(
            `/lab-report/${testReference}/${caseHeaderState?.data?.serviceUserReference}?version=${version}`,
            "_blank"
          );
        }
      }}
    />
  );
};

SummaryCard.defaultProps = {
  inputModel: ""
};

SummaryCard.propTypes = {
  inputModel: PropTypes.string
};

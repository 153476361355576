import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  Spinner
} from "preventx-storybook";
import { FormBuilder } from "../../../molecules";
import useServiceProviderSettings from "../useServiceProviderSettings";
import { EMAIL_ADDRESS_FIELD, MOBILE_NUMBER_FIELD } from "../../../../constants/defaults";

export const InformationEdit = ({
  inputModel,
  formJson,
  toggleModal,
  showModal,
  onFormChange,
  onSubmit,
  title
}) => {
  const { loading, data } = useServiceProviderSettings()
  const {
    formState: { isValid, errors },
    handleSubmit,
    control,
    watch,
    resetField,
    clearErrors
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });
  const serviceUserUpdateState = useSelector(
    (state) => state.serviceUserReferenceUpdateReducer
  );

  useEffect(() => {
    let watchField = "";
    let dependent = "";
    let isDependent = "";
    if (formJson?.length) {
      formJson.forEach((item) => {
        if (item?.isDependent) {
          watchField = watch(item?.isDependent);
          dependent = item?.name;
          isDependent = item?.isDependent;
        }
      });
    }
    if (watchField !== inputModel[isDependent]) {
      resetField(dependent);
    }
  }, [formJson]);

  useEffect(() => {
    if (data?.value) {
      formJson.forEach(async (item) => {
        if (
          (item.name === MOBILE_NUMBER_FIELD &&
            data?.value?.includes(MOBILE_NUMBER_FIELD)) ||
          (item.name === EMAIL_ADDRESS_FIELD &&
            data?.value?.includes(EMAIL_ADDRESS_FIELD))
        ) {
          item.disabled = true;
        }
      });
    }
  }, [data]);

  useEffect(() => {
    clearErrors();
  }, [showModal]);

  return (
    <ModalLayout showModal={showModal} onCloseModal={toggleModal} isFullScreen>
      <ModalHeader headerTitle={title} onCloseModal={toggleModal} />
      <form>
        <ModalBody isFullScreen>
          {loading ? (
            <Spinner size={48} />
          ) : (
            <FormBuilder
              formjson={formJson}
              inputModel={inputModel}
              handler={onSubmit}
              errors={errors}
              control={control}
              onChangeHandle={onFormChange}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!isValid}
            handler={handleSubmit(onSubmit)}
            loading={serviceUserUpdateState.loading}>
            apply-changes
          </Button>
        </ModalFooter>
      </form>
    </ModalLayout>
  );
};

InformationEdit.defaultProps = {
  onSubmit: () => null,
  onFormChange: () => null,
  toggleModal: () => null,
  showModal: false,
  formJson: {},
  inputModel: {},
  title: "Personal Information"
};

InformationEdit.propTypes = {
  onSubmit: PropTypes.func,
  onFormChange: PropTypes.func,
  toggleModal: PropTypes.func,
  showModal: PropTypes.bool,
  formJson: PropTypes.instanceOf({}),
  inputModel: PropTypes.instanceOf({}),
  title: PropTypes.string
};

import { Caselist, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import * as PATH from "../../../../constants/paths";

export const Table = ({ data }) => {
  const history = useHistory();

  return (
    <Wrapper className="Table caselist__table">
      <Wrapper className="Table__Body caselist__table__body">
        {data.map((inputData, index) => {
          const renderViewCase = () => {
            const { caseReference } = inputData.caseDetail;
            history.push(`${PATH.CASE_LIST}/${caseReference}`);
          };
          return (
            <Caselist
              key={`table-${index + 1}`}
              inputData={inputData}
              handlerRowClick={renderViewCase}
            />
          );
        })}
      </Wrapper>
    </Wrapper>
  );
};

Table.defaultProps = {
  data: []
};

Table.propTypes = {
  data: PropTypes.instanceOf(Object)
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  Typography,
  Wrapper
} from "preventx-storybook";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deleteOutcomes } from "../../../store/actionTypes";
import { getCareProcess } from "../../../store/care/logic";

const baseClass = "sb-confirmdialogue";

export const OutComeConfirmModal = ({ toggleModal, showModal, inputModel }) => {
  const { caseId } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isDeleting = useSelector(
    (state) => state.notesReducer.form.isDeletingOutcome
  );
  useEffect(() => {
    if (!isDeleting && loading) {
      toggleModal();
      dispatch(
        getCareProcess({
          caseReference: caseId,
          processReference: inputModel?.processReference
        })
      );
    }
  }, [isDeleting]);

  const onConfirm = () => {
    setLoading(true);
    dispatch(deleteOutcomes(inputModel?.caseNoteReference));
  };
  return (
    <Wrapper testId={baseClass} className={baseClass}>
      <ModalLayout
        showModal={showModal}
        onCloseModal={toggleModal}
        className={baseClass}>
        <ModalHeader onCloseModal={toggleModal} showCloseIcon={false}>
          <Wrapper className={`${baseClass}__head`}>
            <Typography size={18}>Outcome-Delete-Title</Typography>
          </Wrapper>
        </ModalHeader>
        <ModalBody>
          <Typography>Outcome-Delete-Content</Typography>
        </ModalBody>
        <ModalFooter
          primaryText="Confirm"
          handlerPrimary={onConfirm}
          handlerSecondary={toggleModal}
          secondaryText="Cancel"
          testId={`${baseClass}__footer`}
          isPrimaryLoading={isDeleting}
        />
      </ModalLayout>
    </Wrapper>
  );
};

OutComeConfirmModal.defaultProps = {
  toggleModal: () => null,
  showModal: false
};

OutComeConfirmModal.propTypes = {
  toggleModal: PropTypes.func,
  showModal: PropTypes.bool,
  inputModel: PropTypes.instanceOf(Object).isRequired
};

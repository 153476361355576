import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Loader,
  Wrapper,
  Divider,
  TextEditor,
  Typography,
  NoteSection
} from "preventx-storybook";

// Constants
import { HEPC } from "../../../../../constants/defaults";

// Store Management
import {
  addNotes,
  insertNewNote,
  resetNotesForm,
  filterOutcomeTypes
} from "../../../../../store/actionTypes";

// Services
import {
  showOutcome,
  selectNoteType,
  selectNoteCategory
} from "../../../../../utils/helper";
import { apiException } from "../../../../../services/api";

// Components
import { RightMenuPanel } from "../../..";
import { Outcomes } from "../Outcomes/Outcomes";

// Component Constants
const inputProcessType = "ProcessType-Care";
const inputNoteType = "NoteType-Care-LogHepCOutcome-Done";

export const NewNote = ({
  closeHandler,
  isEdit,
  reference,
  noteName,
  triggerEdit
}) => {
  const dispatch = useDispatch();

  const serviceProviders = useSelector(
    (state) => state.serviceProvidersReducer
  );
  const { portalType, serviceProviderSettings } = useSelector(
    (state) => state.caseTypeReducer
  );
  const { form, response } = useSelector((state) => state.notesReducer);

  const [loading, setIsLoading] = useState(true);
  const [noteTypeSelected, setNoteType] = useState("-");
  const [noteCategorySelected, setNoteCategory] = useState("-");
  const [notesInitialState, updateInitialState] = useState(null);

  const {
    processes,
    outcomeTypes,
    outcomeOptions,
    noteTypeOptions,
    noteCategoryOptions,
    filteredOutcomeTypes
  } = response;
  const { isLoading, logCareOutcome } = form;
  const { noteTypeId, noteCategoryIds, content, outcomes } = form.notes;

  const getProcessTypeId = _.find(processes, { processType: inputProcessType });
  const getNoteTypeId = _.find(noteTypeOptions, { text: inputNoteType });

  const defaultHepcDropdownData = [
    {
      processReference: getProcessTypeId.processReference,
      outcomeTypeId: 2500,
      data: {
        outcome: "Outcome-Care-CareTransferred",
        outcomeDetails: "Outcome-Care-CareTransferred-CareTransferredToODN"
      }
    }
  ];

  const getOutcome = (key, value) => {
    const options = {
      key,
      value,
      noteCategoryOptions,
      outcomeTypes,
      noteCategoryIds,
      noteTypeId
    };
    dispatch(filterOutcomeTypes(options));
  };

  const isDisableSave = (isSaving, noteTypeValue, outcomesSelected) => {
    if (
      noteCategoryOptions.length < 1 ||
      noteTypeOptions.length < 1 ||
      isSaving
    ) {
      return true;
    }

    if (noteName === "user" && (content === "" || content === "<p><br></p>")) {
      return true;
    }

    const noteTypeDetails = _.find(noteTypeOptions, {
      value: noteTypeValue
    });

    if (showOutcome(noteTypeDetails?.text)) {
      const { outcome, outcomeDetails, ClinicName } =
        outcomesSelected[0]?.data || {};

      if (
        !outcome ||
        !outcomeDetails ||
        (_.isArray(outcomeDetails) && outcomeDetails.length < 1) ||
        (outcome === "Outcome-Care-CareTransferred" &&
          outcomeDetails &&
          (!ClinicName || ClinicName === ""))
      ) {
        return true;
      }
    }

    if (_.isEqual(notesInitialState, form.notes)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (noteCategoryOptions.length < 1) {
      dispatch(apiException({ message: "NoteCategories-Fail" }));
      return;
    }
    const noteCategory = selectNoteCategory(
      noteCategoryOptions,
      +noteCategoryIds?.toString(),
      logCareOutcome
    );

    if (noteCategory?.text && noteCategory?.value) {
      dispatch(addNotes("noteCategoryIds", [noteCategory.value]));
      getOutcome("noteCategories", noteCategory.value);

      setNoteCategory(noteCategory.text);
    }
  }, [noteCategoryOptions, logCareOutcome]);

  useEffect(() => {
    if (noteTypeOptions.length < 1) {
      dispatch(apiException({ message: "NoteTypes-Fail" }));
      return;
    }
    const noteTypeDetails = selectNoteType(
      noteTypeOptions,
      noteTypeId,
      logCareOutcome,
      portalType
    );

    if (noteTypeDetails?.text && noteTypeDetails?.value) {
      dispatch(addNotes("noteTypeId", noteTypeDetails.value));
      getOutcome("noteTypeId", noteTypeDetails.value);

      setNoteType(noteTypeDetails.text);
    }
  }, [noteTypeOptions, logCareOutcome]);

  useEffect(() => {
    if (noteCategoryOptions.length < 1 || noteTypeOptions.length < 1) {
      return;
    }
    if (noteCategoryIds) {
      const noteCategory = selectNoteCategory(
        noteCategoryOptions,
        +noteCategoryIds?.toString()
      );
      setNoteCategory(noteCategory.text);
      getOutcome("noteCategories", noteCategory.value);
    }
    if (noteTypeId) {
      const noteTypeDetails = selectNoteType(noteTypeOptions, noteTypeId);
      setNoteType(noteTypeDetails.text);
      getOutcome("noteTypeId", noteTypeDetails.value);
    }

    if (noteName === "user" && isEdit) {
      const noteTypeDetails = selectNoteType(noteTypeOptions);
      const noteCategory = selectNoteCategory(noteCategoryOptions);
      setNoteCategory(noteCategory.text);
      setNoteType(noteTypeDetails.text);
    }
  }, [noteTypeId, noteCategoryIds, noteName]);

  useEffect(() => {
    if (noteName === "user") {
      dispatch(addNotes("serviceUserReference", reference));
      dispatch(
        addNotes("serviceProviderReference", serviceProviders.data[1].value)
      );
      return;
    }
    dispatch(addNotes("caseReference", reference));
  }, [noteName]);

  // To set the default options in the dropdown if its a hepc case
  useEffect(() => {
    if (noteTypeId && !isEdit) {
      if (!portalType && serviceProviderSettings) {
        const { processReference } = getProcessTypeId;
        const outcomeTypeId = _.find(outcomeTypes, { noteTypeId })?.id;
        const { outcomeDefaultValue, outcomeDetailsDefaultValue } =
          serviceProviderSettings;
        dispatch(
          addNotes("outcomes", [
            {
              processReference,
              outcomeTypeId,
              data: {
                outcome: outcomeDefaultValue,
                outcomeDetails: outcomeDetailsDefaultValue
              }
            }
          ])
        );
      } else if (
        portalType &&
        portalType === HEPC &&
        noteTypeId === getNoteTypeId.value
      ) {
        dispatch(addNotes("outcomes", defaultHepcDropdownData));
      }
    }
  }, [noteTypeId]);

  const notesHandler = (key, value) => dispatch(addNotes(key, value));

  useEffect(() => {
    if (noteCategorySelected && noteTypeSelected) {
      setIsLoading(false);
      updateInitialState(form.notes);
    }
  }, [noteCategorySelected, noteTypeSelected]);

  const createNoteHandler = () => {
    dispatch(
      insertNewNote(
        noteName,
        reference,
        form.notes,
        closeHandler,
        isEdit,
        logCareOutcome
      )
    );
  };

  useEffect(() => () => dispatch(resetNotesForm()), []);

  return (
    <RightMenuPanel
      action="close"
      handler={closeHandler}
      highlightBorder={triggerEdit}
      title={isEdit ? "Edit-Note" : "New-Note"}>
      <Divider mt={5} mb={20} absolute />
      <Loader loading={loading || isLoading} height="50vh">
        <NoteSection>
          <Typography weight="medium" display="block" mb={8}>
            Note-Type
          </Typography>
          <Typography>{noteTypeSelected}</Typography>
        </NoteSection>

        <Divider mt={16} mb={30} absolute />

        <NoteSection>
          <Typography weight="medium" display="block" mb={8}>
            Note-Category
          </Typography>
          <Typography>{noteCategorySelected}</Typography>
        </NoteSection>

        {filteredOutcomeTypes.length > 0 && (
          <>
            <Divider mb={45} absolute />
            <Wrapper mt={40}>
              <Outcomes
                filterOutcomeTypes={filteredOutcomeTypes}
                form={form}
                processes={processes}
                outcomeOptions={outcomeOptions}
                notesHandler={notesHandler}
              />
            </Wrapper>
          </>
        )}

        <Divider mt={16} mb={30} absolute />

        <TextEditor
          label="Note"
          unlimitedRows
          value={content}
          handler={(value) => notesHandler("content", value)}
        />

        <Divider mt={16} mb={40} absolute />

        <Button
          fullWidth
          loading={form.isSaving}
          handler={createNoteHandler}
          isDisabled={isDisableSave(form.isSaving, noteTypeId, outcomes)}>
          save-note
        </Button>
      </Loader>
    </RightMenuPanel>
  );
};

NewNote.defaultProps = {
  closeHandler: false
};

NewNote.propTypes = {
  closeHandler: PropTypes.func,
  isEdit: PropTypes.bool.isRequired,
  reference: PropTypes.string.isRequired,
  noteName: PropTypes.string.isRequired,
  triggerEdit: PropTypes.bool.isRequired
};

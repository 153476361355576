import { stringify } from "query-string";
import { all, put, takeLatest } from "redux-saga/effects";
import { apiErrorObj, DEFAULT_PAGE_SIZE } from "../../constants/defaults";
import { apiCall, apiException, paging } from "../../services/api";
import {
  getState,
  injectUserDetails,
  pageStateDetails,
  updateFiltersParams
} from "../../utils/helper";
import { getUpdatedPagedResults } from "../../utils/settings";

const CASE_LIST = "CASE_LIST";
const CASE_LIST_MORE = "CASE_LIST_MORE";
const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
const CASE_LIST_MORE_SUCCESS = "CASE_LIST_MORE_SUCCESS";
const CASE_LIST_ERROR = "CASE_LIST_ERROR";
const CASE_LIST_FILTER = "CASE_LIST_FILTER";
const CASE_SCROLL_POSITION = "CASE_SCROLL_POSITION";
const CASE_SCROLL_POSITION_UPDATE = "CASE_SCROLL_POSITION_UPDATE";
const CASE_LIST_CLEAR_ERROR = "CASE_LIST_CLEAR_ERROR";
const CASE_LIST_LOADING = "CASE_LIST_LOADING";
const CASE_LIST_UPDATE = "CASE_LIST_UPDATE";
const RESET_CASE_LIST = "RESET_CASE_LIST";

const initialState = {
  loading: true,
  error: apiErrorObj,
  overDuePage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  data: [],
  scrollposition: 0
};

const setCaseList = (moreLoading, response, pageNumber) => ({
  type: moreLoading ? CASE_LIST_MORE_SUCCESS : CASE_LIST_SUCCESS,
  response,
  overDuePage: pageNumber
});

function* caseListGet(action) {
  const { filterType, resetPage, moreLoading } = action.payload;
  const { currentFilters, overDuePage, pageSize, formFilters } =
    yield pageStateDetails(filterType);

  const serviceProviderList = yield getState(
    "serviceProvidersReducer",
    "serviceProvidersList"
  );
  const updatedParams = updateFiltersParams(
    currentFilters,
    serviceProviderList
  );

  const pageNumber = resetPage ? 1 : overDuePage + 1;

  try {
    const response = yield apiCall({
      method: "get",
      url: `cases?${paging(pageNumber, pageSize)}&${stringify({
        Filters: updatedParams.filters,
        ServiceProviderReference: updatedParams.serviceProviders
      })}&${stringify(formFilters)}`
    });
    if (response) {
      const updatedPagedResults = yield getUpdatedPagedResults(response);

      const caseListResponse = {
        ...response,
        pagedResults: updatedPagedResults
      }

      const updatedcaseList = yield injectUserDetails(caseListResponse);
      yield all([
        put(setCaseList(moreLoading, updatedcaseList, pageNumber)),
        put({ type: CASE_LIST_CLEAR_ERROR })
      ]);
    }
    
  } catch (e) {
    yield put(
      apiException({
        type: CASE_LIST_ERROR,
        code: e.code || e,
        message: e.message || "Caselist-Fail"
      })
    );
  }
}

function* updateCaseScrollPosition(action) {
  yield put({
    type: CASE_SCROLL_POSITION_UPDATE,
    payload: action.payload
  });
}

export const getCaseList = (payload) => ({
  type: CASE_LIST,
  payload: { ...payload, resetPage: true }
});

export const getCaseListMore = (payload) => ({
  type: CASE_LIST_MORE,
  payload: { ...payload, moreLoading: true }
});

export const updateCaseListFilter = (params = []) => ({
  type: CASE_LIST_FILTER,
  payload: { filters: params }
});

export const updatecaseScroll = (payload) => ({
  type: CASE_SCROLL_POSITION,
  payload
});

export const caseListSpinner = () => ({
  type: CASE_LIST_LOADING
});

export const updateCaseList = (payload) => ({
  type: CASE_LIST_UPDATE,
  payload
});

export const resetCaseList = () => ({
  type: RESET_CASE_LIST
});

export function* caselistGETAction() {
  yield takeLatest([CASE_LIST, CASE_LIST_MORE], caseListGet);
  yield takeLatest([CASE_SCROLL_POSITION], updateCaseScrollPosition);
}

export const caseListReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CASE_LIST: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CASE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.response,
        overDuePage: action.overDuePage
      };
    }
    case CASE_LIST_MORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        overDuePage: action.overDuePage,

        data: {
          pagedResults: [
            ...state.data.pagedResults,
            ...action.response.pagedResults
          ],
          totalCount: action.response.totalCount,
          totalOverdue: action.response.totalOverdue,
          totalDue: action.response.totalDue
        }
      };
    }
    case CASE_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        overDuePage: action.pageNumber,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }

    case CASE_LIST_CLEAR_ERROR: {
      return {
        ...state,
        error: apiErrorObj
      };
    }

    case RESET_CASE_LIST: {
      return {
        ...state,
        error: apiErrorObj,
        data: [],
        loading: true
      };
    }
    case CASE_SCROLL_POSITION_UPDATE: {
      return {
        ...state,
        scrollposition: action.payload
      };
    }

    case CASE_LIST_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case CASE_LIST_UPDATE: {
      const tmpstate = { ...state };
      if (tmpstate?.data?.pagedResults) {
        tmpstate.data.pagedResults.forEach((item) => {
          if (
            item?.caseDetail?.serviceUserDetails?.ServiceUserReference ===
            action.payload.ServiceUserReference
          ) {
            item.caseDetail.serviceUserDetails = {
              ...item.caseDetail.serviceUserDetails,
              ...action.payload
            };
          }
        });
        return tmpstate;
      }
      return state;
    }
    default:
      return state;
  }
};

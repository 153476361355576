export const ROOT = "/";
export const CASE_LIST = "/case-list";
export const CASE_VIEW = "/case-list/:caseId";
export const TRIAGEREPORT_PAGE =
  "/triage-report/:QuestionnaireReference/:serviceuserReferenceId";
export const TODAY_LIST = "/today";

export const USER_LIST = "/users";
export const USER_VIEW = "/users/:serviceuserReferenceId";

export const SERVICE_USER = "/service-user";
export const LOGIN = "/login";
export const LAB_REPORT =
  "/lab-report/:screenReferenceId/:serviceuserReferenceId";
export const MESSAGE_LIST = "/messages";

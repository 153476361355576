import axios from "axios";
import { errorStatusAction } from "../store/error/logic";
import { getCookie } from "../utils/utilsFunction";

const axiosInterceptors = (store) => {
  axios.interceptors.request.use(
    (req) => {
      const authtoken = getCookie("accessToken");

      if (authtoken) {
        req.headers.Authorization = `Bearer ${authtoken}`;
      }
      return req;
    },
    () => {}
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        store.dispatch(errorStatusAction({ errorstatus: error }));
      }
      return Promise.reject(error.response.status);
    }
  );
};

export default { axiosInterceptors };

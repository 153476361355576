import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";
import { taskLogGet } from "../../utils/helper";
import { updateCaseView } from "../caseHeader/logic";

const CARE_UPDATE = "CARE_UPDATE";

const CARE = "CARE";
const CARE_SUCCESS = "CARE_SUCCESS";
const CARE_ERROR = "CARE_ERROR";
const CARE_RESET = "CARE_RESET";
const CARE_UPDATE_SUCESS = "CARE_UPDATE_SUCESS";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: []
};

function* processGet(action) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `Cases/${action.payload.caseReference}`
    });
    const tmpData = response?.processes.filter(
      (item) => item.processReference === action.payload.processReference
    );
    if (tmpData.length) {
      yield taskLogGet({
        payload: tmpData[0],
        type : CARE_UPDATE_SUCESS
      });
    } else {
      yield put({
        type: CARE_SUCCESS,
        payload: {
          processReference: action.payload.processReference,
          process: response?.processes
        }
      });
    }
    yield put(updateCaseView(response));
  } catch (e) {
    yield put(
      apiException({
        type: CARE_ERROR,
        payload: {
          ...action.payload
        },
        code: e.code || e,
        message: e.message || "CareProcesses-Fail"
      })
    );
  }
}

function* taskGetLog(action){
  yield taskLogGet({
    payload: action.payload,
    type : CARE_UPDATE_SUCESS
  });
}

export const getCareProcess = (payload) => ({
  type: CARE,
  payload
});

export const updateCare = (payload) => ({
  type: CARE_UPDATE,
  payload
});

export const resetGetCare = (payload) => ({
  type: CARE_RESET,
  payload
});

export function* processCareGETAction() {
  yield takeLatest([CARE], processGet);
  yield takeLatest([CARE_UPDATE], taskGetLog);
}

export const careReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CARE: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CARE_SUCCESS: {
      const tmpData = action.payload.process.filter(
        (item) => item.processReference === action.payload.processReference
      );
      return {
        ...state,
        loading: false,
        flag: true,
        data: tmpData && tmpData.length && tmpData[0]
      };
    }
    case CARE_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case CARE_RESET: {
      return {
        ...state,
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    case CARE_UPDATE: {
      return {
        ...state,
        loading: false,
        flag: false,
        data: action.payload
      };
    }
    case CARE_UPDATE_SUCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    default:
      return state;
  }
};

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { Loader, Page, Spinner, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { FilterButton } from "../../components/molecules";
import { Filters } from "../../components/organisms";
import { Table, TableHead } from "../../components/organisms/CaseList";
import { SearchListHeader } from "../../components/organisms/Search/SearchListHeader";
import {
  getCaseList,
  getCaseListMore,
  resetCaseList
} from "../../store/caselist/logic";
import { resetFilters } from "../../store/filterlist/actionCreator";
import {
  mainInfiniteScrollHeight,
  scrollContent
} from "../../utils/utilsFunction";
import { AuthenticateTemplate } from "../AuthenticateTemplate";

export const CaseList = ({ filterType }) => {
  const dispatch = useDispatch();
  const searchCountState = useSelector((state) => state.searchCountReducer);

  const caseListData = useSelector((state) => state.caseListReducer);

  const { filters, error: filterError } = useSelector(
    (state) => state.filterListReducer.caseList
  );
  const { error } = caseListData;

  useEffect(() => {
    if (filters?.length > 0) {
      dispatch(getCaseList({ filterType }));
    }
    scrollContent(caseListData, "tablelist-scroll");

    return () => {
      dispatch(resetCaseList());
    };
  }, [filters?.length]);

  const fetchMoreData = () => {
    dispatch(getCaseListMore({ filterType }));
  };

  useEffect(
    () => () => {
      if (filters?.length > 0 && !searchCountState.isSearch) {
        dispatch(resetFilters(filterType, false));
      }
    },
    [filterType, filters?.length, searchCountState.isSearch]
  );

  const filterButton = () => (
    <>
      <FilterButton />
      {searchCountState?.isSearch?.case && (
        <Wrapper className="editsearch-container-web">
          <SearchListHeader pageType={filterType} />
        </Wrapper>
      )}
    </>
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Wrapper
          flex
          fw
          className={`caselist__container ${
            searchCountState?.isSearch?.case && "search-enabled"
          }`}>
          <Page
            title="Cases"
            miniActions={filterButton()}
            sideBar={<Filters filterType={filterType} showDefaultFilters />}>
            {searchCountState?.isSearch?.case && (
              <Wrapper className="editsearch-container-mobile">
                <SearchListHeader pageType={filterType} />
              </Wrapper>
            )}
            <TableHead />

            <Loader
              loading={filterError.isError ? false : caseListData.loading}
              error={error.isError}
              errorMessage={filterError.message || error.message}
              errorCode={error.code}
              className="todaylist-loader"
              height="70vh"
              noData={
                !caseListData || !caseListData?.data?.pagedResults?.length
              }>
              <InfiniteScroll
                className=" case-list-scroll smothscroll scrollbar"
                dataLength={caseListData?.data?.pagedResults?.length || 0}
                next={fetchMoreData}
                hasMore={
                  caseListData?.data?.pagedResults?.length <
                  caseListData?.data?.totalCount
                }
                scrollThreshold={0.9}
                height={mainInfiniteScrollHeight()}
                loader={<Spinner size={48} />}
                width="100%">
                <Wrapper tabIndex={0}>
                  <Table
                    data={caseListData.data.pagedResults}
                    title="Overdue"
                  />
                </Wrapper>
              </InfiniteScroll>
            </Loader>
          </Page>
        </Wrapper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};
CaseList.defaultProps = {
  filterType: "caseList"
};
CaseList.propTypes = {
  filterType: PropTypes.string
};

import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "preventx-storybook";

export const NotificationCard = ({ children, className, elemRef }) => (
  <Wrapper className={className} elemRef={elemRef}>
    {children}
  </Wrapper>
);

NotificationCard.defaultProps = { children: "", className: "" };

NotificationCard.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  elemRef: PropTypes.element.isRequired
};

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import {
  AdditionalMetrics,
  EventLog,
  FlagReport,
  Laboratory,
  Page,
  PatientDetails,
  RecordSummary,
  Samples,
  translate,
  Wrapper,
  TestBookings
} from "preventx-storybook";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  geteventLog,
  geteventLogMore,
  reseteventLog
} from "../store/eventlog/logic";
import { getAdditionalMetrics } from "../store/additionalmetrics/logic";
import { getLabAccreditation } from "../store/laboratory/logic";
import { getrecordSummary } from "../store/recordsummary/logic";
import { getsampleReceiveds } from "../store/samplereceived/logic";
import { getTestBookings } from "../store/testBookings/logic";
import { AuthenticateTemplate } from "./AuthenticateTemplate";
import { getserviceUserReference } from "../store/serviceuserreference/logic";

export const LabReports = () => {
  const history = useHistory();

  const isSccResult = process.env.REACT_APP_LABREPORT_PROVIDER === "SCC";
  const { screenReferenceId, serviceuserReferenceId } = useParams();

  const dispatch = useDispatch();
  const sampleReceivedState = useSelector(
    (state) => state.sampleReceivedReducer
  );
  const additionalMetricsState = useSelector(
    (state) => state.additionalMetricsReducer
  );
  const recordSummaryState = useSelector((state) => state.recordSummaryReducer);
  const testBookingState = useSelector((state) => state.testBookingReducer);

  const activityLogState = useSelector((state) => state.eventLogReducer);
  const laboratory = useSelector((state) => state.laboratoryAccreditateReducer);
  const serviceUserReferenceState = useSelector(
    (state) => state.serviceUserReferenceReducer
  );

  useEffect(() => {
    dispatch(
      getsampleReceiveds({
        screenReference: screenReferenceId
      })
    );

    dispatch(
      getAdditionalMetrics({
        screenReference: screenReferenceId
      })
    );

    dispatch(
      getrecordSummary({
        screenReference: screenReferenceId
      })
    );

    dispatch(
      geteventLog({
        screenReference: screenReferenceId
      })
    );

    dispatch(
      getTestBookings({
        screenReference: screenReferenceId
      })
    );

    dispatch(
      getLabAccreditation({
        screenReference: screenReferenceId
      })
    );
    dispatch(
      getserviceUserReference({
        serviceUserReference: serviceuserReferenceId,
        profile: "LabReport"
      })
    );
    return () => {
      dispatch(reseteventLog());
    };
  }, []);

  const handlerfetchMoreData = () => {
    dispatch(
      geteventLogMore({
        screenReference: screenReferenceId
      })
    );
  };
  const redirectHandler = (link) => {
    window.open(link);
  };
  const { t } = translate();

  let dilutionFactor;let translationKey;let translatedVal;let ctValue ;let vlValue ;let upperLimit;


  // dilution factor is captured and translated as part of below code

  if(testBookingState?.data?.bookings) {
      const isHepcRnaCaseFound = testBookingState?.data?.bookings.findIndex(el => el.testCode === "rx-hcv-rna");
      const HepcRnaCase = testBookingState?.data?.bookings[isHepcRnaCaseFound];
      if(HepcRnaCase){
      // if isInsufficient flag is false then there will be no dilution factor.Dilution factor cannot be null
      if(!HepcRnaCase.specimen.dilution || HepcRnaCase.specimen?.dilution === null || HepcRnaCase.specimen.dilution?.isInsufficient === "true"  ){
        dilutionFactor = "Insufficient"
      } else {
        dilutionFactor = HepcRnaCase.specimen.dilution.multipleFactor;
      }
      if(HepcRnaCase.specimen.dilution?.upperTiterLimit) {
        upperLimit = HepcRnaCase.specimen.dilution.upperTiterLimit
      }
      
     
      translationKey = `${HepcRnaCase.testComponents[0].code}-${HepcRnaCase.testComponents[0].results[0].caseCodeLocalisationKey}-desc`;
      ctValue = HepcRnaCase?.testComponents?.map((testVal) => testVal?.results?.map(r => r?.observations?.map(o => `${o.value} ${o.uoMLocalisationKey}` ))).join(' ');
      if(translationKey !=="hcv-rna-not-detected-desc"){
      const viralLoadValue = HepcRnaCase.testComponents
      .flatMap(tc => tc.results)
      .flatMap(r => r.subResults ?? [])
      .find(sr => sr.code === 'HCV_RNA_CalcWithDilution');

      if(viralLoadValue?.value) {
       vlValue = viralLoadValue.value
      }else {
        vlValue = 0;
      }
    }
       translatedVal = t(translationKey, { DF: dilutionFactor, Ct:ctValue , viralLoadDilutionFactor:vlValue , upperTiterLimit: upperLimit});
    }
  }
  

  return (
    <>
      <AuthenticatedTemplate>
        <Page title={`${t("Lab-Record")} ${serviceuserReferenceId}`}>
          <Wrapper flex justify="space-between" className="cup-labreport">
            <Wrapper className="cup-labreport__report">
              <Wrapper fw className="cup-labreport__report-md">
                <Wrapper fw>
                  <RecordSummary
                    isLoading={recordSummaryState.loading}
                    isError={recordSummaryState.error.isError}
                    errorCode={recordSummaryState.error.code}
                    errorMessage={recordSummaryState.error.message}
                    summary={recordSummaryState?.data || {}}
                    isSccResult={!isSccResult}
                  />
                </Wrapper>
                <Wrapper
                  fw
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px"
                  }}>
                  <PatientDetails
                    isLoading={
                      serviceUserReferenceState.loading ||
                      recordSummaryState.loading
                    }
                    isError={serviceUserReferenceState.error.isError}
                    errorCode={serviceUserReferenceState?.error?.code}
                    errorMessage={{
                      404: "Patient-Record-Not-Found"
                    }}
                    summary={{
                      ...serviceUserReferenceState?.data,
                      ...recordSummaryState?.data
                    }}
                    handler={() => {
                      history.push(`/users/${serviceuserReferenceId}`);
                    }}
                  />

                  {additionalMetricsState?.data?.flags &&
                    !!additionalMetricsState?.data?.flags.length && (
                      <FlagReport
                        isLoading={additionalMetricsState.loading}
                        isError={additionalMetricsState.error.isError}
                        summary={additionalMetricsState?.data?.flags || []}
                        loaderHeight="60px"
                      />
                    )}
                  
                  {!isSccResult && <AdditionalMetrics
                    isLoading={additionalMetricsState.loading}
                    isError={additionalMetricsState.error.isError}
                    summary={additionalMetricsState?.data?.metrics || {}}
                  />}
                </Wrapper>
              </Wrapper>
              {!isSccResult && <EventLog
                isLoading={activityLogState.loading}
                isError={activityLogState.error.isError}
                summary={activityLogState?.data || {}}
                InfiniteScroll={InfiniteScroll}
                handlerfetchMoreData={handlerfetchMoreData}
                loaderHeight="40vh"
              /> }
              {laboratory?.data?.isAccreditedLaboratory && (
                <Laboratory
                  handler={(link) => redirectHandler(link)}
                  isLoading={laboratory.loading}
                  isError={laboratory.error.isError}
                  errorCode={laboratory.error.code}
                  errorMessage={laboratory.error.message}
                />
              )}
            </Wrapper>

            <Wrapper className="cup-labreport__report">
              <Samples
                isError={sampleReceivedState.error?.isError}
                isLoading={sampleReceivedState.loading}
                errorCode={sampleReceivedState?.error?.code}
                errorMessage={sampleReceivedState?.error?.message}
                summary={sampleReceivedState?.data?.specimens || []}
                isSccResult={!isSccResult}
              />
              <TestBookings
                isError={testBookingState.error.isError}
                errorCode={testBookingState.error.code}
                errorMessage={testBookingState.error.message}
                isLoading={testBookingState.loading}
                summary={testBookingState?.data?.bookings}
                translatedValue ={translatedVal}
                isSccResult={!isSccResult}
              />
            </Wrapper>
          </Wrapper>
        </Page>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

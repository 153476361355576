/* eslint-disable no-unused-vars */
import { call, put, takeLatest, all } from "redux-saga/effects";
import { apiCall, apiException, paging } from "../../services/api";
import { getSuppressedMenus } from "../../utils/settings";
import { setNavCasesCount } from "./actionCreator";
import { GET_NAV_CASES_COUNT, SET_NAV_CASES_COUNT_ERROR } from "./actiontypes";

const getTodayListCount = async () => {
  try {
    const response = await apiCall({
      method: "get",
      url: `Worklists?${paging(1, 1)}`
    });
    localStorage.setItem("workListCount", response?.totalCount);
    return response?.totalCount;
  } catch (error) {
    localStorage.setItem("workListCount", "");
    const err = { code: error, message: "Worklist-Count-Fail" };
    return Promise.reject(err);
  }
};

const getUnReadMessagesCount = async () => {
  try {
    const response = await apiCall({
      method: "get",
      apiType: "messaging",
      url: `conversations/unreadcount`
    });
    localStorage.setItem("unReadMessagesCount", response?.totalUnreadCount);
    return response?.totalUnreadCount;
  } catch (error) {
    localStorage.setItem("unReadMessagesCount", "");
    const err = { code: error, message: "UnRead-Messages-Count-Fail" };
    return Promise.reject(err);
  }
};

const getCaseListCount = async () => {
  try {
    const response = await apiCall({
      method: "get",
      url: `cases?${paging(1, 1)}`
    });
    localStorage.setItem("caseListCount", response?.totalCount);
    return response?.totalCount;
  } catch (error) {
    localStorage.setItem("caseListCount", "");
    const err = { code: error, message: "Caselist-Count-Fail" };
    return Promise.reject(err);
  }
};

const getFreetestMeCount = async () => {
  try {
    const response = await apiCall({
      method: "get",
      url: "worklists/freetestme/totalcount"
    });
    localStorage.setItem("freetestMeCount", response?.totalCount);
    return response?.totalCount;
  } catch (error) {
    localStorage.setItem("freetestMeCount", "");
    const err = { code: error, message: "FreetestMe-Count-Fail" };
    return Promise.reject(err);
  }
};

function* handleMenuItem(fetchData, stateProperty) {
  try {
    yield put(setNavCasesCount({ [stateProperty]: yield call(fetchData) }));
  } catch (e) {
    yield put(
      apiException({
        type: SET_NAV_CASES_COUNT_ERROR,
        code: e.code || e,
        message: e.message
      })
    );
  }
}

function* getCasesCount({ data, menuName }) {
  // menus needs to be hidden are coming from backend
  const suppressedMenus = getSuppressedMenus(data);

  // config to track when incoming menuName is received as "all", "todayList", "caseList"
  const menuConfig = {
    all: [
      {
        displayName: "Today",
        fetchData: getTodayListCount,
        stateProperty: "todayListCount"
      },
      {
        displayName: "Cases",
        fetchData: getCaseListCount,
        stateProperty: "caseListCount"
      },
      {
        displayName: "Messages",
        fetchData: getUnReadMessagesCount,
        stateProperty: "unReadMessagesCount"
      },
      {
        displayName: "FreeTestSmartKits",
        fetchData: getFreetestMeCount,
        stateProperty: "freetestMeCount"
      }
    ],
    todayList: [
      {
        displayName: "Today",
        fetchData: getTodayListCount,
        stateProperty: "todayListCount"
      }
    ],
    caseList: [
      {
        displayName: "Cases",
        fetchData: getCaseListCount,
        stateProperty: "caseListCount"
      }
    ]
  };

  // capture the target menu to be shown
  const menuItem = menuConfig[menuName] || [];

  // once backend data is populated then trigger the generator function
  if (suppressedMenus.length) {
    yield all(
      menuItem.map(({ displayName, fetchData, stateProperty }) => {
        if (!suppressedMenus.includes(displayName)) {
          return call(handleMenuItem, fetchData, stateProperty);
        }
        return null; // or return any other value if needed
      })
    );
  }
}

export function* navbarCasesCountGETAction() {
  yield takeLatest([GET_NAV_CASES_COUNT], getCasesCount);
}

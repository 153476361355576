import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const GET_TEST_BOOKINGS = "GET_TEST_BOOKINGS";
const TEST_BOOKINGS_SUCCESS = "TEST_BOOKINGS_SUCCESS";
const TEST_BOOKINGS_ERROR = "TEST_BOOKINGS_ERROR";

const initialState = {
  loading: true,
  error: apiErrorObj,
  data: {}
};

function* testBookingsGET(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "Laboratory",
      url: `screens/${action.payload.screenReference}/servicerequests`
    });

    yield put({
      type: TEST_BOOKINGS_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: TEST_BOOKINGS_ERROR,
        code: e.code || e,
        message: e.message || "TestBookings-Fail"
      })
    );
  }
}

export const getTestBookings = (payload) => ({
  type: GET_TEST_BOOKINGS,
  payload
});

export function* testBookingsGETAction() {
  yield takeLatest([GET_TEST_BOOKINGS], testBookingsGET);
}

export const testBookingReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case GET_TEST_BOOKINGS: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case TEST_BOOKINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...action.payload }
      };
    }

    case TEST_BOOKINGS_ERROR: {
      return {
        ...state,
        loading: false,
        data: [],
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }
    default:
      return state;
  }
};

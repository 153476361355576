import {
  Button,
  DateRow,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  Typography,
  Wrapper
} from "preventx-storybook";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DATE_FORMAT } from "../../../constants/defaults";
import EditTaskForm from "../../../json/edittaskForm";
import NewTaskForm from "../../../json/newtaskForm";
import { addDay, dropdownConverter } from "../../../utils/utilsFunction";
import { FormBuilder } from "../../molecules";

export const NewTask = ({
  showModal,
  toggleModal,
  inputModel,
  handlerSubmit,
  onChangeHandle,
  handlerDueDateChange,
  processTypeCode,
  editData,
  isPrimaryLoading
}) => {
  const [newForm, setNewForm] = useState(NewTaskForm);
  const taskTypeState = useSelector((state) => state.taskTypeReducer);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const setDropdownValue = (taskData) => {
    const tmpForm = [...newForm];
    tmpForm.forEach((item) => {
      if (item.name === "taskTypeId") {
        item.options = dropdownConverter(
          taskData.data[processTypeCode],
          "localisationKey",
          "id"
        );
      }
    });
    setNewForm(tmpForm);
  };

  useEffect(() => {
    if (taskTypeState.data[processTypeCode]) {
      setDropdownValue(taskTypeState);
    }
    reset();
  }, [showModal]);

  useEffect(() => {
    if (
      Object.keys(taskTypeState.data).length &&
      taskTypeState.data[processTypeCode] &&
      !Object.keys(editData).length
    ) {
      setDropdownValue(taskTypeState);
    }
  }, [taskTypeState]);

  const onFormChange = (obj) => {
    onChangeHandle(obj);
  };

  const onSubmit = (formObj) => {
    handlerSubmit(formObj);
  };

  const changeDuedate = (day) => {
    const dt = addDay(day, DATE_FORMAT);
    handlerDueDateChange(dt);
    setValue("dueDate", dt, { shouldValidate: true });
  };

  return (
    <ModalLayout
      className="NewTaskModal"
      showModal={showModal}
      onCloseModal={toggleModal}>
      <ModalHeader
        headerTitle={
          Object.keys(editData).length ? "Edit due date" : "Create new task"
        }
        onCloseModal={toggleModal}
      />
      <ModalBody>
        {!!Object.keys(editData).length && (
          <Wrapper className="edit-task-block">
            <Wrapper
              flex
              style={{ "flex-direction": "column", "margin-bottom": "24px" }}>
              <Typography size={18} weight="medium">
                task-dueDate
              </Typography>
              <Typography
                size={18}
                weight="medium"
                style={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
                {editData?.taskTypeLocalisationKey}
              </Typography>
            </Wrapper>
            <Wrapper style={{ "margin-bottom": "24px" }}>
              <Typography size={18} weight="medium">
                current-dueDate
              </Typography>
              <DateRow inputDate={editData.dueDate} format="DD MMM YYYY" />
            </Wrapper>
          </Wrapper>
        )}
        <form>
          {Object.keys(editData).length > 0 ? (
            <FormBuilder
              formjson={EditTaskForm}
              inputModel={inputModel}
              errors={errors}
              control={control}
              onChangeHandle={onFormChange}
            />
          ) : (
            <FormBuilder
              formjson={newForm}
              inputModel={inputModel}
              errors={errors}
              control={control}
              onChangeHandle={onFormChange}
            />
          )}
          <Wrapper className="modal-date-container">
            <Button
              handler={() => changeDuedate(1, DATE_FORMAT)}
              variant="tertiary">
              Tomorrow
            </Button>
            <Button
              handler={() => changeDuedate(7, DATE_FORMAT)}
              variant="tertiary">
              in-7-days
            </Button>
            <Button
              handler={() => changeDuedate(30, DATE_FORMAT)}
              variant="tertiary">
              in-30-days
            </Button>
          </Wrapper>
        </form>
      </ModalBody>
      <ModalFooter
        primaryText={
          Object.keys(editData).length ? "save-changes" : "Create-Task"
        }
        handlerSecondary={toggleModal}
        handlerPrimary={() => {
          handleSubmit(onSubmit)();
        }}
        secondaryText="Cancel"
        isPrimaryLoading={isPrimaryLoading}
      />
    </ModalLayout>
  );
};

NewTask.defaultProps = {
  inputModel: {},
  editData: {},
  isPrimaryLoading: false
};

NewTask.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handlerSubmit: PropTypes.func.isRequired,
  onChangeHandle: PropTypes.func.isRequired,
  handlerDueDateChange: PropTypes.func.isRequired,
  inputModel: PropTypes.instanceOf(Object),
  processTypeCode: PropTypes.string.isRequired,
  editData: PropTypes.instanceOf(Object),
  isPrimaryLoading: PropTypes.bool
};

import {
  errorStatusNotificationAction,
  statusNotificationAction
} from "../store/notification/logic";

const notificationMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action?.notificationType === "SUCCESS") {
      dispatch(
        statusNotificationAction({
          type: action?.type,

          message: action?.message || "Successfull"
        })
      );
    }
    if (action?.notificationType === "FAILURE") {
      dispatch(
        errorStatusNotificationAction({
          ...action,
          message: action?.message || "Error-500"
        })
      );
    }
    next(action);
  };

export default notificationMiddleware;

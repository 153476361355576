import { Button, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";

export const NotificationFooter = ({
  title,
  overrideClass,
  handler,
}) => (
  <Wrapper className="notification-footer" handler={handler}>
    <Button className={`notification-footer__button ${overrideClass}`}>
      {title}
    </Button>
  </Wrapper>
);

NotificationFooter.defaultProps = {
  // children: "",
  title: "",
  overrideClass: "",
};

NotificationFooter.propTypes = {
  // children: PropTypes.string,
  title: PropTypes.string,
  overrideClass: PropTypes.string,
  handler: PropTypes.func.isRequired,
};

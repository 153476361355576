import { Typography, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";

export const NotificationUpdated = ({ unRead, read }) => (
  <Wrapper className="notification-updated">
    {unRead && (
      <Wrapper className="notification-updated__container">
        <Wrapper className="notification-updated__heading">
          <Typography>up-to-date-message</Typography>
        </Wrapper>
        <Wrapper className="notification-updated__message">
          <Typography>notifications-read-message</Typography>
        </Wrapper>
      </Wrapper>
    )}
    {read && (
      <Wrapper className="notification-updated__container">
        <Wrapper className="notification-updated__heading">
          <Typography>no-read-notifications</Typography>
        </Wrapper>
        <Wrapper className="notification-updated__message">
          <Typography>read-notifications-display</Typography>
        </Wrapper>
      </Wrapper>
    )}
  </Wrapper>
);

NotificationUpdated.defaultProps = {
  unRead: false,
  read: false
};

NotificationUpdated.propTypes = {
  unRead: PropTypes.bool,
  read: PropTypes.bool
};

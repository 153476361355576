import _ from "lodash";
import { stringify } from "query-string";
import { all, put, takeLatest } from "redux-saga/effects";
import { apiErrorObj, DEFAULT_PAGE_SIZE } from "../../constants/defaults";
import { apiCall, apiException, paging } from "../../services/api";
import {
  getState,
  pageStateDetails,
  serviceUserRefCollection
} from "../../utils/helper";
import {
  CLEAR_SERVICE_USER_REFERENCES_ERROR,
  GET_SERVICE_USERS_BY_REFERENCES,
  GET_SERVICE_USER_REFERENCES,
  GET_SERVICE_USER_REFERENCES_MORE,
  RESET_USER_LIST,
  SERVICE_USER_REFERENCES_ERROR,
  SERVICE_USER_REFERENCES_MORE_SUCCESS,
  SERVICE_USER_REFERENCES_SUCCESS,
  SET_PLATFORM_USER_NAME,
  SET_SERVICE_USER_DETAILS,
  UPDATE_SCROLL_POSITION,
  UPDATE_USER_LIST
} from "./actionTypes";

const initialState = {
  loading: true,
  error: apiErrorObj,
  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  scrollposition: 0,
  serviceUserReferences: [],
  serviceUserDetails: {},
  platformUserName: {},
  // to be removed later
  data: {
    FirstName: "Jaxson",
    AssignedSex: "female",
    ContactPreferenceOrder: ["mobilenumber", "email"],
    CountryCode: "UK",
    CountryOfBirthIsoCode: "UNITED KINGDOM",
    DateOfBirth: "1962-02-13T00:00:00.0000000Z",
    EmailAddress: "Walstreet_Wolff@hotmail.com",
    EthnicityLocalisationKey: "Asian",
    GenderIdentity: "female",
    Gender: "Straight",
    LastName: "Levin",
    MobileNumberCountryCode: "+44",
    MobileNumber: "9902771821",
    MobileNumberLastVerified: "2022-02-04T04:22:36.7001542Z",
    LastLogin: "2022-02-04T04:22:36.7001542Z",
    OrganisationReferral: "SHL.UK",
    Postcode: "LS11 5AD",
    PrimaryAddress: ["10 Downing Street", "Manhattan Mews", "London"],
    PronounsLocalisationKey: "She-her",
    ReferralType: "no-referrer",
    RegistrationDate: "2022-02-13T00:00:00.0000000Z",
    ServiceProviderReference: "SP23",
    SexualOrientationLocalisationKey: "Straight",
    AccountStatusLocalisationKey: "Active",
    ServiceUserReference: "SU30000723",
    OpenCases: "10",
    ClosedCases: "10"
  }
};

const setUserList = (moreLoading, response, pageNumber) => ({
  type: moreLoading
    ? SERVICE_USER_REFERENCES_MORE_SUCCESS
    : SERVICE_USER_REFERENCES_SUCCESS,
  response,
  pageNumber
});

function* getServiceUserReferences(action) {
  const { filterType, resetPage, moreLoading } = action.payload;
  const { currentFilters, pageNumber, pageSize, formFilters } =
    yield pageStateDetails(filterType);

  const page = resetPage ? 1 : pageNumber + 1;
  yield put({ type: CLEAR_SERVICE_USER_REFERENCES_ERROR });
  try {
    const response = yield apiCall({
      method: "get",
      url: `serviceusers?${paging(page, pageSize)}&${stringify({
        Filters: currentFilters
      })}&${stringify(formFilters)}`
    });

    if (response.pagedResults.length > 0) {
      const serviceUserReferences = serviceUserRefCollection(
        response?.pagedResults || []
      );

      const serviceUserCaseCounts = yield getServiceUserCaseCount(
        serviceUserReferences
      );

      const updatedResponse = yield getUserDetailsByReferences({
        serviceUserReferences
      });

      _.forEach(serviceUserCaseCounts, (user) => {
        updatedResponse[user.serviceUserReference] = {
          ...updatedResponse[user.serviceUserReference],
          openCount: user.openCount,
          closedCount: user.closedCount
        };
      });
      response.pagedResults = updatedResponse;
    }
    yield all([put(setUserList(moreLoading, response, page))]);
  } catch (e) {
    yield put(
      apiException({
        type: SERVICE_USER_REFERENCES_ERROR,
        code: e.code || e,
        message: e.message || "ServiceUserList-Fail"
      })
    );
  }
}

export function* getUserDetailsByReferences(action) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "serviceusers",
      url: `serviceusers?profile=userdetails&${stringify({
        serviceUserReference: action.serviceUserReferences
      })}`
    });
    return response;
  } catch (e) {
    const err = { code: e, message: "ServiceUser-Profile-Fail" };
    throw err;
  }
}

export function* getServiceUserCaseCount(serviceUserReferences = []) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "clinicalcases",
      url: `cases/casecount?${stringify({
        ServiceUserReferences: serviceUserReferences
      })}`
    });
    return response;
  } catch (e) {
    const err = { code: e, message: "ServiceUser-CaseCount-Fail" };
    throw err;
  }
}

export function* userDetailsGETAction() {
  yield takeLatest(
    [GET_SERVICE_USER_REFERENCES, GET_SERVICE_USER_REFERENCES_MORE],
    getServiceUserReferences
  );
  yield takeLatest(
    [GET_SERVICE_USERS_BY_REFERENCES],
    getUserDetailsByReferences
  );
}

export function* filterAndFetchUsers(userReferences = []) {
  const { serviceUserDetails } = yield getState("userDetailsReducer");

  const fetchedKeys = _.keys(serviceUserDetails);

  /* Filter the user references that are not present in both arrays. 
     This is used to optimize the api call.
  */
  const keysToFetch = _.filter(
    userReferences,
    (u) => fetchedKeys.indexOf(u) < 0
  );

  if (keysToFetch.length > 0) {
    try {
      const response = yield apiCall({
        method: "get",
        apiType: "serviceusers",
        url: `serviceusers?profile=userdetails&${stringify({
          serviceUserReference: keysToFetch
        })}`
      });
      yield put({
        type: SET_SERVICE_USER_DETAILS,
        response
      });
      return { ...serviceUserDetails, ...response };
    } catch (e) {
      const err = { code: e, message: "Caselist-Serviceusers-Fail" };
      throw err;
    }
  }
  return serviceUserDetails;
}

export const userDetailsReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case GET_SERVICE_USER_REFERENCES: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case CLEAR_SERVICE_USER_REFERENCES_ERROR: {
      return {
        ...state,
        error: apiErrorObj
      };
    }
    case SERVICE_USER_REFERENCES_SUCCESS: {
      return {
        ...state,
        pageNumber: action.pageNumber,
        serviceUserDetails: action.response,
        loading: false
      };
    }
    case SERVICE_USER_REFERENCES_MORE_SUCCESS: {
      return {
        ...state,
        pageNumber: action.pageNumber,
        serviceUserDetails: {
          pagedResults: {
            ...state.serviceUserDetails.pagedResults,
            ...action.response.pagedResults
          },
          totalCount: action.response.totalCount
        },
        loading: false
      };
    }
    case SERVICE_USER_REFERENCES_ERROR: {
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        }
      };
    }

    case RESET_USER_LIST: {
      return {
        ...state,
        loading: true,
        serviceUserDetails: {},
        error: apiErrorObj
      };
    }

    case SET_SERVICE_USER_DETAILS: {
      return {
        ...state,
        loading: false,
        serviceUserDetails: { ...state.serviceUserDetails, ...action.response }
      };
    }

    case SET_PLATFORM_USER_NAME: {
      return {
        ...state,
        platformUserName: { ...state.platformUserName, ...action.data }
      };
    }

    case UPDATE_SCROLL_POSITION: {
      return {
        ...state,
        scrollposition: action.payload
      };
    }

    case UPDATE_USER_LIST: {
      const tmpstate = { ...state };
      const user =
        state?.serviceUserDetails?.pagedResults &&
        state?.serviceUserDetails?.pagedResults[
          action.payload.ServiceUserReference
        ];
      if (user) {
        tmpstate.serviceUserDetails.pagedResults[
          action.payload.ServiceUserReference
        ] = {
          ...state?.serviceUserDetails?.pagedResults[
            action.payload.ServiceUserReference
          ],
          ...action?.payload
        };
      }
      return user ? tmpstate : state;
    }

    default:
      return state;
  }
};

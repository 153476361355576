import { put, takeLatest } from "redux-saga/effects";
import { apiCall, apiException } from "../../services/api";
import {
  CASE_VIEW_TYPE_HEPC,
  CASE_HEPC_GETODNLIST_SUCCESS,
  CASE_HEPC_GETODNLIST_ERROR,
  GET_PORTALTYPE,
  GET_PORTALTYPE_SUCCESS,
  GET_PORTALTYPE_ERROR,
  GET_SERVICE_PROVIDER_SETTINGS_REQUEST,
  GET_SERVICE_PROVIDER_SETTINGS_SUCCESS
} from "./actionType";
import { HEPC } from "../../constants/defaults";

function* getServiceProviderSettings(action) {
  try {
    const { payload } = action;
    const res = yield apiCall({
      method: "get",
      apiType: "serviceproviders",
      url: `serviceproviders/${payload}/setting/CUPSettings`
    });
    const response = JSON.parse(res?.value);
    const noteOutcomeSettingsData = response.find(
      (setting) => setting.Key === "NoteOutComeSettings"
    );
    const {
      outcomeDefaultValue,
      outcomeDetailsDefaultValue,
      careTransferToList
    } = JSON.parse(noteOutcomeSettingsData?.Value);
    if (
      outcomeDefaultValue &&
      outcomeDetailsDefaultValue &&
      careTransferToList
    ) {
      yield put({
        type: GET_SERVICE_PROVIDER_SETTINGS_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: GET_PORTALTYPE,
        payload
      });
    }
  } catch (e) {
    yield put({
      type: GET_PORTALTYPE,
      payload: action.payload
    });
  }
}

function* caseViewTypeHepc(action) {
  const { payload } = action;
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "serviceproviders",
      url: `serviceproviders/${payload}/odnlist`
    });

    yield put({
      type: CASE_HEPC_GETODNLIST_SUCCESS,
      payload: response
    });
  } catch (e) {
    yield put(
      apiException({
        type: CASE_HEPC_GETODNLIST_ERROR,
        code: e.code || e,
        message: e.message || "OdnListFetch-Fail"
      })
    );
  }
}

function* getPortalType(action) {
  const { payload } = action;
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "serviceproviders",
      url: `serviceproviders/${payload}/portaltype`
    });
    yield put({
      type: GET_PORTALTYPE_SUCCESS,
      payload: response
    });

    if (response?.portalName === HEPC) {
      yield put({
        type: CASE_VIEW_TYPE_HEPC,
        payload
      });
    }
  } catch (e) {
    yield put(
      apiException({
        type: GET_PORTALTYPE_ERROR,
        code: e.code || e,
        message: e.message || "PortalTypeFetch-Fail"
      })
    );
  }
}

export function* showOdnListForHepcAction() {
  yield takeLatest(
    [GET_SERVICE_PROVIDER_SETTINGS_REQUEST],
    getServiceProviderSettings
  );
  yield takeLatest([CASE_VIEW_TYPE_HEPC], caseViewTypeHepc);
  yield takeLatest([GET_PORTALTYPE], getPortalType);
}
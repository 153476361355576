import { Tag, TodayList, Typography, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

export const Table = ({ data, title, totalOverdue, totalDue }) => {
  const history = useHistory();

  const renderViewCase = (caseDetail) => {
    const { caseReference } = caseDetail;
    history.push(`/case-list/${caseReference}`);
  };

  return (
    <div className="Table today_list_container">
      <Wrapper>
        <div className="Table__Body">
          {totalOverdue > 0 && (
            <Wrapper className="today_list_container__overdue ">
              <Typography
                translate
                className="today_list_container__overdue-text">
                {title}
              </Typography>
              <Tag variant="overdue" text={totalOverdue} />
            </Wrapper>
          )}
          <TodayList
            data={data}
            totalDue={totalDue}
            totalOverdue={totalOverdue}
            handlerRowClick={renderViewCase}
          />
        </div>
      </Wrapper>
    </div>
  );
};

Table.defaultProps = {
  data: [],
  title: "",
  totalDue: 0,
  totalOverdue: 0
};

Table.propTypes = {
  data: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  totalDue: PropTypes.number,
  totalOverdue: PropTypes.number
};

import _ from "lodash";
import { BackDrop, SliderMenu, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import * as qs from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { sideBarMenus } from "../../../../constants/sideBarMenus";
import { minWindowWidth } from "../../../../utils/utilsFunction";
import { useWindow } from "../../../hooks";

const RightMenus = ({ defaultMenu, ignoreMenu, history, isDisabled }) => {
  let { tab } = qs.parse(history.location.search);
  const activeMenu = useRef(tab || defaultMenu);
  const [content, setContent] = useState();
  const [showPanel, setPanel] = useState(true);
  const [backDrop, setBackdrop] = useState(false);
  const [active, setActive] = useState(-1);
  const initialWidth = useRef(0);

  const { triggerEdit, logCareOutcome, triggerUserNotes } = useSelector(
    (state) => state.notesReducer.form
  );

  const closeSection = () => {
    setBackdrop(false);
    setPanel(false);
    setContent(null);
  };

  const getComponent = () => {
    if (isDisabled) {
      return "";
    }
    const { component } =
      _.find(sideBarMenus, { menuName: activeMenu.current }) || {};
    return component;
  };

  const getActive = (item) => {
    if (!isDisabled) {
      return setActive(item);
    }
    return setActive(-1);
  };

  useEffect(() => {
    if (isDisabled) {
      setActive(-1);
      setContent(null);
    }
  }, [isDisabled]);

  useEffect(() => {
    let component = getComponent();

    if (component) {
      setContent(component);
      getActive(activeMenu.current);
    } else {
      activeMenu.current = defaultMenu;
      history.push({
        pathname: history.location.pathname,
        search: `?tab=${defaultMenu}`
      });
      component = getComponent();
    }
  }, [defaultMenu, isDisabled]);

  useEffect(() => {
    const historyupdate = history.listen((obj) => {
      if (
        obj.pathname.includes("case-list") &&
        obj.pathname.split("/").length > 2 &&
        !isDisabled
      ) {
        ({ tab } = qs.parse(obj.search));
        const { component } = _.find(sideBarMenus, { menuName: tab }) || {};
        if (component) {
          setContent(component);
          getActive(tab || defaultMenu);
        }
      }
    });

    return () => {
      historyupdate();
    };
  }, [history]);

  const handler = ({ item }) => {
    const { component, menuName } = item;

    if (menuName === active && minWindowWidth()) {
      closeSection();
      getActive(-1);
      activeMenu.current = null;
      return;
    }

    setContent(component);
    getActive(menuName);
    activeMenu.current = menuName;

    history.push({
      pathname: history.location.pathname,
      search: `?tab=${menuName}`
    });

    if (!showPanel) {
      setPanel(true);
    }
    if (minWindowWidth() && !backDrop) {
      setBackdrop(true);
    }
  };

  const toggleBackDrop = () => {
    if (minWindowWidth() && content) {
      setBackdrop(true);
    } else {
      setBackdrop(false);
    }

    if (!minWindowWidth() && active < 0) {
      ({ tab } = qs.parse(history.location.search));
      const { component } = _.find(sideBarMenus, { menuName: tab }) || {};
      if (component) {
        setContent(component);
        getActive(tab || defaultMenu);
        setPanel(true);
      }
    }
    if (minWindowWidth() && initialWidth.current !== window.innerWidth) {
      setActive(-1);
      closeSection();
      initialWidth.current = window.innerWidth;
    }
  };

  useWindow(toggleBackDrop, []);

  useEffect(() => {
    toggleBackDrop();
  }, [minWindowWidth()]);

  useEffect(() => {
    // To trigger the notes click when the user click on the edit outcome from the left side screen
    if (triggerEdit || logCareOutcome) {
      const item = _.find(sideBarMenus, { menuName: "case-notes" });
      if (item) {
        handler({ item });
      }
    }
  }, [triggerEdit, logCareOutcome]);

  useEffect(() => {
    // To trigger the notes click when the user click on the edit outcome from the left side screen
    if (triggerUserNotes) {
      const item = _.find(sideBarMenus, { menuName: "user-notes" });
      if (item) {
        handler({ item });
      }
    }
  }, [triggerUserNotes]);

  const hideMenu = (item) => {
    if (typeof item === "string") {
      return _.filter(sideBarMenus, ({ menuName }) => menuName !== item);
    }

    if (_.isArray(item)) {
      return _.filter(
        sideBarMenus,
        ({ menuName }) => !_.includes(item, menuName)
      );
    }
    return sideBarMenus;
  };

  return (
    <>
      <Wrapper className="page-right-menu-wrapper">
        <SliderMenu
          menus={hideMenu(ignoreMenu)}
          bl={1}
          maxWidth={168}
          minWidth={56}
          borderColor="greyscale-grey-5"
          handler={handler}
          activeMenu={active}
          isDisabled={isDisabled}
        />
        {showPanel && (
          <Wrapper className="page-right-menu-wrapper__content" fh>
            {content}
          </Wrapper>
        )}
      </Wrapper>
      <BackDrop toggleBackDrop={backDrop} />
    </>
  );
};

RightMenus.defaultProps = {
  isDisabled: false,
  ignoreMenu: ""
};

RightMenus.propTypes = {
  defaultMenu: PropTypes.string.isRequired,
  ignoreMenu: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array)
  ]),
  history: PropTypes.instanceOf(Object).isRequired,
  isDisabled: PropTypes.bool
};

export default withRouter(RightMenus);

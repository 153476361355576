import { put, takeEvery } from "redux-saga/effects";
import { apiCall, apiException } from "../../services/api";
import { setTriageHistory } from "./actionCreators";
import {
  GET_TRIAGEREPORT,
  GET_TRIAGE_HISTORY,
  SET_TRIAGEREPORT_ERROR,
  SET_TRIAGEREPORT_SUCCESS,
  SET_TRIAGE_HISTORY_ERROR
} from "./actionTypes";

function* getTriageReport(action) {
  const isSnowplowEnabled =  process.env.REACT_APP_SNOWPLOW_ENABLED  === "true";
  try {
    let response = null;
    if(!isSnowplowEnabled) {
      response = yield apiCall({
        method: "get",
        apiType: "clinicalcases",
        url: `reports/${action.payload.QuestionnaireReference}/triagereport`
      });
    } else {
      response = yield apiCall({
        method: "get",
        apiType: "questionnaires",
        url: `questionnaires/${action.payload.QuestionnaireReference}/TriageReport`
      });
    }
    yield put({ type: SET_TRIAGEREPORT_SUCCESS, data: response });
  } catch (e) {
    yield put(
      apiException({
        type: SET_TRIAGEREPORT_ERROR,
        code: e.code || e,
        message: e.message || "Triages-Fail"
      })
    );
  }
}

function* getTriageHistory({
  serviceuserReferenceId,
  screenReference = [],
  triageType
}) {
  const isSnowplowEnabled =  process.env.REACT_APP_SNOWPLOW_ENABLED  === "true";
  if (triageType === "related" && screenReference.length < 1) {
    yield put(setTriageHistory(triageType, []));
    return;
  }
  
  try {
    let response = null;
    if(!isSnowplowEnabled) {
      response = yield apiCall({
        method: "get",
        apiType: "clinicalcases",
        url: `reports/${serviceuserReferenceId}/triagehistory`
      });
      yield put(setTriageHistory(triageType,  [response]));
      yield put(setTriageHistory('related',  [response]));
    } else {
      response = yield apiCall({
        method: "get",
        apiType: "questionnaires",
        url: `serviceUsers/${serviceuserReferenceId}`
      });
      yield put(setTriageHistory(triageType, response || []));
    }
  } catch (e) {
    yield put(
      apiException({
        type: SET_TRIAGE_HISTORY_ERROR,
        triageType:
          triageType === "all" ? "allTriagesError" : "relatedTriagesError",
        code: e.code || e,
        message:
          e.message || triageType === "all"
            ? "No-Triage-For-Case"
            : "No-Triage-Found"
      })
    );
  }
}

export function* triageReportGETAction() {
  yield takeEvery(GET_TRIAGEREPORT, getTriageReport);
  yield takeEvery(GET_TRIAGE_HISTORY, getTriageHistory);
}

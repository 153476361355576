export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";

export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";

export const MUTE_SUBSCRIPTION = "MUTE_SUBSCRIPTION";

export const UNMUTE_SUBSCRIPTION = "UNMUTE_SUBSCRIPTION";

export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";

export const CLEAR_SUBSCRIPTION_ERROR = "CLEAR_SUBSCRIPTION_ERROR";

export const TOGGLE_SETTINGS = "TOGGLE_SETTINGS";

import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import {
  apiErrorObj,
  ProcessTypeCare,
  ProcessTypePartnerTracing,
  ProcessTypeSafeguarding,
  ProcessTypeTesting
} from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const TASK_TYPE = "TASK_TYPE";
const TASK_TYPE_SUCCESS = "TASK_TYPE_SUCCESS";
const TASK_TYPE_ERROR = "TASK_TYPE_ERROR";

const initialState = {
  loading: false,
  error: apiErrorObj,
  data: {},
  flag: false
};

function* taskTypeGet(action) {
  try {
    const response = yield all([
      ..._.map(
        [
          ProcessTypeSafeguarding,
          ProcessTypePartnerTracing,
          ProcessTypeCare,
          ProcessTypeTesting
        ],
        (processcode) =>
          apiCall({
            url: `TaskTypes/${processcode}`
          })
            .then((res) => ({ [processcode]: res }))
            .catch(() => ({
              [processcode]: []
            }))
      )
    ]);
    yield put({
      type: TASK_TYPE_SUCCESS,
      payload: {
        ...action.payload,
        response
      }
    });
  } catch (e) {
    yield put(
      apiException({
        type: TASK_TYPE_ERROR,
        code: e.code || e,
        message: e.message || "TaskType-Fail"
      })
    );
  }
}

export const getTaskType = (payload) => ({
  type: TASK_TYPE,
  payload
});

export function* taskTypeGETAction() {
  yield takeLatest([TASK_TYPE], taskTypeGet);
}

export const taskTypeReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case TASK_TYPE: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case TASK_TYPE_SUCCESS: {
      let taskData = {};
      action.payload.response.forEach((item) => {
        const tmpData = item[Object.keys(item)[0]].filter((subitem) =>
          subitem.localisationKey.toLocaleLowerCase().includes("followup")
        );
        taskData = {
          ...taskData,
         ...{
          [Object.keys(item)[0]] : tmpData
         }
        };
      });
      return {
        ...state,
        loading: false,
        flag: true,
        data: {
          ...state.data,
          ...taskData
        }
      };
    }
    case TASK_TYPE_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    default:
      return state;
  }
};

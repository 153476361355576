/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { stringify } from "query-string";
import {
  Wrapper,
  PartnerTracingCard,
  Tasks,
  PartnerTracingList,
  Loader,
  translate
} from "preventx-storybook";
import { useSelector, useDispatch } from "react-redux";
import { NewTask } from "../NewTask/NewTask";
import { resetGetPartnerTracing } from "../../../store/partnertracing/logic";
import {
  postCreateTask,
  resetCreateTask
} from "../../../store/createTask/logic";
import { convertToDateTime } from "../../../utils/utilsFunction";
import {
  DATE_FORMAT,
  PARTNER_TRACING_URL,
  ProcessTypePartnerTracing,
  PROCESS_DEFAULT_OPEN
} from "../../../constants/defaults";
import { EditOutcomes, goToOldApp, putTaskAction } from "../../../utils/helper";
import {
  getPartnerTracingList,
  resetGetPartnerTracingList
} from "../../../store/partnerlist/logic";
import { apiCall, apiException } from "../../../services/api";
import { getNoteByIdAndTriggerEdit } from "../../../store/actionTypes";

export const PartnerTracing = () => {
  const { t } = translate();
  const dispatch = useDispatch();
  const partnertracingState = useSelector(
    (state) => state.partnerTracingReducer
  );
  const caseHeaderState = useSelector((state) => state.caseHeaderReducer);
  const createTaskState = useSelector((state) => state.createTaskReducer);
  const testingState = useSelector((state) => state.testingReducer);
  const taskTypeState = useSelector((state) => state.taskTypeReducer);
  const partnerTracingListState = useSelector(
    (state) => state.partnerTracingListReducer
  );
  const [showModal, setShowModal] = useState(false);
  const [addPartnerSpinner, setAddPartnerSpinner] = useState(false);
  const [inputData, setInputData] = useState({});
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (
      testingState.flag &&
      testingState?.data?.screenReferences &&
      testingState?.data?.screenReferences?.length
    ) {
      dispatch(
        getPartnerTracingList({
          screenreferences: testingState?.data?.screenReferences || [],
          serviceUserReference:
            caseHeaderState?.data?.serviceUserReference || ""
        })
      );
    } else {
      dispatch(resetGetPartnerTracingList());
    }
  }, [testingState.flag]);

  useEffect(() => {
    if (partnertracingState.flag) {
      setShowModal(false);
      dispatch(resetGetPartnerTracing());
    }
  }, [partnertracingState.flag]);

  useEffect(() => {
    if (createTaskState.flag) {
      setInputData({});
      dispatch(resetCreateTask());
      setShowModal(false);
    }
  }, [createTaskState.flag]);

  const redirectToexternalSite = async (messageItem) => {
    const serviceUserRef = caseHeaderState?.data?.serviceUserReference;
    setAddPartnerSpinner(true);

    apiCall({
      apiType: "partnertracing",
      url: `partners/add/redirect?${stringify({
        screenReference: testingState?.data?.screenReferences
      })}&ReturnUrl=${process.env.REACT_APP_ADB2C_REDIRECT_URI}`
    })
      .then((response) => {
        if (response?.url) {
          setAddPartnerSpinner(false);

          goToOldApp(response?.url, true);
        }
      })
      .catch(() => {
        setAddPartnerSpinner(false);

        dispatch(
          apiException({
            type: null,
            message: "Error-500"
          })
        );
      });
  };

  const submitForm = (obj) => {
    obj.dueDate = convertToDateTime(obj.dueDate, DATE_FORMAT, "YYYY-MM-DD");
    if (Object.keys(obj).length > 1) {
      dispatch(
        postCreateTask({
          processReference: partnertracingState.data.processReference,
          body: {
            ...obj,
            ProcessReference: partnertracingState.data.processReference
          },
          caseReference: caseHeaderState.data.caseReference,
          processType: ProcessTypePartnerTracing,
          message: t("Task-Created-Message")
        })
      );
    } else {
      dispatch(
        postCreateTask({
          processReference: partnertracingState.data.processReference,
          body: obj,
          caseReference: caseHeaderState.data.caseReference,
          taskReference: editData.taskReference,
          processType: ProcessTypePartnerTracing,
          message: t("Task-Edit-Due-Date-Message")
        })
      );
    }
  };

  if (!partnertracingState.data) {
    return null;
  }

  return (
    <Wrapper className="mb-25">
      <PartnerTracingCard
        showNewTask
        defaultOpen={PROCESS_DEFAULT_OPEN}
        maxWidth={736}
        inputModel={partnertracingState?.data}
        handlerEditOutcome={(item) =>
          item?.caseNoteReference && EditOutcomes(item?.caseNoteReference)
        }
        handlerNewTask={() => {
          let taskTypeId = "";
          if (
            taskTypeState?.data &&
            taskTypeState?.data[ProcessTypePartnerTracing] &&
            taskTypeState?.data[ProcessTypePartnerTracing].length
          ) {
            taskTypeId = taskTypeState?.data[ProcessTypePartnerTracing][0].id;
          }
          setInputData({
            taskTypeId
          });
          setEditData({});
          setShowModal(true);
        }}>
        <Loader
          loading={partnerTracingListState?.loading}
          error={partnerTracingListState?.error.isError}
          errorMessage={partnerTracingListState?.error?.message}
          errorCode={partnerTracingListState?.error?.code}
          height="30vh">
          <PartnerTracingList
            handlerAddPartner={redirectToexternalSite}
            inputModel={partnerTracingListState?.data?.screenPartners}
            isAddPartnerLoading={addPartnerSpinner}
            showAddPartner={
              testingState?.data?.screenReferences &&
              testingState?.data?.screenReferences?.length
            }
          />
        </Loader>
        <Tasks
          onhandlerAttempted={(noteid) => {
            dispatch(getNoteByIdAndTriggerEdit(noteid));
          }}
          tasksData={
            partnertracingState?.data && partnertracingState?.data.tasks
          }
          taskApiFn={putTaskAction}
          caseReference={caseHeaderState.data.caseReference}
          processReference={partnertracingState.data.processReference}
          taskHandler={(item, taskReference) => {
            if (item?.localisationKey?.includes("ChangeDueDate")) {
              const tmpTask = partnertracingState.data.tasks.find(
                (subitem) => subitem.taskReference === taskReference
              );
              setShowModal(true);
              setEditData(tmpTask);
            }
          }}
        />
      </PartnerTracingCard>
      <NewTask
        toggleModal={() => {
          setShowModal(!showModal);
          setInputData({});
          setEditData({});
        }}
        showModal={showModal}
        handlerSubmit={submitForm}
        inputModel={inputData}
        editData={editData}
        onChangeHandle={(obj) => setInputData(obj)}
        handlerDueDateChange={(dt) =>
          setInputData({
            ...inputData,
            ...{
              dueDate: dt
            }
          })
        }
        processTypeCode={ProcessTypePartnerTracing}
        isPrimaryLoading={createTaskState.loading}
      />
    </Wrapper>
  );
};

import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants/defaults";
import { ValidateDate } from "../utils/utilsFunction";

const NewTaskForm = [
  {
    name: "taskTypeId",
    placeholder: "please-select",
    label: "task-type",
    rules: {
      required: {
        value: true,
        message: "select-task-type"
      }
    },
    type: "select",
    isMulti: false,
    options: [],
    isFullRow: true,
    disabled: false,
  },
  {
    name: "dueDate",
    placeholder: DATE_FORMAT,
    label: "dueDate",
    rules: {
      required: {
        value: true,
        message: "provide-dueDate"
      }
    },
    validate: {
      checkDate: (v) => ValidateDate(v, DATE_FORMAT)
    },
    format: DATE_FORMAT,
    seperator: "/",
    type: "datepicker",
    isFullRow: true,
    minDate: dayjs().toDate()
  }
];

export default NewTaskForm;

import { put, takeEvery } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const RESULT_SUMMARY = "RESULT_SUMMARY";
const RESULT_SUMMARY_SUCCESS = "RESULT_SUMMARY_SUCCESS";
const RESULT_SUMMARY_ERROR = "RESULT_SUMMARY_ERROR";
const RESET_RESULT_SUMMARY = "RESET_RESULT_SUMMARY";

const initialState = {};

function* resultSummaryGet(action) {
  try {
    if (action.payload.screenReference) {
      const response = yield apiCall({
        method: "get",
        apiType: "Laboratory",
        url: `screens/${action.payload.screenReference}/results`
      });

      try {
        const gumcadResponse = yield apiCall({
          method: "get",
          apiType: "Laboratory",
          url: `screens/${action.payload.screenReference}/result/codings/SHHAPT`
        });

        response.screen.gumcad = gumcadResponse;
      } catch (ex) {
        console.error("GUMCAD data not found");
      }

      yield put({
        type: RESULT_SUMMARY_SUCCESS,
        payload: {
          ...response,
          ...action.payload
        }
      });
    } else {
      yield put(
        apiException({
          type: RESULT_SUMMARY_ERROR,
          payload: {
            code: 404,
            ...action.payload
          }
        })
      );
    }
  } catch (e) {
    yield put(
      apiException({
        type: RESULT_SUMMARY_ERROR,
        code: e.code || e,
        message: e.message || "ResultSummary-Fail",
        payload: {
          ...e,
          ...action.payload
        }
      })
    );
  }
}

export const getresultSummary = (payload) => ({
  type: RESULT_SUMMARY,
  payload
});

export const resetresultSummary = (payload) => ({
  type: RESET_RESULT_SUMMARY,
  payload
});

export function* resultSummaryGETAction() {
  yield takeEvery([RESULT_SUMMARY], resultSummaryGet);
}

export const resultSummaryReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case RESULT_SUMMARY: {
      return {
        ...state,
        [action.payload.screenReference]: {
          ...initialState,
          loading: true,
          error: apiErrorObj,
          flag: false,
          data: {}
        }
      };
    }
    case RESULT_SUMMARY_SUCCESS: {
      return {
        ...state,
        [action.payload.screenReference]: {
          ...state[action.payload.screenReference],
          loading: false,
          flag: true,
          data: action.payload
        }
      };
    }

    case RESULT_SUMMARY_ERROR: {
      return {
        ...state,
        [action.payload.screenReference]: {
          ...state[action.payload.screenReference],
          loading: false,
          flag: false,
          data: {},
          error: {
            ...state.error,
            isError: true,
            message: action.message,
            code: action.code
          }
        }
      };
    }
    case RESET_RESULT_SUMMARY: {
      return {
        ...state,
        loading: false,
        flag: false,
        data: {},
        error: apiErrorObj
      };
    }
    default:
      return state;
  }
};

import { SettingsIcon, Typography, Wrapper } from "preventx-storybook";
import React from "react";
import PropTypes from "prop-types";

export const NotificationHeader = ({
  title,
  children,
  className,
  settingsIcon,
  handler
}) => (
  <Wrapper className={`notification-header ${className}`}>
    {title && (
      <Typography className="notification-header__title">{title}</Typography>
    )}
    {children}
    {settingsIcon && (
      <Wrapper className="notification-header__settings">
        <Typography>settings</Typography>
        <Wrapper handler={handler}>
          <SettingsIcon className="notification-header__icon" />
        </Wrapper>
      </Wrapper>
    )}
  </Wrapper>
);

NotificationHeader.defaultProps = {
  title: "",
  children: "",
  className: "",
  settingsIcon: false
};

NotificationHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  settingsIcon: PropTypes.bool,
  handler: PropTypes.func.isRequired
};

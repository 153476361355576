import { put, takeLatest } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";

const SEARCH_SCREEN_REFERENCE = "SEARCH_SCREEN_REFERENCE";
const SEARCH_SCREEN_REFERENCE_SUCCESS = "SEARCH_SCREEN_REFERENCE_SUCCESS";
const SEARCH_SCREEN_REFERENCE_ERROR = "SEARCH_SCREEN_REFERENCE_ERROR";
const SEARCH_SCREEN_REFERENCE_RESET = "SEARCH_SCREEN_REFERENCE_RESET";
const  SEARCH_SCREEN_REFERENCE_RESET_ERROR_FLAG = "SEARCH_SCREEN_REFERENCE_RESET_ERROR_FLAG";


const initialState = {
  loading: false,
  error: apiErrorObj,
  data: {},
  flag: false
}

function* searchScreenReferenceGet(action) {
  let response;
  try {
    if(action.payload.CaseReference.startsWith('PX')){
    response = yield apiCall({
        method: "get",
        url :`cases/searchcount?LegacyScreenReference=${action.payload.CaseReference}`
      });
    }else {
      response = yield apiCall({
        method: "get",
        url :`cases/searchcount?ScreenReference=${action.payload.CaseReference}`
      });
    }

    if (response?.CaseReference !== null || response?.totalServiceUsers > 0) {
      yield put({
        type: SEARCH_SCREEN_REFERENCE_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: SEARCH_SCREEN_REFERENCE_ERROR ,
        payload: {
          ...action.payload
        }
      });
    }
  } catch (e) {
    yield put(
      apiException({
        type: SEARCH_SCREEN_REFERENCE_ERROR ,
        code: e.code || e,
        message: e.message || "CaseSearch-Fail"
      })
    )
  }
}

export const getsearchScreenReference = (payload) => ({
  type: SEARCH_SCREEN_REFERENCE,
  payload
});

export const resetsearchScreenReference = () => ({
  type: SEARCH_SCREEN_REFERENCE_RESET
});

export const resetsearchScreenReferenceFlag = () => ({
  type: SEARCH_SCREEN_REFERENCE_RESET_ERROR_FLAG
});

export function* searchScreenReferenceGETAction() {
  yield takeLatest([ SEARCH_SCREEN_REFERENCE], searchScreenReferenceGet);
}

export const searchScreenReferenceReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case SEARCH_SCREEN_REFERENCE: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }
    case SEARCH_SCREEN_REFERENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        flag: true,
        data: action.payload
      };
    }
    case SEARCH_SCREEN_REFERENCE_ERROR: {
      return {
        ...state,
        loading: false,
        data: action.payload || "",
        error: {
          ...state.error,
          isError: true,
          message: action.message,
          code: action.code
        },
        flag: false
      };
    }
    case SEARCH_SCREEN_REFERENCE_RESET: {
      return {
        ...state,
        data: "",
        loading: false,
        error: apiErrorObj,
        flag: false
      };
    }
    case SEARCH_SCREEN_REFERENCE_RESET_ERROR_FLAG: {
      return {
        ...state,
        error: false
      };
    }
    
    default:
      return state;
  }
};
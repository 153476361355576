import _ from "lodash";
import { put, select, takeEvery } from "redux-saga/effects";
import { apiErrorObj } from "../../constants/defaults";
import { apiCall, apiException } from "../../services/api";
import { injectUserDetails } from "../../utils/helper";

const MESSAGES = "MESSAGES";
const MESSAGES_MORE = "MESSAGES_MORE";
const MESSAGES_SUCCESS = "MESSAGES_SUCCESS";
const MESSAGES_MORE_SUCCESS = "MESSAGES_MORE_SUCCESS";
const MESSAGES_ERROR = "MESSAGES_ERROR";
const MESSAGES_RESET = "MESSAGES_RESET";
const GET_TODO_MESSAGES = "GET_TODO_MESSAGES";
const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";

const TODO_LOADING = "TODO_LOADING";
const ALL_LOADING = "ALL_LOADING";

const PAGE_SIZE = 10;

const initialState = {
  flag: false,
  error: apiErrorObj,
  todo: {
    data: {},
    loading: true,
    error: apiErrorObj,
    pageNumber: 1
  },
  all: {
    data: {},
    loading: true,
    error: apiErrorObj,
    pageNumber: 1
  }
};

function* messageGet(action) {
  const { todo, all } = yield select((state) => state.messageReducer);

  try {
    let response = yield apiCall({
      method: "get",
      apiType: "messaging",
      url: action?.payload?.serviceUserReference
        ? `serviceusers/${action.payload.serviceUserReference}?pageSize=${PAGE_SIZE}&pageNumber=${all.pageNumber}`
        : `serviceproviders?pageSize=${PAGE_SIZE}&pageNumber=${
            action.type === GET_TODO_MESSAGES ? todo.pageNumber : all.pageNumber
          }${action.type === GET_TODO_MESSAGES ? `&Filters=unread` : ""}`
    });

    if (response && !action?.payload?.serviceUserReference) {
      response = yield injectUserDetails(response, "messages");
    }

    yield put({
      type: action?.payload?.moreLoading
        ? MESSAGES_MORE_SUCCESS
        : MESSAGES_SUCCESS,
      payload: {
        ...response,
        type: action.type === GET_TODO_MESSAGES ? "todo" : "all"
      }
    });
  } catch (e) {
    yield put(
      apiException({
        type: MESSAGES_ERROR,
        payload: { type: action.type === GET_TODO_MESSAGES ? "todo" : "all" },
        code: e.code || e,
        message: e.message || "Messages-Fail"
      })
    );
  }
}

export const getMessage = (payload) => ({
  type: MESSAGES,
  payload
});

export const getTodoMessages = (payload) => ({
  type: GET_TODO_MESSAGES,
  payload
});

export const getAllMessages = (payload) => ({
  type: GET_ALL_MESSAGES,
  payload
});

export const resetMessage = () => ({
  type: MESSAGES_RESET
});

export const getMessageMore = (payload) => ({
  type: MESSAGES_MORE,
  payload: { ...payload, moreLoading: true }
});

export function* messageGETAction() {
  yield takeEvery(
    [MESSAGES, MESSAGES_MORE, GET_ALL_MESSAGES, GET_TODO_MESSAGES],
    messageGet
  );
}

const updateData = (data) => {
  const newdata = _.chain(data)
    .groupBy("created")
    .map((value, key) => ({ created: key, data: value }))
    .value();
  return newdata;
};

export const messageReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case MESSAGES: {
      return {
        ...state,
        loading: true,
        error: apiErrorObj
      };
    }

    case TODO_LOADING: {
      return {
        ...state,
        todo: { ...state.todo, loading: action.payload.isLoading }
      };
    }
    case ALL_LOADING: {
      return {
        ...state,
        all: { ...state.all, loading: action.payload.isLoading }
      };
    }

    case MESSAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...{
          ...(action.payload.type === "todo"
            ? {
                todo: {
                  loading: false,
                  error: apiErrorObj,
                  pageNumber: state.todo.pageNumber + 1,
                  data: {
                    ...state.todo.data,
                    pagedResults: action.payload.pagedResults,
                    modifieddata: updateData(action.payload.pagedResults),
                    ...action.payload
                  },
                  flag: true
                }
              }
            : {
                all: {
                  loading: false,
                  error: apiErrorObj,
                  pageNumber: state.all.pageNumber + 1,
                  data: {
                    ...state.all.data,
                    pagedResults: action.payload.pagedResults,
                    modifieddata: updateData(action.payload.pagedResults),
                    ...action.payload
                  },
                  flag: true
                }
              })
        }
      };
    }
    case MESSAGES_MORE_SUCCESS: {
      const newResult = [
        ...state[action.payload.type === "todo" ? "todo" : "all"].data
          .pagedResults,
        ...action.payload.pagedResults
      ];
      const modifieddata = updateData(newResult);
      return {
        ...state,
        ...{
          ...(action.payload.type === "todo"
            ? {
                todo: {
                  loading: false,
                  error: apiErrorObj,
                  pageNumber: state.todo.pageNumber + 1,
                  data: {
                    ...action.payload,
                    pagedResults: newResult,
                    modifieddata: [
                      ...state.todo.data.modifieddata,
                      ...modifieddata
                    ]
                  },
                  flag: true
                }
              }
            : {
                all: {
                  loading: false,
                  error: apiErrorObj,
                  pageNumber: state.all.pageNumber + 1,
                  data: {
                    ...action.payload,
                    pagedResults: newResult,
                    modifieddata: [
                      ...state.all.data.modifieddata,
                      ...modifieddata
                    ]
                  },
                  flag: true
                }
              })
        }
      };
    }

    case MESSAGES_ERROR: {
      return {
        ...state,
        ...{
          ...(action.payload.type === "todo"
            ? {
                todo: {
                  loading: false,
                  error: {
                    ...state.todo.error,
                    isError: true,
                    message: action.message,
                    code: action.code
                  },
                  pageNumber: 1,
                  data: action.payload,
                  flag: false
                }
              }
            : {
                all: {
                  loading: false,
                  error: {
                    ...state.all.error,
                    isError: true,
                    message: action.message,
                    code: action.code
                  },
                  pageNumber: 1,
                  data: action.payload,
                  flag: false
                }
              })
        }
      };
    }

    case MESSAGES_RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

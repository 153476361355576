import React from "react";
import { Typography, GridContainer, GridItem } from "preventx-storybook";

export const TableHead = () => (
  <div className="Table__Head Case_list_Head">
    <GridContainer>
      <GridItem className="Table__Head__Col-1" xs={12} md={4} lg={4} xlg={4}>
        <Typography className="head1 personal-details" variant="span">
          personal-details
        </Typography>
      </GridItem>
      <GridItem className="Table__Head__Col-1" xs={12} md={4} lg={4} xlg={4}>
        <Typography className="head1 contact" variant="span">
          contact-details
        </Typography>
      </GridItem>
      <GridItem className="Table__Head__Col-1" xs={12} md={4} lg={4} xlg={4}>
        <Typography className="head1 case-status" variant="span">
          case-status
        </Typography>
      </GridItem>
    </GridContainer>
    <div style={{ minWidth: "40px", visibility: "hidden" }} />
  </div>
);
